import { scrapeEmptyFields } from "@knowt/syncing/utils/dataCleaning";
import { ESQueryFull } from "@knowt/syncing/graphql/queries";
import { platform } from "@knowt/syncing/platform";
import { client } from "@knowt/syncing/utils/client/graphql";

export const ES_INDICES_DEV = {
    notes_dev: "NOTE",
    flashcardsets_dev: "FLASHCARDSET",
    users_dev: "USER",
    verified_schools_dev: "VERIFIED_SCHOOL",
};

export const ES_INDICES_PROD = {
    notes_prod: "NOTE",
    flashcardsets_prod: "FLASHCARDSET",
    users_prod: "USER",
    verified_schools_prod: "VERIFIED_SCHOOL",
};

export const PAGE_SIZE = 20;

export const escapedQuery = query => {
    const ESCAPE_LIST = ["-", "[", "]", "+", "&&", "||", "!", "(", ")", "{", "}", "^", '"', "~", "*", "?", ":", "\\"];
    const regex = RegExp("[" + ESCAPE_LIST.join("\\") + "]", "g");
    return query.replace(regex, "\\$&");
};

export const sortFieldsFull = (sortBy, timeKey = "updated", direction: "asc" | "desc" = "desc") => {
    return {
        Updated: [
            { "updated.keyword": direction },
            { _score: "desc" },
            { rating: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { ratingCount: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { _id: "asc" },
        ],
        Created: [
            { "created.keyword": direction },
            { _score: "desc" },
            { rating: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { ratingCount: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { _id: "asc" },
        ],
        Relevance: [
            { _score: "desc" },
            { rating: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { ratingCount: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { _id: "asc" },
        ],
        Oldest: [
            { [`${timeKey}.keyword`]: "asc" },
            { _score: "desc" },
            { rating: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { ratingCount: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { _id: "asc" },
        ],
        Newest: [
            { [`${timeKey}.keyword`]: "desc" },
            { _score: "desc" },
            { rating: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { ratingCount: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { _id: "asc" },
        ],
        Popular: [
            { views: "desc" },
            { rating: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { ratingCount: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { _score: "desc" },
            { _id: "asc" },
        ],
        Rating: [
            { rating: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { ratingCount: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { _score: "desc" },
            { _id: "asc" },
        ],
        Views: [
            { views: "desc" },
            { rating: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { ratingCount: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { _score: "desc" },
            { _id: "asc" },
        ],
        Verified: [
            { verified: "desc" },
            { rating: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { ratingCount: { order: "desc", missing: "_last", unmapped_type: "long" } },
            { _score: "desc" },
            { _id: "asc" },
        ],
        Followers: [{ numFollowers: "desc" }, { _score: "desc" }, { _id: "asc" }],
        Year: [{ "year.keyword": "desc" }, { _score: "desc" }, { _id: "asc" }],
        Order: [{ "order.keyword": "asc" }],
        Title: [{ "title.keyword": direction }, { _score: "desc" }, { _id: "asc" }],
    }?.[sortBy];
};

export type ESQueryFullProps = {
    queryFields: string[];
    queryPhrase: string | string[];
    // if return fields is undefined, it defaults to true, which returns all fields
    returnFields?: string[] | boolean;
    excludeFields?: string[];
    includeFields?: string[];
    searchIndex: string[];
    nextToken?: string | null;
    page?: number | null;
    pagesize?: number | null;
    sort?: string | null;
    direction?: "asc" | "desc";
    timeKey?: string;
    returnAll?: boolean;
    exactMatch?: boolean;
    filters?: any;
    random?: boolean;
};

export type ESQueryFullReturn<ItemType> = {
    items: ItemType[];
    total: number;
};

export const getAllESQueryFull = async <ItemType>({
    queryFields,
    queryPhrase = "",
    returnFields,
    excludeFields,
    searchIndex,
    nextToken,
    pagesize = PAGE_SIZE,
    sort = "Relevance",
    timeKey = "updated",
    returnAll = false,
    filters = {},
}: ESQueryFullProps): Promise<ESQueryFullReturn<ItemType>> => {
    let items = [];
    let total = 0;
    let curPage = 0;
    do {
        curPage += 1;

        const temp = await runESQueryFull({
            queryFields,
            queryPhrase,
            returnFields,
            excludeFields,
            searchIndex,
            page: curPage,
            nextToken,
            pagesize,
            sort,
            timeKey,
            returnAll,
            filters,
        });

        items = [...items, ...(temp.items || [])];
        total = temp?.total || 0;
    } while (curPage * pagesize < total);
    return { items, total } as ESQueryFullReturn<ItemType>;
};

const isRangeFilter = value => {
    const isNumber = maybeNumber => !Number.isNaN(Number.parseFloat(maybeNumber)) && Number.isFinite(maybeNumber);
    return ["gte", "gt", "lte", "lt"].some(key => isNumber(value[key]));
};

const isBoolFilter = field => {
    return ["must", "must_not", "should"].includes(field);
};

/**
 * Generic function to run a more granular ES Query to get any kind of information
 * @param queryFields - array of fields to search on (remember to use .keyword if you need a specific result)
 * @param queryPhrase - string that you are searching for (not needed if returnAll = true)
 * @param returnFields - fields that you want returned, depending on the searchIndex. defaults to true, returning all fields
 * @param excludeField - exclude the results where the informed field exists
 * @param searchIndex - array of indexes you want to search on. eg. ["NOTE", "USER"]
 * @param page - the page of results you want to return, useful if you want to jump to a specific page or go backwards.
 * @param nextToken - if moving directly to the next page of results, use nextToken rather than the page parameter to get quicker results. If this variable is specified, the page parameter is ignored
 * @param pagesize - size of each page of results
 * @param sort - can either be a key from the generic sorting methods (Relevance, Oldest, Newest, Popular), or pass in a custom sort array and that will be used instead
 * @param direction - can either be asc or desc
 * @param timeKey - can either by created or updated
 * @param exactMatch - if we should return only the exact match for the queryPhrase
 * @param returnAll - if we should return all values from the search index
 * @param filters - filters object {fieldName1: value, ...} to filter es query results
 * @returns {Promise<[]>}
 * return values are already in json format
 */
export const runESQueryFull = async <ItemType>({
    queryFields,
    queryPhrase = "",
    returnFields = [],
    excludeFields = null,
    includeFields = null,
    searchIndex,
    page = 0,
    nextToken = null,
    pagesize = PAGE_SIZE,
    sort = "Relevance",
    direction = "desc",
    timeKey = "updated",
    returnAll = false,
    exactMatch = false,
    filters = {},
    random = false,
}: ESQueryFullProps): Promise<ESQueryFullReturn<ItemType>> => {
    const createQueryCondition = () => {
        if (returnAll) {
            return [{ exists: { field: returnFields[0] } }];
        }

        const queries = Array.isArray(queryPhrase) ? queryPhrase : [queryPhrase];

        if (exactMatch) {
            return queries.map(query => ({
                term: { [queryFields[0]]: { value: query, boost: 1.0 } },
            }));
        }

        return queries.map(phrase => ({
            multi_match: {
                query: phrase,
                type: "phrase_prefix",
                fields: queryFields,
            },
        }));
    };

    const input = {
        index: searchIndex,
        body: JSON.stringify({
            _source: returnFields,
            query: {
                bool: {
                    should: createQueryCondition(),
                    minimum_should_match: 1,
                    ...(includeFields?.length && { must: includeFields.map(field => ({ exists: { field } })) }),
                    ...(excludeFields?.length && { must_not: excludeFields.map(field => ({ exists: { field } })) }),
                    filter: [
                        ...Object.entries(scrapeEmptyFields(filters || {})).map(([field, value]) => {
                            if (field === "match") return { match: value };

                            if (isBoolFilter(field)) {
                                return {
                                    bool: {
                                        [field]: value
                                            .flatMap(val => Object.entries(val))
                                            .map(([key, val]) => ({
                                                term: {
                                                    [key]: val,
                                                },
                                            })),
                                    },
                                };
                            }

                            return {
                                [isRangeFilter(value) ? "range" : Array.isArray(value) ? "terms" : "term"]: {
                                    [field]: value,
                                },
                            };
                        }),
                    ],
                },
            },
            sort: random
                ? [{ _script: { type: "number", script: "Math.random()" } }]
                : sortFieldsFull(sort, timeKey, direction) || sort,
            size: pagesize,
            ...(nextToken && { search_after: nextToken }),
            ...(!nextToken && page > 1 && { from: (page - 1 || 0) * pagesize }),
        }),
    };

    const args = {
        query: ESQueryFull,
        variables: { input },
        forceUnauth: true,
    };

    const { report } = await platform.analytics.logging();

    return await client
        .query({ ...args })
        .then(_data => {
            const data = JSON.parse(_data.data.ESQueryFull.result) as {
                hits: {
                    total: { value: number };
                    hits: Array<{
                        _index: string;
                        _source: ItemType;
                    }>;
                };
                errors: any[];
            };

            if (data?.errors?.length > 0) {
                report(data.errors.toString(), "runESQueryFull", {
                    ...args?.variables?.input,
                });
                return {
                    items: [],
                    total: 0,
                } as ESQueryFullReturn<ItemType>;
            }

            return {
                total: data.hits.total.value,
                items: data.hits.hits.map(result => ({
                    __typename: getTypename(result._index),
                    ...result._source,
                })),
            } as ESQueryFullReturn<ItemType>;
        })
        .catch(error => {
            report(error, "runESQueryFull", { ...args?.variables?.input });

            return {
                items: [],
                total: 0,
            } as ESQueryFullReturn<ItemType>;
        });
};

/**
 * Generic function to run a ES Query to get a list of suggestions based on the current query
 * @param queryFields - array of fields to search on (remember to use .keyword if you need a specific result)
 * @param searchIndex - array of indexes you want to search on. eg. ["NOTE", "USER"]
 * @param queryPhrase - string that you are searching for
 * @param returnFields - fields that you want returned, depending on the searchIndex
 * @param contexts - contexts object used to filter suggestions
 * @returns {Promise<[]>}
 */
export const runESSuggestions = async ({
    returnFields = false,
    queryPhrase: _queryPhrase,
    searchIndex,
    pagesize = PAGE_SIZE,
    queryField,
    contexts = {},
}: Pick<ESQueryFullProps, "returnFields" | "queryPhrase" | "pagesize" | "returnFields" | "searchIndex"> & {
    queryField: string;
    contexts?: Record<string, Record<string, string>>;
}) => {
    const queryPhrase = Array.isArray(_queryPhrase) ? _queryPhrase : [_queryPhrase];

    const args = {
        query: ESQueryFull,
        variables: {
            input: {
                index: searchIndex,
                body: JSON.stringify({
                    _source: returnFields,
                    suggest: queryPhrase.reduce((acc, prefix) => {
                        const fieldContexts = contexts?.[queryField];

                        return {
                            // biome-ignore lint: suspicious/noAccumulatingSpread
                            ...acc,
                            [prefix]: {
                                prefix: prefix,
                                completion: {
                                    field: queryField,
                                    skip_duplicates: true,
                                    size: pagesize,
                                    ...(fieldContexts ? { contexts: fieldContexts } : {}),
                                },
                            },
                        };
                    }, {}),
                }),
            },
        },
        forceUnauth: true,
    };

    const { report } = await platform.analytics.logging();

    return client
        .query({ ...args })
        .then(data => {
            return Object.values(JSON.parse(data.data.ESQueryFull.result).suggest).flatMap(
                (result: { options: any[] }) => result?.[0]?.options
            );
        })
        .catch(error => {
            report(error, "runESSuggestions", { ...args });
            return [];
        });
};

function getTypename(itemType: string) {
    if (ES_INDICES_PROD[itemType] === "NOTE") return "Note";
    if (ES_INDICES_PROD[itemType] === "FLASHCARDSET") return "FlashcardSet";
    if (ES_INDICES_PROD[itemType] === "USER") return "User";
    if (ES_INDICES_PROD[itemType] === "VERIFIED_SCHOOL") return "VerifiedSchool";
}
