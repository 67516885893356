"use client";

import { BadgeProgress, DailyActivity, UserDetails } from "@/graphql/schema";
import { callListDailyActivity, callProcessAsyncActions } from "@/hooks/gamification/activities/graphqlUtils";
import { resolveDailyActivitySWRKey } from "@/hooks/gamification/activities/utils";
import { callListBadgeProgress } from "@/hooks/gamification/badges/graphqlUtils";
import { checkStreakStatus } from "@/hooks/gamification/monitoring/streakMonitoring/checkStreakStatus";
import { StableSWRKeys, getSWRValue, safeLocalMutate } from "@/hooks/swr/swr";
import { fetchCurrentUserInfo } from "@/hooks/user/auth";
import { platform } from "@/platform";
import Event from "@/utils/Event";
import { unionBy } from "lodash-es";
import { mutate } from "swr";
import { LocalAuthUser } from "../../user/types";
import { resolveBadgeProgressSWRKey } from "../badges/useBadges";
import { showXPToast } from "../utils";
import { isToday } from "./streakMonitoring/utils";

export enum StreakStatus {
    STREAK_ENCOURAGEMENT_POPUP = "STREAK_ENCOURAGEMENT_POPUP",
    STREAK_FREEZES_WERE_USED = "STREAK_FREEZES_WERE_USED",
    STREAK_BROKEN = "STREAK_BROKEN",
    RESUME_LONG_PAUSE = "RESUME_LONG_PAUSE",
    LONG_PAUSE_ENDED = "LONG_PAUSE_ENDED",
}

export enum ProgressingType {
    STREAK = "STREAK",
    LEVEL = "LEVEL",
    BADGES = "BADGES",
}

export enum ProgressingEventMiscPayload {
    READY_TO_SHOW_ALERTS = "READY_TO_SHOW_ALERTS",
}
export type ProgressingPopupType = ProgressingType | StreakStatus;

export const PROGRESSING_POPUP_ORDER: ProgressingPopupType[] = [
    StreakStatus.STREAK_FREEZES_WERE_USED,
    StreakStatus.STREAK_BROKEN,
    ProgressingType.STREAK,
    ProgressingType.LEVEL,
    ProgressingType.BADGES,
    StreakStatus.STREAK_ENCOURAGEMENT_POPUP,
    StreakStatus.RESUME_LONG_PAUSE,
    StreakStatus.LONG_PAUSE_ENDED,
];

export type PopupState = {
    [StreakStatus.STREAK_FREEZES_WERE_USED]: { open: boolean; usedStreakFreezes: number; streak: number };
    [StreakStatus.STREAK_BROKEN]: { open: boolean };
    [StreakStatus.STREAK_ENCOURAGEMENT_POPUP]: { open: boolean };
    [StreakStatus.RESUME_LONG_PAUSE]: { open: boolean; daysRemaining: number };
    [StreakStatus.LONG_PAUSE_ENDED]: { open: boolean };
    [ProgressingType.STREAK]: { open: boolean; streak: number; dailyActivity: DailyActivity | undefined };
    [ProgressingType.LEVEL]: { open: boolean; level: number; dailyActivity: DailyActivity | undefined };
    [ProgressingType.BADGES]: { open: boolean; badges: BadgeProgress[]; dailyActivity: DailyActivity | undefined };
};

export const INITIAL_PROGRESSSING_POPUP_STATE: PopupState = {
    [StreakStatus.STREAK_FREEZES_WERE_USED]: { open: false, usedStreakFreezes: 0, streak: 0 },
    [StreakStatus.STREAK_BROKEN]: { open: false },
    [StreakStatus.STREAK_ENCOURAGEMENT_POPUP]: { open: false },
    [StreakStatus.RESUME_LONG_PAUSE]: { open: false, daysRemaining: 0 },
    [StreakStatus.LONG_PAUSE_ENDED]: { open: false },
    [ProgressingType.STREAK]: { open: false, streak: 0, dailyActivity: undefined },
    [ProgressingType.LEVEL]: { open: false, level: 0, dailyActivity: undefined },
    [ProgressingType.BADGES]: { open: false, badges: [], dailyActivity: undefined },
};

export type ProgressingEventPayload = {
    [ProgressingEventMiscPayload.READY_TO_SHOW_ALERTS]?: Record<string, never>;
    [StreakStatus.STREAK_ENCOURAGEMENT_POPUP]?: Record<string, never>;
    [StreakStatus.STREAK_FREEZES_WERE_USED]?: { usedStreakFreezes: number; streak: number };
    [StreakStatus.RESUME_LONG_PAUSE]?: { daysRemaining: number };
    [StreakStatus.LONG_PAUSE_ENDED]?: Record<string, never>;
    [StreakStatus.STREAK_BROKEN]?: Record<string, never>;
    [ProgressingType.STREAK]?: { streak: number; dailyActivity: DailyActivity };
    [ProgressingType.LEVEL]?: { level: number; dailyActivity: DailyActivity };
    [ProgressingType.BADGES]?: { badges: BadgeProgress[]; dailyActivity: DailyActivity };
};

export const progressingEvent = new Event<ProgressingEventPayload>();

export type V2Response = {
    user: UserDetails;
    badges: BadgeProgress[];
    dailyActivity: DailyActivity;
    level?: number;
};

export const handleMaybeProgressing = async (
    v2Response: V2Response,
    isItAnOnMountCheck = false,
    serverOldUserDetails?: UserDetails
) => {
    // TODO: handle timezone error
    try {
        const oldUserDetails = await getOldUserDetails(serverOldUserDetails);

        const payload = await getProgressingPayload(v2Response, oldUserDetails);
        if (Object.keys(payload).length) {
            progressingEvent.notify(payload);
        }

        await updateSWRCache(v2Response);

        if (!isItAnOnMountCheck) {
            // if we're calling this function on mount,
            // we don't want to check the streak status,
            // it will be done by the useStreakStatusMonitor hook.
            await checkStreakStatus(v2Response.user);
        }
    } catch {}
};

const getOldUserDetails = async (serverOldUserDetails?: UserDetails): Promise<UserDetails> => {
    if (serverOldUserDetails) {
        // server side
        return serverOldUserDetails;
    }

    // client side

    const oldLocalUser = await getSWRValue(StableSWRKeys.USER);

    return oldLocalUser?.user ?? (await fetchCurrentUserInfo()).user;
};

const getProgressingPayload = async (v2Response: V2Response, oldUser: UserDetails) => {
    const { user: updatedUser, badges: updatedBadges, dailyActivity, level } = v2Response;
    const newlyEarnedBadges = updatedBadges.filter(badge => badge.newlyEarned);
    const payload: ProgressingEventPayload = {};

    const mixpanel = await platform.analytics.mixpanel();

    // needed in case of some edge cases where calls happen while cancelling a streak
    const isStreakToday = updatedUser.lastStreakDate && isToday(updatedUser.lastStreakDate, updatedUser.timeZone);

    if (oldUser.streak < updatedUser.streak && isStreakToday) {
        if (updatedUser.notifSettings?.gamifySettings?.streak !== false) {
            payload[ProgressingType.STREAK] = { streak: updatedUser.streak, dailyActivity };
        }
        await mixpanel.track("Streak - Triggered", {
            streakAction: dailyActivity.streakAction,
            xp: updatedUser?.xp,
            level: updatedUser?.level,
            streak: updatedUser?.streak,
            coins: updatedUser?.coins,
            gamificationRecords: updatedUser?.records,
            popupDisabled: updatedUser.notifSettings?.gamifySettings?.streak === false,
        });
    }

    if (level && level % 5 === 0) {
        if (updatedUser.notifSettings?.gamifySettings?.level !== false) {
            payload[ProgressingType.LEVEL] = { level, dailyActivity };
        }
        await mixpanel.track("Level - Triggered", {
            level,
            xp: updatedUser?.xp,
            streak: updatedUser?.streak,
            coins: updatedUser?.coins,
            gamificationRecords: updatedUser?.records,
            accountType: updatedUser.accountType,
            popupDisabled: updatedUser.notifSettings?.gamifySettings?.level === false,
        });
    }

    if (newlyEarnedBadges.length) {
        if (updatedUser.notifSettings?.gamifySettings?.badges !== false) {
            payload[ProgressingType.BADGES] = { badges: newlyEarnedBadges, dailyActivity };
        }
    }

    return payload;
};

const updateSWRCache = async (res: V2Response) => {
    const { user: updatedUser, badges: updatedBadges, dailyActivity } = res;

    await updateUserSWRCache(updatedUser);
    await maybeUpdateBadgeProgressSWRCache(updatedUser, updatedBadges);
    await updateDailyActivitySWRCache(updatedUser, dailyActivity);
};

const updateUserSWRCache = async (updatedUser: UserDetails) => {
    await safeLocalMutate(StableSWRKeys.USER, (oldData: LocalAuthUser) => ({
        ...oldData,
        user: {
            ...updatedUser,
            signInType: updatedUser.signInType ?? oldData.user.signInType,
        },
    }));
};

const maybeUpdateBadgeProgressSWRCache = async (updatedUser: UserDetails, updatedBadges: BadgeProgress[]) => {
    if (!updatedBadges.length) return;

    const oldBadgeProgress = ((await mutate(
        resolveBadgeProgressSWRKey(updatedUser.ID),
        oldBadgeProgress => oldBadgeProgress,
        {
            revalidate: false,
        }
    )) ?? (await callListBadgeProgress({ userId: updatedUser.ID }))) as BadgeProgress[];

    await mutate(resolveBadgeProgressSWRKey(updatedUser.ID), unionBy(updatedBadges, oldBadgeProgress, "badge"), {
        revalidate: false,
    });
};

const updateDailyActivitySWRCache = async (updatedUser: UserDetails, dailyActivity: DailyActivity) => {
    const oldDailyActivities =
        (await mutate(resolveDailyActivitySWRKey(updatedUser.ID), oldDailyActivity => oldDailyActivity, {
            revalidate: false,
        })) ?? (await callListDailyActivity({ limit: 90 }));

    const addedXP =
        dailyActivity.xp -
        (oldDailyActivities.find(activity => activity.activityDate === dailyActivity.activityDate)?.xp ?? 0);

    if (addedXP > 0) {
        showXPToast(addedXP);
    }

    const updatedDailyActivities = getUpdatedDailyActivities(oldDailyActivities, dailyActivity);
    await mutate(resolveDailyActivitySWRKey(updatedUser.ID), updatedDailyActivities, { revalidate: false });

    // last daily activity might be null, if there's been no activity for today.
    if (dailyActivity?.asyncActions?.length) {
        // !! This has a chance of an infinite loop if the backend doesn't process the actions correctly
        const v2Response = await callProcessAsyncActions();
        // this errors bc the dailyActivity has a key called `items`, and because of that, the aws schema generator thinks its a paged list (items, nextToken)
        await handleMaybeProgressing(v2Response);
    }
};

const getUpdatedDailyActivities = (oldDailyActivities: DailyActivity[], dailyActivity: DailyActivity) => {
    return oldDailyActivities
        .filter(activity => activity.activityDate !== dailyActivity.activityDate)
        .concat(dailyActivity)
        .sort((a, b) => (a.activityDate > b.activityDate ? -1 : 1));
};
