import { isEmail } from "@/hooks/user/userVerificationUtils";
import { platform } from "@/platform";
import { now } from "@/utils/dateTimeUtils";
import noop from "@/utils/noop";
import { UserDetails } from "@knowt/syncing/graphql/schema";
import {
    SignInOutput,
    signIn as amplifySignIn,
    signOut as amplifySignOut,
    fetchAuthSession,
    signInWithRedirect,
} from "aws-amplify/auth";
import { uniq } from "lodash-es";
import { uniqBy } from "lodash-es";
import { StableSWRKeys, getSWRValue, safeLocalMutate } from "../swr/swr";
import { generateUnauthUser, getAuthenticatedUser } from "./graphqlUtils";
import { LocalUser, SIGN_UP_TRACKED, TEMP_CACHED_SIGN_IN_SOURCE_KEY } from "./types";

export const maybeLogSource = async (user?: UserDetails) => {
    if (!user) {
        return;
    }
    const storage = await platform.storage();

    // User has just logged in, if source is available, the user is new (within 60 seconds)
    const source = (await storage.getWithExpiry(TEMP_CACHED_SIGN_IN_SOURCE_KEY)) ?? "GENERAL";
    const isSignUpTracked = (await storage.get(SIGN_UP_TRACKED)) === user.Email;
    if (source && now() - user.created < 120 && !isSignUpTracked) {
        const mixpanel = await platform.analytics.mixpanel();
        mixpanel.alias(user.ID);
        mixpanel.track("Sign Up", { signInType: user.signInType, isNewUser: true, source });
        await storage.remove(TEMP_CACHED_SIGN_IN_SOURCE_KEY);
        await storage.set(SIGN_UP_TRACKED, user.Email);
    }
};

export const fetchCurrentUserInfo = async (): Promise<LocalUser> => {
    try {
        const localUser = await getAuthenticatedUser();
        maybeLogSource(localUser.user);
        refreshSession();
        return localUser;
    } catch (e) {
        // biome-ignore lint: noConsole
        console.log("fetchCurrentUserInfo: failed to parse user", e);
        return generateUnauthUser();
    }
};

const sendSessionToChromeExtension = async (idToken: string) => {
    try {
        const chromeExtensionId = process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID;
        chrome.runtime?.sendMessage(chromeExtensionId, { idToken }, noop);
    } catch {
        // ignore error thrown when chrome extension is not installed
    }
};

export const refreshSession = async (options: { forceRefresh?: boolean } = {}) => {
    try {
        const session = await fetchAuthSession({ forceRefresh: options.forceRefresh });
        await sendSessionToChromeExtension(session.tokens.idToken.toString());
        return session;
    } catch {
        await sendSessionToChromeExtension(null);
        return null;
    }
};

const checkAndConvertLegacyUserAlertsIfNeeded = (alerts: string | undefined): string => {
    if (!alerts)
        return JSON.stringify({
            done: [],
            todo: [],
        });

    const oldUserAlertsKeys = ["shown", "toShow", "toRemove"];

    const parsedUserAlerts = JSON.parse(alerts);
    const isLegacyUserAlerts = Object.keys(parsedUserAlerts).every(key => oldUserAlertsKeys.includes(key));

    if (!isLegacyUserAlerts) return alerts;

    const done = uniq(Object.values(parsedUserAlerts.shown).flat() as string[]);

    const todo = uniqBy(
        parsedUserAlerts.toShow.map(event => ({
            eventName: event.eventName,
            visitCount: event.visitCount,
        })),
        "eventName"
    );

    return JSON.stringify({ done, todo });
};

export const fetchServerUserInfo = async ({
    forceStripeVerify = false,
}: {
    forceStripeVerify?: boolean;
} = {}): Promise<LocalUser> => {
    try {
        const retUser = await getAuthenticatedUser({ forceStripeVerify });
        if (retUser.user) {
            retUser.user.alerts = checkAndConvertLegacyUserAlertsIfNeeded(retUser.user.alerts);
        }

        maybeLogSource(retUser.user);
        await safeLocalMutate(StableSWRKeys.USER, retUser);
        return retUser;
    } catch (e) {
        // biome-ignore lint: noConsole
        console.log("fetchServerUserInfo: failed to parse user", e);
        signOut();
    }
};

// Signs the user out and sets up all state accordingly
export const signOut = async () => {
    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Logout");
    mixpanel.reset();

    const swrUser = await getSWRValue(StableSWRKeys.USER);

    const storage = await platform.storage();
    try {
        // Sign out from AWS Amplify
        await amplifySignOut();
    } catch (e) {
        const { report } = await platform.analytics.logging();
        report(e, "signOut", {});
        return null;
    }

    // clean up referral information
    await storage.remove("alreadyReferred");
    await storage.remove("referralId");
    if (swrUser?.user?.ID) {
        await storage.remove(`${swrUser.user.ID}-recentlyViewed`);
        await storage.remove(`${swrUser.user.ID}-recentlyStudied`);
    }

    await safeLocalMutate(StableSWRKeys.USER, generateUnauthUser());
};

/**
 * A one-to-one mapping between emails and usernames
 */
export const usernameFromEmail = email => email.replace("@", "@-@");

/**
 * A one-to-one mapping between usernames and emails
 * @param email
 */
export const emailFromUsername = username => username?.replace("@-@", "@");

export const cleanEmail = email => email.replace(/ /g, "").trim().toLowerCase();

/**
 * Converts a Cognito auth function to a safe version with additional error handling
 */
export const authSafe = <T>(authFun) => {
    const authFunSafe = (arg1, arg2, arg3) => {
        if (arg3) {
            return authFun(arg1, arg2, arg3);
        } else if (arg2) {
            return authFun(arg1, arg2);
        } else {
            return authFun(arg1);
        }
    };

    return (identifier: string, arg2: string = null, arg3: string = null): Promise<T> => {
        if (!identifier) {
            throw new Error(`"identifier" must be provided, found: ${identifier}`);
        }

        //biome-ignore lint:
        identifier = identifier.toLowerCase();
        return new Promise((resolve, reject) => {
            const isPotentialEmail = isEmail(identifier);
            if (isPotentialEmail) {
                authFunSafe(usernameFromEmail(identifier), arg2, arg3)
                    .then((result: T) => resolve(result))
                    .catch(err => {
                        if (err.name === "UserNotFoundException" || err.name === "InvalidParameterException") {
                            authFunSafe(identifier, arg2, arg3)
                                .then((result: T) => resolve(result))
                                .catch(async err => {
                                    if (
                                        err.name === "UserNotFoundException" ||
                                        err.name === "InvalidParameterException"
                                    ) {
                                        authFunSafe(identifier, arg2, arg3)
                                            .then((result: T) => resolve(result))
                                            .catch(reject);
                                    } else {
                                        reject(err);
                                        const { report } = await platform.analytics.logging();
                                        report(err, "verify", { identifier });
                                    }
                                });
                        } else {
                            reject(err);
                        }
                    });
            } else {
                authFunSafe(identifier, arg2, arg3)
                    .then((result: T) => resolve(result))
                    .catch(reject);
            }
        });
    };
};

export type SocialAuthProvider =
    | "Facebook"
    | "Google"
    | "Apple"
    | { custom: "Microsoft" | "Clever" | "ClassLink" }
    | "SSO";

export const federateSignIn = async (provider: SocialAuthProvider) => {
    if (provider !== "SSO") {
        return await signInWithRedirect({
            provider,
            options: {
                preferPrivateSession: true,
            },
        });
    }
};

export const signIn = async (email: string, password: string) => {
    await amplifySignOut();
    let result: SignInOutput = null;
    try {
        result = await authSafe<SignInOutput>((a, b) => amplifySignIn({ username: a, password: b }))(email, password);
    } catch (err) {
        // biome-ignore lint: noConsole
        console.log("Error signing in", err);
        throw new Error(err.name);
    }

    if (result.nextStep.signInStep === "CONFIRM_SIGN_UP") {
        throw new Error("UserNotConfirmedException");
    }
    if (result.nextStep.signInStep === "RESET_PASSWORD") {
        throw new Error("PasswordResetRequiredException");
    }

    await fetchServerUserInfo({ forceStripeVerify: true });
};
