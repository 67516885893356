import { PlatformType, platform } from "@knowt/syncing/platform";
import {
    DEBUG_LEVEL,
    GRAPHQL_AUTH_MODE,
    GeneratedMutation,
    GeneratedQuery,
    GraphQLInput,
    GraphQLListInput,
} from "@knowt/syncing/utils/client/types";
import {
    GraphQLListOutput,
    clearTypenames,
    createGraphQLError,
    groupItems,
    isNetworkError,
    throwIfInputIsNotValid,
} from "@knowt/syncing/utils/client/utils";
import { retry } from "@knowt/syncing/utils/genericUtils";
import { generateClient } from "@aws-amplify/api";
import { V6ClientSSRCookies } from "@aws-amplify/api-graphql";
import { deepScrapeEmptyFields } from "@knowt/syncing/utils/dataCleaning";
import { prettyPrint } from "../stringUtils";

const normalClient = generateClient();

export type ServerClientWithCookies = {
    client: V6ClientSSRCookies;
    sourceIp?: string;
    auth:
        | {
              authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS;
              authToken: string;
          }
        | {
              authMode: GRAPHQL_AUTH_MODE.AWS_IAM;
          };
    path: string;
};

export const client = {
    query: async <T extends GraphQLInput, K>({
        query,
        variables,
        serverClient = null,
        forceUnauth = false,
    }: {
        query: GeneratedQuery<T, K>;
        // extends the input type to include the "input" key
        variables: T;
        serverClient?: ServerClientWithCookies;
        forceUnauth?: boolean;
    }) => {
        const { log, report } = await platform.analytics.logging();
        const queryName = query.split("(")[0].split(" ")[1];

        if (DEBUG_LEVEL !== "NONE") {
            log(
                `[QUERY - ${serverClient ? "Server" : "Client"}]`,
                queryName,
                DEBUG_LEVEL === "VERBOSE" ? prettyPrint(variables.input) : ""
            );
        }

        if (platform?.platformType === PlatformType.CHROME_EXTENSION) {
            const { idToken } = (await chrome?.storage?.local?.get("idToken")) ?? { idToken: null };

            try {
                return await normalClient.graphql({
                    query,
                    variables,
                    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                    authToken: idToken,
                });
            } catch (e) {
                const err = createGraphQLError(e);

                if (queryName !== "GetCurrentUserAndOrganization" && !isNetworkError(err)) {
                    report(err, queryName, variables.input);
                }

                throw err;
            }
        }

        try {
            if (serverClient) {
                return await serverClient.client.graphql(
                    { query, variables, ...serverClient.auth },
                    serverClient.sourceIp ? { cd2f8270d71f4dba8f0a58390132b149: serverClient.sourceIp } : {}
                );
            } else {
                return await normalClient.graphql({
                    query,
                    variables,
                    ...(forceUnauth && { authMode: GRAPHQL_AUTH_MODE.AWS_IAM }),
                });
            }
        } catch (e) {
            const err = createGraphQLError(e);
            if (queryName !== "GetCurrentUserAndOrganization" && !isNetworkError(err)) {
                report(err, queryName, variables.input);
            }
            throw err;
        }
    },
    mutate: async <T extends GraphQLInput, K>({
        mutation,
        variables: _variables,
        serverClient = null,
    }: {
        mutation: GeneratedMutation<T, K>;
        variables: T;
        serverClient?: ServerClientWithCookies;
    }) => {
        const { log, report } = await platform.analytics.logging();
        const mutationName = mutation.split("(")[0].split(" ")[1];
        const variables = clearTypenames(_variables);

        if (DEBUG_LEVEL !== "NONE") {
            log(
                `[MUTATE - ${serverClient ? "Server" : "Client"}]`,
                mutationName,
                DEBUG_LEVEL === "VERBOSE" ? prettyPrint(variables.input) : ""
            );
        }

        if (platform?.platformType === PlatformType.CHROME_EXTENSION) {
            const { idToken } = (await chrome?.storage?.local?.get("idToken")) ?? { idToken: null };

            try {
                return await normalClient.graphql({
                    query: mutation,
                    variables,
                    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                    authToken: idToken,
                });
            } catch (e) {
                const err = createGraphQLError(e);
                if (!isNetworkError(err)) {
                    report(err, mutationName, variables.input);
                }
                throw err;
            }
        }

        try {
            if (serverClient) {
                return await serverClient.client.graphql(
                    { query: mutation, variables, ...serverClient.auth },
                    serverClient.sourceIp ? { cd2f8270d71f4dba8f0a58390132b149: serverClient.sourceIp } : {}
                );
            } else {
                return await normalClient.graphql({ query: mutation, variables });
            }
        } catch (e) {
            const err = createGraphQLError(e);
            if (!isNetworkError(err)) {
                report(err, mutationName, variables.input);
            }
            throw err;
        }
    },
};

/***
 * Fetches items from an AppSync list query
 * @param listQuery: The GraphQl query
 * @param queryName: The name of the graphql query
 * @param input: Any input parameters
 * @param ignoreTrashed: If this is set to true, items with trash=true will be ignored
 * @param serverClient: server client to use from server side
 */
export const listData = <T extends GraphQLInput, K extends GraphQLListOutput>({
    listQuery,
    queryName,
    input = {},
    ignoreTrashed = true,
    serverClient,
    forceUnauth = false,
}: {
    listQuery: GeneratedQuery<T, K>;
    // TODO: change to dataExtractor function
    queryName: string;
    input: T["input"];
    ignoreTrashed?: boolean;
    serverClient?: ServerClientWithCookies;
    forceUnauth?: boolean;
}) => {
    return retry(async () => {
        let items = await fetchListItems({ query: listQuery, name: queryName, input, serverClient, forceUnauth });
        if (ignoreTrashed) items = items.filter(({ trash }) => !trash);

        return items;
    });
};

/***
 * Fetches grouped items from an AppSync list query
 * @param listQuery: The GraphQl query
 * @param queryName: The name of the graphql query
 * @param input: Any input parameters
 * @param groupingKey: The key containing an item's ID; input will be grouped by this ID
 * @param ignoreTrashed: If this is set to true, items with trash=true will be ignored
 * @param serverClient: server client to use from server side
 */
export const listGroupedData = <T extends GraphQLListInput, K extends GraphQLListOutput>({
    listQuery,
    queryName,
    input = {},
    groupingKey,
    ignoreTrashed = true,
    serverClient,
    forceUnauth = false,
}: {
    listQuery: GeneratedQuery<T, K>;
    queryName: string;
    input: T["input"];
    groupingKey: string;
    ignoreTrashed?: boolean;
    serverClient?: ServerClientWithCookies;
    forceUnauth?: boolean;
}) => {
    return retry(async () => {
        let items = await fetchListItems({ query: listQuery, name: queryName, input, serverClient, forceUnauth });
        if (ignoreTrashed) items = items.filter(({ trash }) => !trash);

        return groupItems(items, groupingKey);
    });
};

const fetchListItems = async <T extends GraphQLListInput, K extends GraphQLListOutput>({
    query,
    name,
    input,
    serverClient,
    forceUnauth = false,
}: {
    query: GeneratedQuery<T, K>;
    name: string;
    input: T["input"];
    serverClient?: ServerClientWithCookies;
    forceUnauth?: boolean;
}) => {
    input = deepScrapeEmptyFields(input);
    throwIfInputIsNotValid(input, name);

    const result = [];
    let nextToken = null;
    do {
        await client
            .query({
                query: query,
                variables: {
                    input: {
                        ...input,
                        limit: 1000,
                        ...(nextToken && { nextToken }),
                    } as T["input"],
                } as T,
                serverClient,
                forceUnauth,
            })
            .then(({ data }) => {
                nextToken = data[name].nextToken;
                result.push(...data[name].items);
            });
    } while (nextToken);

    return result;
};
