import { pluralize } from "@knowt/syncing/utils/stringUtils";
import TIMEZONES from "@knowt/syncing/utils/timeZones";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * shows the value in milliseconds
 */
export enum TIME {
    SECOND = 1000,
    MINUTE = 1000 * 60,
    HOUR = 1000 * 60 * 60,
    DAY = 1000 * 60 * 60 * 24,
    WEEK = 1000 * 60 * 60 * 24 * 7,
    MONTH = 1000 * 60 * 60 * 24 * 30,
    YEAR = 1000 * 60 * 60 * 24 * 365,
}

/**
 * shows the value in seconds
 */
export enum TIME_SECONDS {
    SECOND = 1,
    MINUTE = 60,
    HOUR = 60 * 60,
    DAY = 60 * 60 * 24,
    WEEK = 60 * 60 * 24 * 7,
    MONTH = 60 * 60 * 24 * 30,
    YEAR = 60 * 60 * 24 * 365,
}

export const TIMEZONE_CHANGE_INTERVAL = 3 * TIME_SECONDS.DAY;

export const timezoned = (day: Dayjs, tz: string | null | undefined) => {
    if (!tz) {
        return day;
    }

    const verifiedTZ = TIMEZONES[tz.toLowerCase()];

    if (!verifiedTZ) {
        // biome-ignore lint: noConsole
        console.warn(`Offset not found for ${tz}`);
        return day;
    }

    let verifiedOffset = verifiedTZ.offset;

    // account for DST
    // will be in the format
    // dst: "03/08:02->11/01:02",
    if (verifiedTZ.dst) {
        const [start, end] = verifiedTZ.dst.split("->");

        const startMonth = Number(start.split("/")[0]);
        const startDay = Number(start.split("/")[1].split(":")[0]);

        const endMonth = Number(end.split("/")[0]);
        const endDay = Number(end.split("/")[1].split(":")[0]);

        const startDate = dayjs(`2025-${startMonth}-${startDay}`);
        const endDate = dayjs(`2025-${endMonth}-${endDay}`);

        // dayjs months are stupid
        const todayMonth = day.month() + 1;
        const todayDay = day.date();

        const today2025 = dayjs(`2025-${todayMonth}-${todayDay}`);

        // if the timezone does not fall into the DST range, we need to subtract 1 hour
        if (today2025.isBefore(startDate) || today2025.isAfter(endDate)) {
            verifiedOffset = verifiedOffset + (verifiedTZ.hem === "s" ? 1 : -1);
        }
    }

    // manually offset the date
    return day.utc().add(verifiedOffset, "hours");
};

export const today = (tz: string | null | undefined) => {
    const val = timezoned(dayjs(), tz);

    return val;
};

export const thisDay = (dateString: string | null | undefined, tz: string | null | undefined) => {
    if (!tz || !dateString) {
        return dayjs();
    }

    return timezoned(dayjs(dateString), tz);
};

export const getOffset = (tz: string) => {
    return today(tz).format("Z");
};

export const padTimeUnit = (unit: number | string, length: number) => unit.toString().padStart(length, "0");

// returns in format MM/DD/YYYY or YYYY-MM-DD if withDashes is true
export const getParsedUTCDate = (date: number | string | Date, withDashes = true) => {
    if (!date) return "";

    // if the date is in seconds, convert it to milliseconds
    if (typeof date === "number" && date < 1000000000000) {
        //biome-ignore lint:
        date *= 1000;
    }

    const dateObj = new Date(date);
    const day = dateObj.getUTCDate().toString();
    const month = (dateObj.getUTCMonth() + 1).toString();
    const year = dateObj.getUTCFullYear().toString();

    return withDashes
        ? `${year}-${padTimeUnit(month, 2)}-${padTimeUnit(day, 2)}`
        : `${padTimeUnit(month, 2)}/${padTimeUnit(day, 2)}/${year}`;
};

export const getTimeFromNow = (timestamp: number | Date) => {
    // make sure timestamp is in ms
    if (typeof timestamp === "number" && timestamp < 1000000000000) {
        //biome-ignore lint:
        timestamp *= 1000;
    }

    //biome-ignore lint:
    timestamp = new Date(timestamp).getTime();

    const now = Date.now();
    const diff = Math.abs(timestamp - now);

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    return {
        days,
        hours: hours % 24,
        minutes: minutes % 60,
        seconds: seconds % 60,
    };
};

export const getTimeWindow = (duration: number) => {
    duration *= 1000;

    const zero = 0;
    const diff = Math.abs(duration - zero);

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    return {
        days,
        hours: hours % 24,
        minutes: minutes % 60,
        seconds: seconds % 60,
    };
};

export const getFormattedTimeFromNow = (
    timestamp: number,
    upTo: "day" | "hour" | "minute" | "second" = "minute",
    short = true
) => {
    const { days, hours, minutes, seconds } = getTimeFromNow(timestamp);

    const timeUnits: [string, number][] = short
        ? [
              ["d", days],
              ["h", hours],
              ["m", minutes],
              ["s", seconds],
          ]
        : [
              ["day", days],
              ["hour", hours],
              ["minute", minutes],
              ["second", seconds],
          ];

    const timeUnitsLimit = ["day", "hour", "minute", "second"].indexOf(upTo) + 1;

    return timeUnits
        .slice(0, timeUnitsLimit)
        .filter(([_, value]) => value !== 0)
        .map(([unit, value]) => `${value}${unit} `)
        .join("")
        .trim(); // Added .trim() to remove any trailing space
};

export const getFormattedTimeWindow = (
    duration: number,
    upTo: "day" | "hour" | "minute" | "second" = "minute",
    short = true
) => {
    const { days, hours, minutes, seconds } = getTimeWindow(duration);

    const timeUnits: [string, number][] = short
        ? [
              ["d", days],
              ["h", hours],
              ["m", minutes],
              ["s", seconds],
          ]
        : [
              ["day", days],
              ["hour", hours],
              ["minute", minutes],
              ["second", seconds],
          ];

    const timeUnitsLimit = ["day", "hour", "minute", "second"].indexOf(upTo) + 1;

    return timeUnits
        .slice(0, timeUnitsLimit)
        .filter(([_, value]) => value !== 0)
        .map(([unit, value]) => `${value}${unit} `)
        .join("")
        .trim(); // Added .trim() to remove any trailing space
};

export const timeDeltaFromNow = (timestamp: number, short = false) => {
    if (!timestamp) {
        return "...";
    }

    const { days, hours, minutes, seconds } = getTimeFromNow(timestamp);

    if (days) {
        return `${days}${short ? "d" : " " + pluralize("day", days)}`;
    } else if (hours) {
        return `${hours}${short ? "h" : " " + pluralize("hour", hours)}`;
    } else if (minutes) {
        return `${minutes}${short ? "m" : " " + pluralize("minute", minutes)}`;
    } else {
        return `${seconds}${short ? "s" : " " + pluralize("second", seconds)}`;
    }
};

// returns in format 00:00:00 format, with hours only if > 0
export const formatTimeSpan = (seconds: number, shouldShowHour?: boolean) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedHours = hours > 0 || shouldShowHour ? padTimeUnit(hours, 2) + ":" : "";
    const formattedMinutes = padTimeUnit(minutes, 2) + ":";
    const formattedSeconds = padTimeUnit(remainingSeconds, 2);

    return formattedHours + formattedMinutes + formattedSeconds;
};

export const secondsToLargerUnit = (seconds: number): { value: number; unit: "second" | "minute" | "hour" } => {
    if (seconds < 60) {
        return { value: Math.floor(seconds), unit: "second" };
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return { value: minutes, unit: "minute" };
    } else {
        const hours = Math.floor(seconds / 3600);
        return { value: hours, unit: "hour" };
    }
};

export const parseStringTimestampToFloat = timestamp => {
    //biome-ignore lint:
    const timeParts = timestamp?.split(":").map(parseFloat);
    let [hours, minutes, seconds] = timeParts;

    if (timeParts.length === 2) {
        hours = 0; // Set default value for hours
        [minutes, seconds] = timeParts;
    }

    return (hours * 3600 + minutes * 60 + seconds) * 1.0;
};

export const getUnixTimeFromDDMMYY = (dateStr: string) => {
    const [dayStr, monthStr, yearStr] = dateStr.split("-").map(Number);
    //biome-ignore lint:
    if (isNaN(dayStr) || isNaN(monthStr) || isNaN(yearStr)) {
        throw new Error('Invalid date format. Please use "dd-mm-yy" format.');
    }

    const date = new Date(yearStr, monthStr, dayStr);

    return date.getTime();
};

export const formatUnixSecondsTimestamp = (unixSecondsTimestamp: number | string | undefined | null) => {
    const date = new Date(Number(unixSecondsTimestamp) * 1000);

    // Format day, month, and year
    const day = padTimeUnit(date.getDate(), 2);
    const month = padTimeUnit(date.getMonth() + 1, 2);
    const year = date.getFullYear().toString();

    // Format hour and minute
    const minute = padTimeUnit(date.getMinutes(), 2);
    const period = date.getHours() >= 12 ? "pm" : "am"; // https://english.stackexchange.com/a/35317
    const hourIn12HoursSystem = date.getHours() % 12;
    const hour = hourIn12HoursSystem === 0 ? "12" : padTimeUnit(hourIn12HoursSystem, 2);

    return {
        day,
        month,
        year,
        hour,
        minute,
        period,
        date: unixSecondsTimestamp ? `${year}-${month}-${day}` : "N/A",
        time: unixSecondsTimestamp ? `${hour}:${minute} ${period}` : "N/A",
    };
};

export const formatTimeStudying = (seconds: number) => {
    const { value, unit } = secondsToLargerUnit(seconds);
    return `${value} ${pluralize(unit, value)}`;
};

export const formatUnixTimestampToDateTime = (timestamp: number) => {
    const { day, month, year, hour, minute, period } = formatUnixSecondsTimestamp(timestamp);

    return `${month}/${day}/${year} ${hour}:${minute} ${period}`;
};

export const convertUnixToDateTime = (unixTimestamp: number) => {
    if (unixTimestamp < 1000000000000) {
        //biome-ignore lint:
        unixTimestamp *= 1000;
    }

    const date = dayjs(unixTimestamp);
    const formattedDate = date.format("MMM D, YYYY");
    const formattedTime = date.format("h:mm A");
    return {
        date: formattedDate,
        time: formattedTime,
    };
};

export const now = () => Math.round(Date.now() / 1000);

export const daysApart = (date1: string, date2: string) => {
    const date1MS = new Date(date1).getTime();
    const date2MS = new Date(date2).getTime();
    return Math.round(Math.abs((date1MS - date2MS) / TIME.DAY));
};

/**
 *
 * @returns today's date in the format "YYYY-MM-DD"
 */
export const todayDate = (tz?: string | null) => {
    const date = today(tz);

    return date.format("YYYY-MM-DD");
};

export const isLastDayOfMonth = (dt: Date) => {
    const test = new Date(dt.getTime());
    const month = test.getMonth();

    test.setDate(test.getDate() + 1);
    return test.getMonth() !== month;
};

export const getFormattedCountdown = ({
    days,
    hours,
    minutes,
    seconds,
}: {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
}) => {
    if (Number(days)) return `${days}d ${hours}h`;

    if (Number(hours)) return `${hours}h ${minutes}m`;

    return Number(minutes) ? `${minutes}m ${seconds}s` : `${seconds}s`;
};

/**
 * Convert our UI time such "4:00 PM" to a dayjs object.
 */
export const parseUITime = (time?: string) => {
    if (!time) return undefined;

    // invalid format
    const match = time.match(/^(\d{1,2}):(\d{2})\s*(AM|PM)$/i);
    if (!match) return undefined;

    const [, hours, minutes, period] = match;
    //biome-ignore lint:
    let parsedHours = parseInt(hours, 10);
    //biome-ignore lint:
    const parsedMinutes = parseInt(minutes, 10);

    // invalid time
    if (parsedHours < 1 || parsedHours > 12 || parsedMinutes < 0 || parsedMinutes > 59) {
        return undefined;
    }

    // convert to 24-hour format
    if (period.toLowerCase() === "pm" && parsedHours !== 12) {
        parsedHours += 12;
    } else if (period.toLowerCase() === "am" && parsedHours === 12) {
        parsedHours = 0;
    }

    return dayjs().hour(parsedHours).minute(parsedMinutes);
};

export const formatTimeForBackend = (time: dayjs.Dayjs) => time.format("h:mm A");
