import { callListDailyActivity } from "@/hooks/gamification/activities/graphqlUtils";
import { useSWRImmutable } from "@/hooks/swr/immutable";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { resolveDailyActivitySWRKey } from "@/hooks/gamification/activities/utils";
import { useMemo } from "react";
import { today } from "@/utils/dateTimeUtils";

export const useDailyActivities = ({ numberOfDays }: { numberOfDays: number }) => {
    const { userId } = useCurrentUser();

    const { data, mutate } = useSWRImmutable(resolveDailyActivitySWRKey(userId), () =>
        callListDailyActivity({ limit: 90 })
    );

    const dailyActivities = useMemo(() => {
        // slice the last n days
        return data?.slice(0, numberOfDays);
    }, [data, numberOfDays]);

    return { dailyActivities, mutate };
};

export const useTodayDailyActivity = () => {
    const { user, tz } = useCurrentUser();
    const { dailyActivities } = useDailyActivities({ numberOfDays: 7 });

    const xpDate = user?.xpSyncDate ?? today(tz);

    const latestDailyActivity = dailyActivities?.find(activity => activity.activityDate === xpDate);

    if (!latestDailyActivity) {
        return { dailyActivity: null };
    }

    return { todayDailyActivity: latestDailyActivity };
};

export const useUserXP = () => {
    const { user } = useCurrentUser();
    const { todayDailyActivity } = useTodayDailyActivity();

    if (!user) return 0;

    return user.xp + (todayDailyActivity?.xp ?? 0);
};
