/// <reference types="chrome" />
// biome-ignore lint: unused variable
declare const chrome: any;

import { Embedding } from "@knowt/syncing/types/common";
import {
    AICompletionType,
    ItemType,
    Media,
    QuestionTypeEnum,
    SubmitAICompletionInput,
    UserAIStats,
    UserDetails,
} from "@knowt/syncing/graphql/schema";
import { AI_USAGE_LIMITS, LocalAuthUser } from "@knowt/syncing/hooks/user/types";
import { AI_LAMBDA_URL, CONTEXT_WINDOW_BY_MODEL, GPT_MODEL } from "./constants";
import { AI_PROMPT_PARAMS_MAP } from "./aiPrompts/types";
import { callSubmitAICompletion } from "./graphqlUtils";

import { POST_HEADERS } from "@knowt/syncing/fetchFunctions/fetchWrappers";
import { platform } from "@knowt/syncing/platform";
import { ServerClientWithCookies } from "@knowt/syncing/utils/client/graphql";
import { now } from "@knowt/syncing/utils/dateTimeUtils";
import { fetchAuthSession } from "aws-amplify/auth";
import flattenDeep from "lodash/flattenDeep";
import { StableSWRKeys, safeLocalMutate } from "../swr/swr";
import { getUserPlan } from "../user/graphqlUtils";
import { mutateUserAIUsage } from "./useAI";

const dotp = (x: number[], y: number[]) => {
    function dotp_sum(a: number, b: number) {
        return a + b;
    }

    function dotp_times(_a: number, i: number) {
        return x[i] * y[i];
    }

    return x.map(dotp_times).reduce(dotp_sum, 0);
};

export const findRelevantEmbeddings = (inputEmbedding: number[], embeddings: Embedding[]) => {
    return embeddings
        .map(({ vec, index }) => ({
            similarity:
                dotp(inputEmbedding, vec) /
                (Math.sqrt(dotp(inputEmbedding, inputEmbedding)) * Math.sqrt(dotp(vec, vec))),
            index,
        }))
        .sort((a, b) => b.similarity - a.similarity);
};

export const pickRelevantEmbedding = (embeddings: { similarity: number; index: number }[]) => {
    const targetedSimilarity = embeddings[0].similarity;
    const curatedEmbeddings = embeddings.filter(({ similarity }) => targetedSimilarity / similarity < 1.05);

    return curatedEmbeddings.length < 3 ? embeddings.slice(0, 3) : curatedEmbeddings;
};

export const estimateTokens = (str: string) => {
    return ((str?.split(" ")?.length || 0) * 3) / 4;
};

export const chunkStringForAI = async (str: string, splitter = "\n") => {
    const contextWindow = CONTEXT_WINDOW_BY_MODEL[GPT_MODEL];

    const size = estimateTokens(str);

    if (size < contextWindow) {
        return [str];
    }

    // split in half
    const paragraphs = str.split(splitter);
    const half = Math.ceil(paragraphs.length / 2);
    const chunks = [paragraphs.slice(0, half).join("\n"), paragraphs.slice(half).join("\n")];

    return flattenDeep(await Promise.all(chunks.map(chunk => chunkStringForAI(chunk, splitter))));
};

export const didReachAIUsage = (user: UserDetails | null | undefined, type: keyof UserAIStats) => {
    const value = user?.ai?.[type] ?? 0;
    const subscriptionType = getUserPlan(user);
    const usageLimit = AI_USAGE_LIMITS[subscriptionType][type] ?? AI_USAGE_LIMITS[subscriptionType].prompts;

    return value >= usageLimit && (user?.ai?.curPeriodEnd || now()) > now();
};

export const chunkTranscriptForAI = async (str: string) => {
    return await chunkStringForAI(str, "\n\n");
};

export const tryToCreateFlashcards = (str: string) => {
    // Keep the format of:
    // 1. Term 1: Definition 1
    // 2. Term 2: Definition 2

    const lines = str.split("\n");

    return lines
        .map(line => {
            const [term, ...definition] = line.split(": ");

            if (!term || !definition) {
                return null;
            }

            return { term: `<p>${term.split(". ")[1]}</p>`, definition: `<p>${definition.join(":")}</p>` };
        })
        .filter(Boolean);
};

export const submitAICompletion = async (
    input: SubmitAICompletionInput,
    serverClient: ServerClientWithCookies = undefined
) => {
    safeLocalMutate(StableSWRKeys.USER, (data: LocalAuthUser) => {
        let {
            curPrompts,
            vNotes,
            vSets,
            pNotes,
            pSets,
            nTests,
            nSets,
            chats,
            explain,
            frq,
            mSets,
            mNotes,
            tools,
            assignments,
            scans,
            aiV,
        } = data.user.ai;

        switch (input.type) {
            case AICompletionType.NOTE_CHAT:
                chats = chats + 1;
                break;
            case AICompletionType.FLASHCARD_CHAT:
                chats = chats + 1;
                break;
            case AICompletionType.NOTE_FLASHCARDS:
                nSets = nSets + 1;
                break;
            case AICompletionType.NOTE_TEST:
            case AICompletionType.GENERATE_SIMILAR_QUESTIONS:
                nTests = nTests + 1;
                break;
            case AICompletionType.EXPLAIN_WHY_IM_WRONG:
                explain = explain + 1;
                break;
            case AICompletionType.EXPLAIN_EXAM_FRQ_ANSWER:
                frq = frq + 1;
                break;
            case AICompletionType.MEDIA_FLASHCARDS:
                mSets = mSets + 1;
                break;
            case AICompletionType.MEDIA_NOTES:
                mNotes = mNotes + 1;
                break;

            case AICompletionType.SCAN_AND_SOLVE:
                scans = scans + 1;
                break;

            case AICompletionType.VIDEO_GENERATION:
                aiV = aiV + 1;
                break;

            case AICompletionType.LESSON_PLAN:
            case AICompletionType.RUBRIC_GENERATOR:
            case AICompletionType.WRITING_FEEDBACK:
            case AICompletionType.READING_TEXT:
            case AICompletionType.WORKSHEET_GENERATOR:
            case AICompletionType.TEXT_LEVELER:
            case AICompletionType.PROFESSIONAL_EMAIL:
            case AICompletionType.REPORT_CARD_COMMENTS:
            case AICompletionType.TEXT_PROOFREADER:
            case AICompletionType.TEACHER_TOOL_FIELDS_HELPER:
            case AICompletionType.TEXT_PARAPHRASER:
            case AICompletionType.MAKE_IT_RELEVANT:
            case AICompletionType.CLASS_NEWSLETTER_GENERATOR:
            case AICompletionType.VOCABULARY_LIST_GENERATOR:
            case AICompletionType.DISCUSSION_PROMPT_GENERATOR:
            case AICompletionType.WRITING_PROMPT_GENERATOR:
            case AICompletionType.MATH_WORD_PROBLEM_GENERATOR:
            case AICompletionType.SCIENCE_LAB_GENERATOR:
            case AICompletionType.MULTIPLE_CHOICE_QUIZ_GENERATOR:
            case AICompletionType.DEBATE_SCENARIO_GENERATOR:
            case AICompletionType.ICE_BREAKER_ACTIVITIES:
            case AICompletionType.CONTENT_SUMMARIZER:
            case AICompletionType.JEOPARDY_GAME_GENERATOR:
            case AICompletionType.DECODABLE_TEXT_GENERATOR:
            case AICompletionType.STANDARDS_UNPACKER:
            case AICompletionType.MATH_SPIRAL_REVIEW_GENERATOR:
            case AICompletionType.JOKE_GENERATOR:
            case AICompletionType.TEXT_TRANSLATOR:
            case AICompletionType.STORY_WORD_PROBLEMS_GENERATOR:
            case AICompletionType.BIP_GENERATOR:
            case AICompletionType.TIME_BASED_ACTIVITY_GENERATOR:
            case AICompletionType.RECOMMENDATION_LETTER_GENERATOR:
            case AICompletionType.PERFORMANCE_TASK_GENERATOR:
            case AICompletionType.PLC_AGENDA_GENERATOR:
            case AICompletionType.SYLLABUS_GENERATOR:
            case AICompletionType.SONG_GENERATOR:
                tools = tools + 1;
                break;

            case AICompletionType.ASSIGNMENT_TITLE_DESC_GENERATOR:
                assignments = assignments + 1;
                break;

            //ignored, or else this would double count
            case AICompletionType.ASSIGNMENT_RUBRIC_GENERATOR:
            case AICompletionType.ASSIGNMENT_PRIOR_KNOWLEDGE_GENERATOR:
            case AICompletionType.ASSIGNMENT_GRADER:
            case AICompletionType.ASSIGNMENT_FEEDBACK_GENERATOR:
            case AICompletionType.ASSIGNMENT_CHAT:
            case AICompletionType.ASSIGNMENT_MISC_INITIAL_SETTINGS:
            case AICompletionType.ASSIGNMENT_RULES_GENERATOR:
            case AICompletionType.ASSIGNMENT_STANDARDS_GENERATOR:
                break;

            // biome-ignore lint: complexity/noUselessSwitchCase
            case AICompletionType.FLASHCARD_AUTOCOMPLETE:
            // biome-ignore lint: complexity/noUselessSwitchCase
            case AICompletionType.NOTE_AUTOCOMPLETE:
            default:
                curPrompts = curPrompts + 1;
                break;
        }

        return {
            ...data,
            user: {
                ...data.user,
                ai: {
                    ...data.user.ai,
                    curPrompts,
                    vNotes,
                    vSets,
                    pNotes,
                    pSets,
                    nTests,
                    nSets,
                    chats,
                    explain,
                    frq,
                    mSets,
                    mNotes,
                    scans,
                    aiV,
                },
            },
        };
    });

    return await callSubmitAICompletion({ ...input, timestamp: now().toString() }, serverClient);
};

// MAKE SURE THIS IS ALWAYS IN SYNC WITH BACKEND

export const validateSelectedPages = ({
    pdfPagesInputValue,
    media,
    isFileVideo,
    updateStates,
    noteId,
}: {
    pdfPagesInputValue: string;
    media?: Media | null;
    isFileVideo: boolean;
    updateStates: (states: { pdfPages: number[] | null }) => void;
    noteId?: string;
}) => {
    if ((noteId && !media) || isFileVideo) return { valid: true };
    if (!media) return { valid: false, error: "Media not found" };

    if (pdfPagesInputValue === "ALL") {
        updateStates({ pdfPages: null });
        return { valid: true, pages: null };
    }

    const availablePages = media.pages || 0;

    const inputPagesInGoodFormat = pdfPagesInputValue.split(",").every(page => {
        const [start, end] = page.split("-").map(Number);
        if (!end && !start) return true;
        return !Number.isNaN(start) && (!end || !Number.isNaN(end));
    });

    if (!inputPagesInGoodFormat)
        return {
            valid: false,
            error: "Invalid selected pages",
        };

    const selectedIndividualPages = pdfPagesInputValue
        .split(",")
        .map(page => {
            const [start, end] = page.split("-").map(Number);
            return (end ? Array.from({ length: end - start + 1 }, (_, i) => start + i) : [start]) as number[];
        })
        .reduce((acc, val) => acc.concat(val), [])
        .filter(Boolean);

    const allPagesWithinRange = selectedIndividualPages.every(page => page <= availablePages);

    if (!allPagesWithinRange)
        return {
            valid: false,
            error: "Invalid page range",
        };

    updateStates({
        pdfPages: selectedIndividualPages,
    });

    return {
        valid: true,
        pages: selectedIndividualPages,
    };
};

type AllNeverKeys = {
    questions?: never;
    note?: never;
    flashcards?: never;
    follow_up?: never;
    response?: never;
    text?: never;
};

export type AI_PROMPT_OUTPUT_MAP = {
    [AICompletionType.NOTE_TEST]: Omit<AllNeverKeys, "questions"> & {
        questions: {
            question_text: string;
            choices: string[];
            right_answer: number;
        }[];
    };
    [AICompletionType.GENERATE_SIMILAR_QUESTIONS]: Omit<AllNeverKeys, "questions"> & {
        questions: {
            question_text: string;
            choices: string[];
            right_answer: number;
        }[];
    };
    [AICompletionType.MEDIA_NOTES]: Omit<AllNeverKeys, "note"> & { note: string };
    [AICompletionType.MEDIA_FLASHCARDS]: Omit<AllNeverKeys, "flashcards"> & {
        flashcards: { term: string; definition: string }[];
    };
    [AICompletionType.NOTE_FLASHCARDS]: Omit<AllNeverKeys, "flashcards"> & {
        flashcards: { term: string; definition: string }[];
    };
    [AICompletionType.NOTE_CHAT]: Omit<AllNeverKeys, "note" | "response" | "follow_up"> & {
        note: string;
        response: string;
        follow_up: string;
        flashcards: { term: string; definition: string }[];
    };
    [AICompletionType.FLASHCARD_CHAT]: Omit<AllNeverKeys, "flashcards" | "response" | "follow_up"> & {
        flashcards: { term: string; definition: string }[];
        response: string;
        follow_up: string;
    };
    [AICompletionType.FLASHCARD_AUTOCOMPLETE]: Omit<AllNeverKeys, "text"> & { text: string };
    [AICompletionType.NOTE_AUTOCOMPLETE]: Omit<AllNeverKeys, "text"> & { text: string };
    [AICompletionType.EXPLAIN_EXAM_FRQ_ANSWER]: Omit<AllNeverKeys, "questions"> & {
        questions: {
            parts: {
                feedback: string;
                pointsTotal: number;
                pointsEarned: number;
            }[];
        }[];
    };
    [AICompletionType.LESSON_PLAN]: {
        title: string;
        sections: {
            title: string;
            total_time: string;
            description: string;
        }[];
    };
    [AICompletionType.WRITING_FEEDBACK]: {
        title: string;
        text: string;
    };
    [AICompletionType.TEXT_PROOFREADER]: {
        title: string;
        text: string;
        changes: string[];
    };
    [AICompletionType.RUBRIC_GENERATOR]: {
        title: string;
        total_points: number;
        grading_scale: {
            grade: string;
            min_points: number;
            max_points: number;
            description: string;
        }[];
        notes: string[];
        rubric: {
            title: string;
            categories: {
                point_value: number;
                description: string;
            }[];
        }[];
    };
    [AICompletionType.READING_TEXT]: {
        title: string;
        text: string;
    };
    [AICompletionType.WORKSHEET_GENERATOR]: {
        title: string;
        sections: {
            title: string;
            content: string;
        }[];
    };
    [AICompletionType.TEXT_LEVELER]: {
        title: string;
        text: string;
    };
    [AICompletionType.PROFESSIONAL_EMAIL]: {
        title: string;
        output: string;
    };
    [AICompletionType.REPORT_CARD_COMMENTS]: {
        title: string;
        output: string;
    };
    [AICompletionType.READING_TEXT]: {
        title: string;
        text: string;
    };
    [AICompletionType.ASSIGNMENT_RUBRIC_GENERATOR]: {
        A: { score: number; condition: string };
        B: { score: number; condition: string };
        C: { score: number; condition: string };
        D: { score: number; condition: string };
        F: { score: number; condition: string };
    };
    [AICompletionType.ASSIGNMENT_PRIOR_KNOWLEDGE_GENERATOR]: {
        title: string;
        priorKnowledge: string[];
    };
    [AICompletionType.ASSIGNMENT_GRADER]: {
        score: number;
        feedback: {
            strength: string;
            improvement: string;
            followUp: string;
        };
    };
    [AICompletionType.ASSIGNMENT_FEEDBACK_GENERATOR]: {
        strength: string;
        improvement: string;
        followUp: string;
    };
    [AICompletionType.ASSIGNMENT_CHAT]: {
        response: string;
    };
    [AICompletionType.ASSIGNMENT_MISC_INITIAL_SETTINGS]: {
        subject: string;
        topic: string;
        initialPrompt: string;
        suggestions: string[];
    };
    [AICompletionType.ASSIGNMENT_RULES_GENERATOR]: {
        rules: string[];
    };
    [AICompletionType.ASSIGNMENT_TITLE_DESC_GENERATOR]: {
        title: string;
        description: string;
    };
    [AICompletionType.ASSIGNMENT_STANDARDS_GENERATOR]: {
        standards: string[];
    };
    [AICompletionType.TEACHER_TOOL_FIELDS_HELPER]: any;
    [AICompletionType.TEXT_PARAPHRASER]: {
        title: string;
        output: string;
    };
    [AICompletionType.MAKE_IT_RELEVANT]: {
        title: string;
        output: string;
    };
    [AICompletionType.CLASS_NEWSLETTER_GENERATOR]: {
        title: string;
        output: string;
    };
    [AICompletionType.VOCABULARY_LIST_GENERATOR]: {
        title: string;
        text: {
            title: string;
            overview: {
                gradeLevel: string;
                topic: string;
                numberOfWords: number;
            };
            vocabularyWords: {
                word: string;
                definition?: string;
                example?: string;
                pronunciation?: string;
                partOfSpeech?: string;
                synonyms?: string[];
                antonyms?: string[];
                etymology?: string;
            }[];
        };
    };
    [AICompletionType.DISCUSSION_PROMPT_GENERATOR]: {
        title: string;
        sections: {
            section_title: string;
            section_content: string[];
        }[];
    };
    [AICompletionType.WRITING_PROMPT_GENERATOR]: {
        title: string;
        output: string[];
    };
    [AICompletionType.MATH_WORD_PROBLEM_GENERATOR]: {
        title: string;
        questions: {
            question_text: string;
            answer: string;
        }[];
    };
    [AICompletionType.SCIENCE_LAB_GENERATOR]: {
        title: string;
        sections: {
            section_title: string;
            content: string[];
        }[];
        notes: string;
    };
    [AICompletionType.MULTIPLE_CHOICE_QUIZ_GENERATOR]: {
        title: string;
        questions: {
            question_text: string;
            choices: string[];
            correct_answers: number[];
        }[];
    };
    [AICompletionType.DEBATE_SCENARIO_GENERATOR]: {
        title: string;
        output: string;
    };
    [AICompletionType.ICE_BREAKER_ACTIVITIES]: {
        title: string;
        sections: {
            section_title: string;
            section_content: string[];
            subsections: {
                subsection_title: string;
                subsection_content: string[];
            }[];
        }[];
    };
    [AICompletionType.CONTENT_SUMMARIZER]: {
        output: string;
    };
    [AICompletionType.JEOPARDY_GAME_GENERATOR]: {
        output: string;
    };
    [AICompletionType.DECODABLE_TEXT_GENERATOR]: {
        output: string;
    };
    [AICompletionType.STANDARDS_UNPACKER]: {
        output: string;
    };
    [AICompletionType.JOKE_GENERATOR]: {
        output: string;
    };
    [AICompletionType.TEXT_TRANSLATOR]: {
        output: string;
    };
    [AICompletionType.STORY_WORD_PROBLEMS_GENERATOR]: {
        output: string;
    };
    [AICompletionType.BIP_GENERATOR]: {
        output: string;
    };
    [AICompletionType.TIME_BASED_ACTIVITY_GENERATOR]: {
        output: string;
    };
    [AICompletionType.RECOMMENDATION_LETTER_GENERATOR]: {
        output: string;
    };
    [AICompletionType.PERFORMANCE_TASK_GENERATOR]: {
        output: string;
    };
    [AICompletionType.PLC_AGENDA_GENERATOR]: {
        output: string;
    };
    [AICompletionType.SYLLABUS_GENERATOR]: {
        output: string;
    };
    [AICompletionType.SONG_GENERATOR]: {
        output: string;
    };
    [AICompletionType.MATH_SPIRAL_REVIEW_GENERATOR]: {
        output: string;
    };
    [AICompletionType.SCAN_AND_SOLVE]: {
        subject: string;
        topic: string;
        context: string | null;
        questions: {
            text: string;
            type: QuestionTypeEnum;
            choices?: string[];
            answer: {
                text: string;
                steps: string[];
            };
        }[];
    };
};

export const AI_PROMPT_OUTPUT_ENDINGS: {
    [key in AICompletionType]?: string[];
} = {
    [AICompletionType.NOTE_TEST]: ["}]}"],
    [AICompletionType.GENERATE_SIMILAR_QUESTIONS]: ["}]}"],
    [AICompletionType.MEDIA_NOTES]: ['"}'],
    [AICompletionType.MEDIA_FLASHCARDS]: ["}]}"],
    [AICompletionType.NOTE_FLASHCARDS]: ["}]}"],
    [AICompletionType.NOTE_CHAT]: [`"}`, "null}", `"}]}`],
    [AICompletionType.FLASHCARD_CHAT]: [`"}`, "null}", `"}]}`],
    [AICompletionType.EXPLAIN_WHY_IM_WRONG]: [`"}]}`],
    [AICompletionType.EXPLAIN_EXAM_FRQ_ANSWER]: [`"}]}`],
    [AICompletionType.FLASHCARD_AUTOCOMPLETE]: [`"}`],
    [AICompletionType.NOTE_AUTOCOMPLETE]: [`"}`],
    [AICompletionType.SCAN_AND_SOLVE]: [`"]}}]}`, `"}]}`, `]}]}`],
    [AICompletionType.LESSON_PLAN]: [`"}`],
    [AICompletionType.WRITING_FEEDBACK]: [`"}`],
    [AICompletionType.TEXT_PROOFREADER]: [`"}`],
    [AICompletionType.RUBRIC_GENERATOR]: [`"}]}]}`],
    [AICompletionType.READING_TEXT]: [`"}`],
    [AICompletionType.WORKSHEET_GENERATOR]: [`"}`],
    [AICompletionType.TEXT_LEVELER]: [`"}`],
    [AICompletionType.PROFESSIONAL_EMAIL]: [`"}`],
    [AICompletionType.REPORT_CARD_COMMENTS]: [`"}`],
    [AICompletionType.ASSIGNMENT_RUBRIC_GENERATOR]: [`"}}`],
    [AICompletionType.ASSIGNMENT_PRIOR_KNOWLEDGE_GENERATOR]: [`"]}`],
    [AICompletionType.ASSIGNMENT_GRADER]: [`"}}`],
    [AICompletionType.ASSIGNMENT_CHAT]: [`"}`],
    [AICompletionType.ASSIGNMENT_MISC_INITIAL_SETTINGS]: [`"]}`],
    [AICompletionType.ASSIGNMENT_RULES_GENERATOR]: [`"]}`],
    [AICompletionType.ASSIGNMENT_TITLE_DESC_GENERATOR]: [`"}`],
    [AICompletionType.ASSIGNMENT_STANDARDS_GENERATOR]: [`"]}`],
    [AICompletionType.TEACHER_TOOL_FIELDS_HELPER]: [`"}`],
    [AICompletionType.TEXT_PARAPHRASER]: [`"}`],
    [AICompletionType.MAKE_IT_RELEVANT]: [`"}`],
    [AICompletionType.CLASS_NEWSLETTER_GENERATOR]: [`"}`],
    [AICompletionType.VOCABULARY_LIST_GENERATOR]: [`"]}}`],
    [AICompletionType.DISCUSSION_PROMPT_GENERATOR]: [`"]}]}`],
    [AICompletionType.WRITING_PROMPT_GENERATOR]: [`"]}`],
    [AICompletionType.MATH_WORD_PROBLEM_GENERATOR]: [`"}]}`],
    [AICompletionType.SCIENCE_LAB_GENERATOR]: [`"}`],
    [AICompletionType.MULTIPLE_CHOICE_QUIZ_GENERATOR]: [`]}]}`],
    [AICompletionType.DEBATE_SCENARIO_GENERATOR]: [`"}`],
    [AICompletionType.ICE_BREAKER_ACTIVITIES]: [`"]}]}]}`],
    [AICompletionType.CONTENT_SUMMARIZER]: ["}}", '"}', '"}'],
    [AICompletionType.JEOPARDY_GAME_GENERATOR]: ["}}}", '"}]}', '"}}', "]}]}"],
    [AICompletionType.DECODABLE_TEXT_GENERATOR]: ["]}", '"]}', '"}]', '"]}'],
    [AICompletionType.STANDARDS_UNPACKER]: ["}}", '"}'],
    [AICompletionType.JOKE_GENERATOR]: ["]}", '"}}', '"}]'],
    [AICompletionType.TEXT_TRANSLATOR]: ["}}", '"}'],
    [AICompletionType.STORY_WORD_PROBLEMS_GENERATOR]: ["]}", '"}}', '"}]', '"]}'],
    [AICompletionType.BIP_GENERATOR]: ["}}", '"}'],
    [AICompletionType.TIME_BASED_ACTIVITY_GENERATOR]: ["}}", '"}'],
    [AICompletionType.RECOMMENDATION_LETTER_GENERATOR]: ["}}", '"}'],
    [AICompletionType.PERFORMANCE_TASK_GENERATOR]: ["}}", '"}'],
    [AICompletionType.PLC_AGENDA_GENERATOR]: ["}}", '"}'],
    [AICompletionType.SYLLABUS_GENERATOR]: ["}}", '"}'],
    [AICompletionType.SONG_GENERATOR]: ["}}}}", '"}]}', '"}}'],
    [AICompletionType.MATH_SPIRAL_REVIEW_GENERATOR]: ["}}}", '"}}', '"}]}'],
};

export const TEACHER_TOOLS_OUTPUT_FORMATTER = <T extends keyof AI_PROMPT_OUTPUT_MAP>(
    type: T,
    output: AI_PROMPT_OUTPUT_MAP[T]
) => {
    switch (type) {
        case AICompletionType.LESSON_PLAN:
            return {
                title: output.title,
                content: output.sections
                    .map(section => {
                        return `## ${section.title}\n\n**Total Time:** ${section.total_time}\n\n${section.description}\n`;
                    })
                    .join("\n"),
            };
        case AICompletionType.WORKSHEET_GENERATOR: {
            const content = `${output.sections
                .map(section => {
                    const questionsContent = section.content.questions
                        .map((q, index) => {
                            const questionNumber = index + 1;
                            if (typeof q === "string") {
                                return `${questionNumber}. ${q}`;
                            } else {
                                return `${questionNumber}. ${q.question}\n\n${q.choices
                                    .map(choice => `   ${choice}`)
                                    .join("\n\n")}`;
                            }
                        })
                        .join("\n\n");

                    return `## ${section.title}\n\n${questionsContent}`;
                })
                .join("\n\n")}
            \n\n\n\n## Answer Key\n\n${output.sections
                .map(
                    section =>
                        `### ${section.title}\n\n${section.answerKey.map((answer, index) => `${index + 1}. ${answer}`).join("\n\n")}`
                )
                .join("\n\n")}\n\n## Notes\n\n
        ${output.notes.join("\n\n")}`;

            return {
                title: output.title,
                content,
            };
        }
        case AICompletionType.WRITING_FEEDBACK: {
            return { title: output.title, content: output.text };
        }
        case AICompletionType.TEXT_PROOFREADER: {
            return {
                title: output.title,
                content: `${output.text}\n\n## Changes\n 
             \n\n${output.changes.map(change => `- ${change}`).join("\n")}`,
            };
        }
        case AICompletionType.RUBRIC_GENERATOR: {
            const pointColumns = output.rubric[0].categories.length;
            let result = "| Criteria |";
            for (let i = pointColumns; i > 0; i--) {
                result += ` ${i} Points |`;
            }
            result += "\n|";

            for (let i = 0; i <= pointColumns; i++) {
                result += ":---:|";
            }
            result += "\n";

            output.rubric.map(category => {
                result += `| **${category.title}** |`;
                for (const c of category.categories.sort((a, b) => b.point_value - a.point_value)) {
                    result += ` ${c.description} |`;
                }
                result += "\n";
            });

            return {
                title: output.title,
                content: `## Rubric Categories
${result}

**Total Points: ${output.total_points}**

## Grading Scale
| Grade | Points Range | Description |
|-------|-------------|-------------|
${output.grading_scale
    .map(scale => `| ${scale.grade} | ${scale.min_points} - ${scale.max_points} | ${scale.description} |`)
    .join("\n")}

## Notes
${output.notes.map(note => `- ${note}`).join("\n")}`,
            };
        }
        case AICompletionType.READING_TEXT: {
            return { title: output.title, content: output.text };
        }
        case AICompletionType.TEXT_LEVELER: {
            return { title: output.title, content: output.text };
        }
        case AICompletionType.ASSIGNMENT_RUBRIC_GENERATOR: {
            return {
                title: output.title,
                content:
                    `## Grading Rubric\n\n| Grade | Score | Condition |\n|-------|-------|-----------|\n` +
                    `|-------|-------|-----------|\n` +
                    `| A     | ${output.A.score} | ${output.A.condition} |\n` +
                    `| B     | ${output.B.score} | ${output.B.condition} |\n` +
                    `| C     | ${output.C.score} | ${output.C.condition} |\n` +
                    `| D     | ${output.D.score} | ${output.D.condition} |\n` +
                    `| F     | ${output.F.score} | ${output.F.condition} |\n`,
            };
        }
        case AICompletionType.ASSIGNMENT_PRIOR_KNOWLEDGE_GENERATOR: {
            return {
                title: output.title,
                content:
                    `# Prior Knowledge\n\n` +
                    output.priorKnowledge.map((knowledge, index) => `${index + 1}. ${knowledge}`).join("\n"),
            };
        }
        case AICompletionType.ASSIGNMENT_RULES_GENERATOR: {
            return {
                title: output.title,
                content:
                    `# Rules for AI Chatbot Engagement\n\n` +
                    output.rules.map((rule, index) => `${index + 1}. ${rule}`).join("\n"),
            };
        }
        case AICompletionType.ASSIGNMENT_MISC_INITIAL_SETTINGS: {
            return {
                title: output.title,
                content:
                    `# Assignment Details\n\n` +
                    `- **Subject**: ${output.subject}\n` +
                    `- **Topic**: ${output.topic}\n\n` +
                    `- **Initial Prompt**: ${output.initialPrompt}\n\n` +
                    `- **Suggested Responses**:\n` +
                    output.suggestions.map((suggestion, index) => `  ${index + 1}. ${suggestion}`).join("\n"),
            };
        }
        case AICompletionType.ASSIGNMENT_CHAT: {
            return {
                title: output.title,
                content:
                    `# Chatbot Assignment Guidelines\n\n` +
                    `- **Initial Prompt**: ${output.initialPrompt}\n\n` +
                    `- **Rules**:\n` +
                    output.rules.map((rule, index) => `  ${index + 1}. ${rule}`).join("\n") +
                    `\n\n- **Rubric**:\n` +
                    `| Grade | Score | Condition |\n` +
                    `|-------|-------|-----------|\n` +
                    `| A     | ${output.rubric.A.score} | ${output.rubric.A.condition} |\n` +
                    `| B     | ${output.rubric.B.score} | ${output.rubric.B.condition} |\n` +
                    `| C     | ${output.rubric.C.score} | ${output.rubric.C.condition} |\n` +
                    `| D     | ${output.rubric.D.score} | ${output.rubric.D.condition} |\n` +
                    `| F     | ${output.rubric.F.score} | ${output.rubric.F.condition} |\n`,
            };
        }
        case AICompletionType.ASSIGNMENT_TITLE_DESC_GENERATOR: {
            return {
                title: output.title,
                content:
                    `# Assignment Title and Description\n\n` +
                    `- **Title**: ${output.title}\n\n` +
                    `- **Description**: ${output.description}\n`,
            };
        }
        case AICompletionType.ASSIGNMENT_STANDARDS_GENERATOR: {
            return {
                title: output.title,
                content:
                    `# Educational Standards/Objectives\n\n` +
                    output.standards.map((standard, index) => `${index + 1}. ${standard}`).join("\n"),
            };
        }
        case AICompletionType.TEXT_PARAPHRASER: {
            return { title: output.title, content: `# Paraphrased Text\n\n` + `${output.output}` };
        }
        case AICompletionType.MAKE_IT_RELEVANT: {
            return { title: output.title, content: output.output };
        }
        case AICompletionType.CLASS_NEWSLETTER_GENERATOR: {
            return { title: output.title, content: output.output };
        }
        case AICompletionType.VOCABULARY_LIST_GENERATOR: {
            let result = `## Overview\n`;

            result += output?.overview?.gradeLevel ? `- **Grade Level**: ${output.overview.gradeLevel}\n` : "";
            result += output?.overview?.topic ? `- **Topic**: ${output.overview.topic}\n` : "";
            result += output?.overview?.numberOfWords
                ? `- **Number of Words**: ${output.overview.numberOfWords}\n`
                : "";

            result += `\n## Vocabulary Words\n`;
            result +=
                output?.vocabularyWords
                    ?.map((wordObj, index) => {
                        let wordText = wordObj?.word ? `  ${index + 1}. **${wordObj.word}**\n` : "";
                        wordText += wordObj?.definition ? `     - **Definition**: ${wordObj.definition}\n` : "";
                        wordText += wordObj?.example ? `     - **Example**: ${wordObj.example}\n` : "";
                        wordText += wordObj?.pronunciation
                            ? `     - **Pronunciation**: ${wordObj.pronunciation}\n`
                            : "";
                        wordText += wordObj?.partOfSpeech ? `     - **Part of Speech**: ${wordObj.partOfSpeech}\n` : "";
                        wordText += wordObj?.synonyms?.length
                            ? `     - **Synonyms**: ${wordObj.synonyms.join(", ")}\n`
                            : "";
                        wordText += wordObj?.antonyms?.length
                            ? `     - **Antonyms**: ${wordObj.antonyms.join(", ")}\n`
                            : "";
                        wordText += wordObj?.etymology ? `     - **Etymology**: ${wordObj.etymology}\n` : "";

                        return wordText;
                    })
                    .join("\n") || "";

            return { title: output.title, content: result };
        }
        case AICompletionType.DISCUSSION_PROMPT_GENERATOR: {
            return {
                title: output.title,
                content: output.sections
                    .map(
                        section =>
                            `## ${section.section_title}\n` +
                            section.section_content.map((content, index) => `${index + 1}. ${content}`).join("\n")
                    )
                    .join("\n\n"),
            };
        }
        case AICompletionType.WRITING_PROMPT_GENERATOR: {
            return {
                title: output.title,
                content: output.content.map((prompt, i) => `## Prompt ${i + 1}\n\n${prompt}`).join("\n\n"),
            };
        }
        case AICompletionType.MATH_WORD_PROBLEM_GENERATOR: {
            return {
                title: output.title,
                content: output.questions
                    .map(
                        (question, index) =>
                            `${index + 1}. ${question.story}\n\n   **Question:** ${question.question}\n\n   **Answer:** ${question.answer}`
                    )
                    .join("\n\n"),
            };
        }
        case AICompletionType.SCIENCE_LAB_GENERATOR: {
            return {
                title: output.title,
                content:
                    output.sections
                        .map(
                            section =>
                                `## ${section.section_title}\n` +
                                section.content
                                    .map(item => {
                                        const hasNumbering = /^\d+\./.test(item.trim());
                                        return hasNumbering ? `${item}` : `- ${item}`;
                                    })
                                    .join("\n")
                        )
                        .join("\n\n") + `\n\n\n\n\n\n**Notes:**\n\n${output.notes}`,
            };
        }
        case AICompletionType.MULTIPLE_CHOICE_QUIZ_GENERATOR: {
            return {
                title: output.title,
                content: output.questions
                    .map(
                        (question, index) =>
                            `${index + 1}. ${question.question_text}\n` +
                            question.choices
                                .map((choice, choiceIndex) => `   ${String.fromCharCode(97 + choiceIndex)}. ${choice}`)
                                .join("\n") +
                            `\n   **Correct Answer(s)**: ${question.correct_answers
                                .map(i => String.fromCharCode(97 + i))
                                .join(", ")}`
                    )
                    .join("\n\n"),
            };
        }
        case AICompletionType.DEBATE_SCENARIO_GENERATOR: {
            return { title: output.title, content: output.output };
        }
        case AICompletionType.ICE_BREAKER_ACTIVITIES: {
            return {
                title: output.title,
                content: output.activities
                    .map(
                        (activity, i) =>
                            `## Activity ${i + 1}:  ${activity.title}\n` +
                            activity.description +
                            (activity.materials ? `\n\n**Materials:**\n${activity.materials}` : "") +
                            `\n\n**Steps:**\n` +
                            activity.steps.map(step => `- ${step}`).join("\n") +
                            "\n\n" +
                            `**Objectives:**\n${activity.objectives}`
                    )
                    .join("\n\n"),
            };
        }
        case AICompletionType.PROFESSIONAL_EMAIL: {
            return { title: output.title, content: output.output };
        }
        case AICompletionType.REPORT_CARD_COMMENTS: {
            return { title: output.title, content: output.output };
        }
        case AICompletionType.CONTENT_SUMMARIZER: {
            return {
                title: output.title,
                content: output.content.map(paragraph => `${paragraph}`).join("\n\n\n\n"),
            };
        }
        case AICompletionType.JEOPARDY_GAME_GENERATOR: {
            const { title, content } = output;
            const { sections } = content;

            // Add scores row
            let result = "| |";
            for (const q of sections[0].questions) {
                result += ` $${q.score} |`;
            }
            result += "\n|";

            // Add header separator
            for (let i = 0; i <= sections[0].questions.length; i++) {
                result += "---------|";
            }
            result += "\n";

            // Add topic rows with their questions
            for (const section of sections) {
                result += `| ${section.topic} |`;
                for (const q of section.questions) {
                    result += ` ${q.question} |`;
                }
                result += "\n";
            }

            result += "\n## Answer Key\n\n";
            for (const section of sections) {
                result += `### ${section.topic}\n\n`;
                for (const q of section.questions) {
                    result += `**Q: ${q.question}**\n\n`;
                    result += `A: ${q.answer}\n\n\n`;
                }
            }

            return {
                title,
                content: result,
            };
        }
        case AICompletionType.DECODABLE_TEXT_GENERATOR: {
            return {
                title: output.title,
                content: output.content
                    .map(item => `### ${item.title}\n` + item.examples.map(example => `- ${example}`).join("\n"))
                    .join("\n\n"),
            };
        }
        case AICompletionType.STANDARDS_UNPACKER: {
            return output;
        }
        case AICompletionType.JOKE_GENERATOR: {
            return {
                title: output.title,
                content: output.content
                    .map(joke => {
                        return [`### ${joke.question}`, joke.answer].join("\n\n");
                    })
                    .join("\n\n"),
            };
        }
        case AICompletionType.TEXT_TRANSLATOR: {
            return {
                title: `${output.original_language} → ${output.translated_language}`,
                content: [
                    `## Original Text (${output.original_language})`,
                    `> *${output.original_text}*`,
                    `\n## Translation (${output.translated_language})`,
                    `> *${output.translated_text}*`,
                ].join("\n\n"),
            };
        }
        case AICompletionType.STORY_WORD_PROBLEMS_GENERATOR: {
            return {
                title: output.title,
                content: output.content
                    .map(
                        section =>
                            `### ${section.storyTitle}\n\n` +
                            `${section.story}\n\n` +
                            `**Question:** ${section.question}\n` +
                            `**Answer:** ${section.answer}`
                    )
                    .join("\n\n"),
            };
        }
        case AICompletionType.BIP_GENERATOR: {
            return output;
        }
        case AICompletionType.TIME_BASED_ACTIVITY_GENERATOR: {
            return output;
        }
        case AICompletionType.RECOMMENDATION_LETTER_GENERATOR: {
            return output;
        }
        case AICompletionType.PERFORMANCE_TASK_GENERATOR: {
            return output;
        }
        case AICompletionType.PLC_AGENDA_GENERATOR: {
            return output;
        }
        case AICompletionType.SYLLABUS_GENERATOR: {
            return output;
        }
        case AICompletionType.SONG_GENERATOR: {
            return {
                title: output.title,
                content: output.content.map(section => `## ${section.type}\n\n` + section.content).join("\n\n"),
            };
        }
        case AICompletionType.MATH_SPIRAL_REVIEW_GENERATOR: {
            return {
                title: output.title,
                content:
                    output.content.questions
                        .map(
                            (item, index) =>
                                `## Question ${index + 1}\n\n${item.question}\n\n` +
                                `**Answer:** ${item.answer}\n\n` +
                                `---`
                        )
                        .join("\n\n") + (output.content.notes ? "\n\n## Notes\n\n" + output.content.notes : ""),
            };
        }
    }
};

export const tryParsingAIOutput = <T extends keyof AI_PROMPT_OUTPUT_MAP>(
    output: string,
    type: T
): AI_PROMPT_OUTPUT_MAP[T] | null => {
    if (!AI_PROMPT_OUTPUT_ENDINGS[type]) return null;

    const ending = AI_PROMPT_OUTPUT_ENDINGS[type];

    try {
        return JSON.parse(output) as AI_PROMPT_OUTPUT_MAP[T];
    } catch {
        if (ending.some(end => output.endsWith(end))) {
            try {
                return JSON.parse(output) as AI_PROMPT_OUTPUT_MAP[T];
            } catch {
                return null;
            }
        }

        for (const end of ending) {
            try {
                return JSON.parse(output + end) as AI_PROMPT_OUTPUT_MAP[T];
            } catch {}
        }
    }
};

export const standaloneCallAI = async <T extends keyof AI_PROMPT_OUTPUT_MAP>({
    type,
    itemId,
    itemType,
    aiParams,
}: {
    type: T;
    itemId: string;
    itemType: ItemType;
    aiParams: AI_PROMPT_PARAMS_MAP[T];
}) => {
    let finalText = "";

    const timestamp = now();

    const sessionIdToken = await fetchAuthSession()
        .then(session => session.tokens.idToken.toString())
        .catch(() => null);

    const authToken: string | null = sessionIdToken;

    const customFetcher = await platform.fetch();

    /*
     *  If is running in react native, we need to use the react-native-fetch-api library
     *  because the fetch library that comes with react native doesnt support streaming
     */
    const fetcher = customFetcher ?? fetch;

    const response = await fetcher(AI_LAMBDA_URL, {
        method: "POST",
        reactNative: { textStreaming: true },
        body: JSON.stringify({
            type,
            itemId,
            itemType,
            timestamp,
            cookie: authToken,
            params: aiParams,
        }),
        ...POST_HEADERS,
    });

    await mutateUserAIUsage(type);

    if (!response.ok) {
        const mixpanel = await platform.analytics.mixpanel();
        mixpanel.track("AI Error", {
            error: response.statusText,
            type,
            itemId,
            itemType,
            timestamp,
            e: {
                ok: response.ok,
                status: response.status,
                statusText: response.statusText,
                url: response.url,
                type: response.type,
                redirected: response.redirected,
                headers: response.headers,
                body: response.body,
                bodyUsed: response.bodyUsed,
            },
        });
        // biome-ignore lint: noConsole
        console.error(response.statusText);
        throw new Error(response.statusText);
    }

    const data = response.body;
    if (!data) {
        return { response: "", parsedResponse: null };
    }
    const reader = data.getReader();
    const decoder = new TextDecoder();
    let done = false;

    while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);
        finalText += chunkValue;
    }

    return { response: finalText, parsedResponse: tryParsingAIOutput(finalText, type) };
};
