import { StoreProductEnum, SubscriptionType, UpgradeEvent } from "@knowt/syncing/graphql/schema";

export enum StripeStatus {
    INCOMPLETE = "incomplete",
    ACTIVE = "active",
    PAUSED = "paused",
    PAST_DUE = "past_due",
    CANCELLED = "canceled",
    TRIALING = "trialing",
    UNPAID = "unpaid",
}

export const SUBSCRIPTION_PRODUCT_IDS: Record<string, SubscriptionType> = {
    prod_NABiGO1rnEAwh2: SubscriptionType.SUPPORTER,
    prod_NaC2hqCfzDhmQ8: SubscriptionType.PRO,
    prod_NaC5gA8eQItjQi: SubscriptionType.LIMITLESS,
    prod_Py0qaezB92eT8W: SubscriptionType.TEACHER_PRO,
    prod_Py0qVxlT6JkPG2: SubscriptionType.TEACHER_LIMITLESS,
};

export const FEATURES_BY_SUBSCRIPTION_TYPE = {
    [SubscriptionType.BASIC]: [],
    [SubscriptionType.SUPPORTER]: [
        UpgradeEvent.FOLDER_COLOR,
        UpgradeEvent.FOLDER_EMOJI,
        UpgradeEvent.PASSWORD_PROTECT,
        UpgradeEvent.PERSONALIZE_PROFILE,
        UpgradeEvent.USERNAME_CHANGE,
        UpgradeEvent.REMOVE_ADS,
    ],
    [SubscriptionType.LIMITLESS]: [
        UpgradeEvent.FOLDER_COLOR,
        UpgradeEvent.FOLDER_EMOJI,
        UpgradeEvent.PASSWORD_PROTECT,
        UpgradeEvent.PERSONALIZE_PROFILE,
        UpgradeEvent.USERNAME_CHANGE,
        UpgradeEvent.REMOVE_ADS,
        UpgradeEvent.MAX_VIDEO_AI,
        UpgradeEvent.MAX_PDF_AI,
        UpgradeEvent.MAX_NOTE_FLASHCARDS,
        UpgradeEvent.MAX_NOTE_TESTS,
        UpgradeEvent.MAX_PROMPT,
        UpgradeEvent.MAX_EXPLAIN,
        UpgradeEvent.MAX_FRQ,
    ],
    [SubscriptionType.TEACHER_PRO]: [
        UpgradeEvent.FOLDER_COLOR,
        UpgradeEvent.FOLDER_EMOJI,
        UpgradeEvent.PASSWORD_PROTECT,
        UpgradeEvent.PERSONALIZE_PROFILE,
        UpgradeEvent.USERNAME_CHANGE,
        UpgradeEvent.REMOVE_ADS,
        UpgradeEvent.STUDENT_PROGRESS,
        UpgradeEvent.STUDY_RESTRICTIONS,
    ],
    [SubscriptionType.TEACHER_LIMITLESS]: [
        UpgradeEvent.FOLDER_COLOR,
        UpgradeEvent.FOLDER_EMOJI,
        UpgradeEvent.PASSWORD_PROTECT,
        UpgradeEvent.PERSONALIZE_PROFILE,
        UpgradeEvent.USERNAME_CHANGE,
        UpgradeEvent.REMOVE_ADS,
        UpgradeEvent.STUDENT_PROGRESS,
        UpgradeEvent.STUDY_RESTRICTIONS,
        UpgradeEvent.MAX_VIDEO_AI,
        UpgradeEvent.MAX_PDF_AI,
        UpgradeEvent.MAX_NOTE_FLASHCARDS,
        UpgradeEvent.MAX_NOTE_TESTS,
        UpgradeEvent.MAX_PROMPT,
        UpgradeEvent.MAX_EXPLAIN,
        UpgradeEvent.MAX_FRQ,
    ],
};

export const featureTypeToStoreProductEnum = (event: UpgradeEvent): StoreProductEnum => {
    switch (event) {
        case UpgradeEvent.FOLDER_COLOR:
        case UpgradeEvent.FOLDER_EMOJI:
            return StoreProductEnum.FOLDER_COLORS;

        case UpgradeEvent.PERSONALIZE_PROFILE:
            return StoreProductEnum.PROFILE_COLOR;

        default:
            return null;
    }
};

export enum STUDENT_PRICE_ID {
    SUPPORTER_MONTHLY_6 = "price_1PV2OFKXJM8qxW7Rote7zEqY",
    SUPPORTER_MONTHLY_8 = "price_1QN4mhKXJM8qxW7RdhfQlq6E",
    SUPPORTER_YEARLY_24 = "price_1Pdb44KXJM8qxW7Rh5GePvyA",

    PRO_MONTHLY_5 = "price_1NnTkiKXJM8qxW7ROrVS1dBN",
    PRO_YEARLY_36 = "price_1NnTkPKXJM8qxW7R5buhJyj8",

    LIMITLESS_MONTHLY_13 = "price_1OAECEKXJM8qxW7RtcaWi1EA",
    LIMITLESS_MONTHLY_15 = "price_1QN54wKXJM8qxW7R3nVRQt2H",
    LIMITLESS_YEARLY_96 = "price_1Pdb4ZKXJM8qxW7R3xibzqn5",
    LIMITLESS_YEARLY_60 = "price_1QkW5DKXJM8qxW7RqbsWDf2L",

    // @deprecated ADFREE_YEARLY_10 = "price_1MPrr6KXJM8qxW7RqtPwMGAU",
    // @deprecated ADFREE_MONTHLY_2 = "price_1MPrKxKXJM8qxW7R0Kr41Mq7",
    // @deprecated ADFREE_YEARLY_12 = "price_1NnTj0KXJM8qxW7Rd0cMMI61",
    // @deprecated PRO_YEARLY_26 = "price_1Mp1slKXJM8qxW7RtQyVjzZW",
    // @deprecated PRO_MONTHLY_3 = "price_1Mp1eWKXJM8qxW7RPcschuI7",
    // @deprecated LIMITLESS_MONTHLY_8 = "price_1Mp1hFKXJM8qxW7R3WBGeCmz",
    // @deprecated LIMITLESS_MONTHLY_10 = "price_1NnTl7KXJM8qxW7RtzaFwQbw",
    // @deprecated LIMITLESS_YEARLY_120 = "price_1OAEBvKXJM8qxW7RzVL0zGAO",
    // @deprecated LIMITLESS_YEARLY_48 = "price_1Q82oyKXJM8qxW7RzaOUHOjW",
}

export enum PAYPAL_STUDENT_PRICE_IDS {
    // SUPPORTER
    price_1PV2OFKXJM8qxW7Rote7zEqY = "P-9LS29303TR4650251M43DFLY",
    price_1Pdb44KXJM8qxW7Rh5GePvyA = "P-4SA917752M8328046M43DFFQ",

    // LIMITLESS
    price_1OAECEKXJM8qxW7RtcaWi1EA = "P-8KW460110G2514114M43DE3I",
    price_1Q82oyKXJM8qxW7RzaOUHOjW = "P-27T0227234411983WM43DEEI",
    price_1QkW5DKXJM8qxW7RqbsWDf2L = "P-9UV82021GM7445450M6KUFEY",
}

export enum TEACHER_PRICE_ID {
    PRO_MONTHLY_8 = "price_1PVxrZKXJM8qxW7R6hOPXOF1",
    PRO_YEARLY_36 = "price_1Pdb5dKXJM8qxW7RFoveSEx1",

    LIMITLESS_MONTHLY_13 = "price_1PVxfYKXJM8qxW7RxKqFH2VP",
    LIMITLESS_MONTHLY_15 = "price_1QN54RKXJM8qxW7RMtNr62JH",
    LIMITLESS_YEARLY_96 = "price_1Pdb6JKXJM8qxW7Ra0Sg0tMa",
    LIMITLESS_YEARLY_60 = "price_1QkUBtKXJM8qxW7Rfp15LrX9",

    // @deprecated PRO_MONTHLY_5 = "price_1P84p9KXJM8qxW7RLBCvso4X",
    // @deprecated LIMITLESS_MONTHLY_10 = "price_1P84p7KXJM8qxW7RwCCwrQoi",
    // @deprecated LIMITLESS_YEARLY_48 = "price_1QIY61KXJM8qxW7R5Nko0EI1",
}

export enum PAYPAL_TEACHER_PRICE_IDS {
    // TEACHER_PRO
    price_1PVxrZKXJM8qxW7R6hOPXOF1 = "P-315448803N3540339M43DGHA",
    price_1Pdb5dKXJM8qxW7RFoveSEx1 = "P-54P81883WL9997426M43DGAY",

    // TEACHER_LIMITLESS
    price_1PVxfYKXJM8qxW7RxKqFH2VP = "P-2N718453DS328394XM43DF2I",
    price_1QIY61KXJM8qxW7R5Nko0EI1 = "P-1F1327677G697734TM43DFTY",
    price_1QkUBtKXJM8qxW7Rfp15LrX9 = "P-0R641679VX407444GM6KUE4Y",
}

export type PaymentFrequency = "Month" | "Year";

export type PricingOption = {
    type:
        | "Enterprise"
        | "Teacher Basic"
        | "Teacher Plus"
        | "Teacher Ultra"
        | "Student Basic"
        | "Student Plus"
        | "Student Ultra";
    subscriptionType: SubscriptionType;
    interval: PaymentFrequency;
    price: string;
    priceId?: STUDENT_PRICE_ID | TEACHER_PRICE_ID;
    oldPrice?: string;
    pricePerMonth?: string;
    isPicker?: boolean;
    needContactUs?: boolean;
    androidPriceId?: string;
};

type STUDENTS_OPTION =
    | "MONTHLY_BASIC"
    | "MONTHLY_SUPPORTER"
    | "ANNUALLY_SUPPORTER"
    | "MONTHLY_LIMITLESS"
    | "ANNUALLY_LIMITLESS"
    | "ANNUALLY_LIMITLESS_HIGHER";

type TEACHERS_OPTION =
    | "MONTHLY_BASIC"
    | "MONTHLY_PRO"
    | "ANNUALLY_PRO"
    | "MONTHLY_LIMITLESS"
    | "ANNUALLY_LIMITLESS"
    | "ANNUALLY_LIMITLESS_HIGHER";

export const STUDENTS_PRICING_OPTIONS: Record<STUDENTS_OPTION, PricingOption> = {
    MONTHLY_BASIC: {
        type: "Student Basic",
        subscriptionType: SubscriptionType.BASIC,
        interval: "Month",
        price: "$0",
        pricePerMonth: "$0",
    },
    MONTHLY_SUPPORTER: {
        type: "Student Plus",
        subscriptionType: SubscriptionType.SUPPORTER,
        interval: "Month",
        price: "$7.99",
        pricePerMonth: "$7.99",
        priceId: STUDENT_PRICE_ID.SUPPORTER_MONTHLY_8,
    },
    ANNUALLY_SUPPORTER: {
        type: "Student Plus",
        subscriptionType: SubscriptionType.SUPPORTER,
        interval: "Year",
        price: "$23.99",
        pricePerMonth: "$1.99",
        oldPrice: "$7.99",
        priceId: STUDENT_PRICE_ID.SUPPORTER_YEARLY_24,
    },
    MONTHLY_LIMITLESS: {
        type: "Student Ultra",
        subscriptionType: SubscriptionType.LIMITLESS,
        interval: "Month",
        price: "$14.99",
        pricePerMonth: "$14.99",
        priceId: STUDENT_PRICE_ID.LIMITLESS_MONTHLY_15,
    },
    ANNUALLY_LIMITLESS: {
        type: "Student Ultra",
        subscriptionType: SubscriptionType.LIMITLESS,
        interval: "Year",
        price: "$59.99",
        pricePerMonth: "$4.99",
        oldPrice: "$14.99",
        priceId: STUDENT_PRICE_ID.LIMITLESS_YEARLY_60,
        androidPriceId: `limitless:${STUDENT_PRICE_ID.LIMITLESS_YEARLY_60?.toLowerCase().replace("_", "-")}`,
    },
    ANNUALLY_LIMITLESS_HIGHER: {
        type: "Student Ultra",
        subscriptionType: SubscriptionType.LIMITLESS,
        interval: "Year",
        price: "$95.99",
        pricePerMonth: "$7.99",
        oldPrice: "$14.99",
        priceId: STUDENT_PRICE_ID.LIMITLESS_YEARLY_96,
    },
};

export const TEACHERS_PRICING_OPTIONS: Record<TEACHERS_OPTION, PricingOption> = {
    MONTHLY_BASIC: {
        type: "Teacher Basic",
        subscriptionType: SubscriptionType.BASIC,
        interval: "Month",
        price: "$0",
    },
    MONTHLY_PRO: {
        type: "Teacher Plus",
        subscriptionType: SubscriptionType.TEACHER_PRO,
        interval: "Month",
        price: "$7.99",
        priceId: TEACHER_PRICE_ID.PRO_MONTHLY_8,
    },
    ANNUALLY_PRO: {
        type: "Teacher Plus",
        subscriptionType: SubscriptionType.TEACHER_PRO,
        interval: "Year",
        price: "$35.99",
        pricePerMonth: "$2.99",
        oldPrice: "$7.99",
        priceId: TEACHER_PRICE_ID.PRO_YEARLY_36,
    },
    MONTHLY_LIMITLESS: {
        type: "Teacher Ultra",
        subscriptionType: SubscriptionType.TEACHER_LIMITLESS,
        interval: "Month",
        price: "$14.99",
        priceId: TEACHER_PRICE_ID.LIMITLESS_MONTHLY_13,
    },
    ANNUALLY_LIMITLESS: {
        type: "Teacher Ultra",
        subscriptionType: SubscriptionType.TEACHER_LIMITLESS,
        interval: "Year",
        price: "$59.99",
        pricePerMonth: "$4.99",
        oldPrice: "$14.99",
        priceId: TEACHER_PRICE_ID.LIMITLESS_YEARLY_60,
    },
    ANNUALLY_LIMITLESS_HIGHER: {
        type: "Teacher Ultra",
        subscriptionType: SubscriptionType.TEACHER_LIMITLESS,
        interval: "Year",
        price: "$95.99",
        pricePerMonth: "$7.99",
        oldPrice: "$14.99",
        priceId: STUDENT_PRICE_ID.LIMITLESS_YEARLY_96,
    },
};

export const getPlanDetailsFromPriceId = (priceId: string) => {
    return (
        Object.values(STUDENTS_PRICING_OPTIONS).find(option => option.priceId === priceId) ??
        Object.values(TEACHERS_PRICING_OPTIONS).find(option => option.priceId === priceId)
    );
};

export const getPlanAnnualPrice = (subscriptionType: SubscriptionType) => {
    switch (subscriptionType) {
        case SubscriptionType.SUPPORTER:
            return 6.0;
        case SubscriptionType.LIMITLESS:
            return 95.99;
        case SubscriptionType.TEACHER_PRO:
            return 35.99;
        case SubscriptionType.TEACHER_LIMITLESS:
            return 95.99;
        default:
            return 0;
    }
};

export const getPlanName = (subscriptionType: SubscriptionType | null | undefined, isTrialing?: boolean) => {
    switch (subscriptionType) {
        case SubscriptionType.BASIC:
            return "Basic";
        case SubscriptionType.SUPPORTER:
            return "Student Plus";
        case SubscriptionType.PRO:
            return "Student Pro";
        case SubscriptionType.LIMITLESS:
            return "Student Ultra";
        case SubscriptionType.TEACHER_PRO:
            if (isTrialing) {
                return "Plus Free Trial";
            }
            return "Teacher Plus";
        case SubscriptionType.TEACHER_LIMITLESS:
            if (isTrialing) {
                return "Ultra Free Trial";
            }
            return "Teacher Ultra";
        default:
            return "Basic";
    }
};
