import { TIME_SECONDS, now } from "@/utils/dateTimeUtils";
// https://stripe.com/docs/api/subscriptions/object#subscription_object-status
import {
    AccountType,
    DPAType,
    Organization,
    OrganizationSharing,
    OrganizationStage,
    SubscriptionType,
    UserDetails,
} from "@knowt/syncing/graphql/schema";
import { getOrganizationalSettings, getUserPlan } from "./graphqlUtils";
import { PricingOption, StripeStatus } from "./subscriptionConstants";
import { LocalUser } from "./types";
import { isUnderAge } from "./utils";
import dayjs from "dayjs";

export const isFreeTrialAllowed = (lastTrialTime?: number | null) => {
    // if lastTrialTime is null, then the user has never had a trial.
    return !lastTrialTime;
};

export const getPricingCardProps = (pricingOption: PricingOption, user?: UserDetails, organization?: Organization) => {
    const subscriptionType = user?.subscriptionType ?? SubscriptionType.BASIC;
    const isSubscriptionCancelling = !!user?.subscriptions?.[0].cancel_at_period_end;
    const isTrialing = user?.subscriptions?.[0].status === StripeStatus.TRIALING;

    // TEMP teachers
    const adsEnabled = canShowAds({ user, organization });
    if (pricingOption.type === "Teacher Basic" && subscriptionType === SubscriptionType.BASIC) {
        return {
            isCurrentPlan: adsEnabled,
            buttonText: adsEnabled ? "already on plan" : "select plan",
        };
    }

    if (pricingOption.type === "Teacher Basic" && subscriptionType === SubscriptionType.BASIC) {
        return {
            isCurrentPlan: !adsEnabled,
            buttonText: adsEnabled ? "upgrade" : "already on plan",
        };
    }

    const isCurrentPlan = user
        ? pricingOption.subscriptionType.toLowerCase() !== SubscriptionType.BASIC.toLowerCase()
            ? pricingOption.subscriptionType.toLowerCase() === subscriptionType?.toLowerCase() &&
              user?.subscriptions?.[0]?.interval?.toLowerCase() === pricingOption.interval.toLowerCase()
            : subscriptionType === SubscriptionType.BASIC
        : pricingOption.subscriptionType.toLowerCase() === SubscriptionType.BASIC.toLowerCase();

    const planTypesInOrder = ["basic", "supporter", "pro", "limitless"];

    const isABetterPlan =
        user &&
        subscriptionType !== SubscriptionType.BASIC &&
        planTypesInOrder.indexOf(subscriptionType.toLowerCase()) <
            planTypesInOrder.indexOf(pricingOption.subscriptionType.toLowerCase());

    const isAWorsePlan =
        user &&
        subscriptionType !== SubscriptionType.BASIC &&
        planTypesInOrder.indexOf(subscriptionType.toLowerCase()) >
            planTypesInOrder.indexOf(pricingOption.subscriptionType.toLowerCase());

    const getButtonText = () => {
        if (pricingOption.needContactUs) {
            return "Pick a time with CEO";
        } else if (pricingOption.isPicker) {
            return "Pick this plan";
        } else if (isCurrentPlan) {
            return isSubscriptionCancelling ? "Resubscribe" : isTrialing ? "Already trialing" : "Already on plan";
        } else if (isABetterPlan) {
            return "Upgrade";
        } else if (isAWorsePlan) {
            return "Downgrade";
        } else {
            return "Select plan";
        }
    };

    const isTrialAllowed = isFreeTrialAllowed(user?.stripeTrialTime);

    return {
        isCurrentPlan,
        isABetterPlan,
        isAWorsePlan,
        isSubscriptionCancelling,
        isTrialing,
        isTrialAllowed,
        buttonText: getButtonText(),
    };
};

export const canShareFiles = (serverUser: Pick<LocalUser, "organization" | "user">) => {
    const { organization, user } = serverUser;

    if (!user) {
        return false;
    }

    if (isUnderAge(user.birthday, 13)) {
        return false;
    }

    if (!organization) {
        return true;
    }

    if (user.accountType === AccountType.Teacher) {
        return true;
    }

    if (
        organization.stage === OrganizationStage.STUDENT_INVITED ||
        organization.stage === OrganizationStage.EMAIL_SENT ||
        organization.stage === OrganizationStage.DPA_PENDING
    ) {
        return false;
    }

    const settings = getOrganizationalSettings({ user, organization });
    if (isEnforcedDPA(serverUser) && settings?.sharing === OrganizationSharing.PRIVATE) {
        return false;
    }

    return true;
};

export const canSubscribe = (serverUser: Pick<LocalUser, "organization" | "user">) => {
    const { organization, user } = serverUser;

    if (!user) {
        return false;
    }

    if (isUnderAge(user.birthday, 13)) {
        return false;
    }

    if (!organization) {
        return true;
    }

    if (user.accountType === AccountType.Teacher) {
        return true;
    }

    if (
        organization.stage === OrganizationStage.STUDENT_INVITED ||
        organization.stage === OrganizationStage.EMAIL_SENT ||
        organization.stage === OrganizationStage.DPA_PENDING
    ) {
        return false;
    }

    const settings = getOrganizationalSettings({ user, organization });
    if (isEnforcedDPA(serverUser) && settings?.subsAllowed === false) {
        return false;
    }

    return true;
};

export const canUseAI = (serverUser: Pick<LocalUser, "organization" | "user">) => {
    const { organization, user } = serverUser;

    if (!user) {
        return false;
    }

    if (!organization) {
        return true;
    }

    if (isUnderAge(user.birthday, 13)) {
        return false;
    }

    if (user.accountType === AccountType.Teacher) {
        return true;
    }

    if (!organization.dpa || organization?.dpa?.type === DPAType.NONE) {
        return true;
    }

    const settings = getOrganizationalSettings({ user, organization });

    if (settings?.ai === false) {
        return false;
    }

    return true;
};

export const canShowAds = (serverUser: Pick<LocalUser, "organization" | "user">) => {
    const { organization, user } = serverUser;

    const subscriptionType = getUserPlan(user);

    const isPaused = user?.subscriptions?.[0]?.status === StripeStatus.PAUSED;
    const isAdmin = organization?.admins?.some(admin => admin.userId === user.ID);

    if (!user) {
        return false;
    }

    if (isAdmin) {
        return false;
    }

    if (isUnderAge(user.birthday, 13)) {
        return false;
    }

    if (subscriptionType !== SubscriptionType.BASIC && !isPaused) {
        return false;
    }

    if (now() - Number(user.lastAdWatch || "0") < 30 * TIME_SECONDS.MINUTE) {
        return false;
    }

    if (Number(user?.inventory?.gamifySubExpiry || "0") > now()) {
        return false;
    }

    if (!organization) {
        return true;
    }

    if (organization.dpa) {
        if (organization.dpa.type === DPAType.NONE) {
            return true;
        }

        return false;
    }

    if (user.accountType === AccountType.Teacher) {
        return true;
    }

    if (
        organization.stage === OrganizationStage.STUDENT_INVITED ||
        organization.stage === OrganizationStage.EMAIL_SENT ||
        organization.stage === OrganizationStage.DPA_PENDING
    ) {
        return false;
    }

    return true;
};

export const isEnforcedDPA = (serverUser: Pick<LocalUser, "organization" | "user">) => {
    const { organization, user } = serverUser;
    if (!organization || !user) return false;

    if (isUnderAge(user?.birthday, 13)) {
        return true;
    }

    if (user.accountType === AccountType.Teacher) {
        return false;
    }

    if (
        organization?.stage === OrganizationStage.STUDENT_INVITED ||
        organization?.stage === OrganizationStage.EMAIL_SENT ||
        organization?.stage === OrganizationStage.DPA_PENDING
    ) {
        return true;
    }

    if (organization.dpa && organization.dpa.type !== DPAType.NONE) {
        return true;
    }

    return false;
};

export const calculateUsageResetDate = (user: UserDetails | null | undefined) => {
    if (!user) return dayjs();

    let monthlyUsageRestartDate = user?.ai?.curPeriodEnd
        ? dayjs(Number(user?.ai?.curPeriodEnd) * 1000)
        : dayjs(Number(user?.created) * 1000).add(1, "month");

    // if in the past, set to today
    monthlyUsageRestartDate = monthlyUsageRestartDate.isBefore(dayjs()) ? dayjs() : monthlyUsageRestartDate;

    return monthlyUsageRestartDate;
};
