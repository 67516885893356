/********
DO NOT MODIFY THIS FILE MANUALLY.
This file is generated through the codegen located at scripts/syncBadges.ts. To create a new API, please run that codegen. 
********/

import { BadgeMetadata, BadgeEnum, BadgeGroupEnum } from "./graphql/schema";
export const badgeMetadata: BadgeMetadata[] = [
    {
        __typename: "BadgeMetadata",
        name: "The Popular Kid",
        description: "{required} Four/Five Star Rating(s) Received",
        success: "Congrats! You unlocked Badge {tier} by receiving {required} Four/Five Star Ratings! Keep it up 😜",
        failure: "Unlock Badge {tier} by receiving {required} Four/Five Star ratings. ",
        group: BadgeGroupEnum.SOCIAL,
        badge: BadgeEnum.HIGH_RATINGS_RECEIVED,
        tiers: [
            { coinReward: 25, required: 1, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 75, required: 5, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 125, required: 25, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 250, required: 50, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 400, required: 100, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Academic Weapon",
        description: "{required} Class(es) Joined",
        success: "Congrats! You unlocked Badge {tier} for joining {required} classes! Keep it up 🎉",
        failure: "Unlock Badge {tier} by joining {required} classes.",
        group: BadgeGroupEnum.CLASS,
        badge: BadgeEnum.JOINED_CLASSES,
        tiers: [
            { coinReward: 50, required: 2, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 75, required: 3, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 100, required: 4, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 150, required: 5, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 300, required: 6, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Professional Yapper",
        description: "{required} Rating(s) Given",
        success: "Congrats! You unlocked Badge {tier} for rating {required} files. Keep yapping! 🥳",
        failure: "Unlock Badge {tier} by rating {required} files.",
        group: BadgeGroupEnum.SOCIAL,
        badge: BadgeEnum.RATINGS_GIVEN,
        tiers: [
            { coinReward: 5, required: 5, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 25, required: 50, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 50, required: 100, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 100, required: 300, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 200, required: 500, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Anti Gate-Keeper",
        description: "{required} Note(s) Made Public",
        success: "Congrats! You unlocked Badge {tier} for making {required} notes public! Keep it up 🤩",
        failure: "Unlock Badge {tier} by making {required} notes public.",
        group: BadgeGroupEnum.SHARING,
        badge: BadgeEnum.PUBLIC_NOTES,
        tiers: [
            { coinReward: 5, required: 5, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 25, required: 15, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 75, required: 60, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 125, required: 120, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 200, required: 300, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Fortune Teller",
        description: "{required} Explain Wrong Answer Use(s)",
        success: "Congrats! You unlocked Badge {tier} for using Explain Why I’m Wrong {required} times! 😝",
        failure: "Unlock Badge {tier} by using Explain Why I'm Wrong {required} times.",
        group: BadgeGroupEnum.LEARNING,
        badge: BadgeEnum.EXPLAIN_WRONG_ANSWER,
        tiers: [
            { coinReward: 5, required: 20, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 30, required: 60, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 75, required: 150, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 100, required: 300, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 150, required: 600, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Brainiac",
        description: "{required} Learn Mode Round(s)",
        success: "Congrats! You unlocked Badge {tier} for completing {required} rounds of Learn Mode! 😍",
        failure: "Unlock Badge {tier} by completing {required} rounds of Learn Mode.",
        group: BadgeGroupEnum.LEARNING,
        badge: BadgeEnum.LEARN_MODE_ROUNDS,
        tiers: [
            { coinReward: 5, required: 5, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 35, required: 50, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 75, required: 100, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 100, required: 300, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 300, required: 600, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Fire Breather",
        description: "{required} Day Streak Reached",
        success: "Woohoo! You unlocked Badge {tier} for hitting your {required} day streak!🔥",
        failure: "Unlock Badge {tier} by hitting a {required} day streak.",
        group: BadgeGroupEnum.MILESTONES,
        badge: BadgeEnum.STREAK_REACHED,
        tiers: [
            { coinReward: 0, required: 5, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 7, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 14, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 30, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 60, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 90, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 120, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 150, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 180, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 210, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 240, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 270, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 300, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 330, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 365, xpReward: 0, __typename: "BadgeTier" },
            { coinReward: 0, required: 420, xpReward: 0, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Know(t) it all",
        description: "Your Material Was Viewed {required} Times",
        success: "Woah, {required} people have viewed your material! You just unlocked Badge {tier} 🤓",
        failure: "Unlock Badge {tier} when {required} people view your material.",
        group: BadgeGroupEnum.SHARING,
        badge: BadgeEnum.ITEM_VIEWS,
        tiers: [
            { coinReward: 5, required: 1, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 25, required: 50, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 50, required: 200, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 100, required: 500, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 250, required: 1000, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "Merlin's Apprentice",
        description: "{required} Brainstorm Assistant Use(s)",
        success: "Nice! You unlocked Badge {tier} by using Brainstorm Assistant {required} times. Keep it up! 😎",
        failure: "Unlock Badge {tier} by using Brainstorm Assistant {required} times.",
        group: BadgeGroupEnum.CREATOR,
        badge: BadgeEnum.BRAINSTORM_ASSISTANT,
        tiers: [
            { coinReward: 5, required: 15, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 30, required: 50, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 75, required: 150, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 100, required: 300, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 150, required: 600, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Perfectionist",
        description: "{required} Practice Test(s)",
        success: "Congrats! You unlocked Badge {tier} for taking {required} practice tests. Keep practicing! 🎊",
        failure: "Unlock Badge {tier} by taking  {required} practice tests.",
        group: BadgeGroupEnum.LEARNING,
        badge: BadgeEnum.PRACTICE_TESTS,
        tiers: [
            { coinReward: 5, required: 5, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 50, required: 50, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 100, required: 100, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 150, required: 300, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 350, required: 600, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "Pyramid Schemer",
        description: "{required} Referrals Reached",
        success: "Woah! You unlocked Badge {tier} for hitting {required} successful referrals. Keep scheming 😈",
        failure: "Unlock Badge {tier} by completing {required} sucessful referrals.",
        group: BadgeGroupEnum.SOCIAL,
        badge: BadgeEnum.USERS_REFERRED,
        tiers: [
            { coinReward: 100, required: 5, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 250, required: 10, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 500, required: 25, xpReward: 150, __typename: "BadgeTier" },
            { coinReward: 725, required: 50, xpReward: 300, __typename: "BadgeTier" },
            { coinReward: 1000, required: 100, xpReward: 500, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Scroll Keeper",
        description: "{required} PDF(s) Uploaded",
        success: "Congrats! You unlocked Badge {tier} by uploading {required} PDFs. Keep it up! 😜",
        failure: "Unlock Badge {tier} by uploading {required} PDFs & PPTs.",
        group: BadgeGroupEnum.CREATOR,
        badge: BadgeEnum.PDF_UPLOAD,
        tiers: [
            { coinReward: 5, required: 2, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 30, required: 10, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 75, required: 25, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 100, required: 100, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 150, required: 200, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Recruiter",
        description: "{required} Student(s) Invited",
        success: "You unlocked Badge {tier} for inviting {required} students! Keep growing your class! 🥳",
        failure: "Unlock Badge {tier} by inviting {required} students to your class.",
        group: BadgeGroupEnum.CLASS,
        badge: BadgeEnum.INVITED_STUDENTS,
        tiers: [
            { coinReward: 20, required: 20, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 75, required: 50, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 150, required: 75, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 300, required: 100, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 400, required: 150, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Sourcerer",
        description: "{required} Flashcards from Note(s)",
        success: "You unlocked Badge {tier} by using Kai make flashcards from your notes with AI {required} times! 🥳",
        failure: "Unlock Badge {tier} by using Kai to make flashcards from your notes with AI {required} times.",
        group: BadgeGroupEnum.LEARNING,
        badge: BadgeEnum.FLASHCARDS_FROM_NOTE,
        tiers: [
            { coinReward: 5, required: 3, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 25, required: 10, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 50, required: 50, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 100, required: 100, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 200, required: 200, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Shapeshifter",
        description: "{required} Taking Test(s) From Notes",
        success:
            "You unlocked Badge {tier} for making tests from your notes with AI {required} times. Keep practicing! 🥳",
        failure: "Unlock Badge {tier} by making tests from your notes with AI {required} times.",
        group: BadgeGroupEnum.LEARNING,
        badge: BadgeEnum.TESTS_FROM_NOTE,
        tiers: [
            { coinReward: 5, required: 3, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 50, required: 10, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 100, required: 50, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 150, required: 100, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 350, required: 200, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "Memory Maverick",
        description: "{required} Spaced Repetition Round(s) ",
        success: "Yay! You unlocked Badge {tier} for completing {required} of Spaced Repetition. Keep it going! 🥳",
        failure: "Unlock Badge {tier} by completing {required} rounds of Spaced Repetition.",
        group: BadgeGroupEnum.LEARNING,
        badge: BadgeEnum.SPACED_REPETITION_ROUNDS,
        tiers: [
            { coinReward: 5, required: 5, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 50, required: 25, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 150, required: 50, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 275, required: 150, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 400, required: 300, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Mastermind",
        description: "{required} Mastery of Set(s)",
        success: "You unlocked Badge {tier} by mastering {required} sets. You’re killing it! 🥳",
        failure: "Unlock Badge {tier} by mastering {required} flashcard sets",
        group: BadgeGroupEnum.LEARNING,
        badge: BadgeEnum.MASTERY_OF_SET,
        tiers: [
            { coinReward: 25, required: 1, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 75, required: 10, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 125, required: 50, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 250, required: 150, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 500, required: 300, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Gravedigger",
        description: "{required} Quizlet(s) Imported",
        success: "You unlocked Badge {tier} by importing {required} Quizlet Sets. Keep it up!😈",
        failure: "Unlock Badge {tier} by importing {required} Quizlet sets into Knowt.",
        group: BadgeGroupEnum.CREATOR,
        badge: BadgeEnum.QUIZLET_IMPORT,
        tiers: [
            { coinReward: 5, required: 3, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 30, required: 10, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 75, required: 25, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 100, required: 50, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 150, required: 75, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Torchbearer",
        description: "{required} Class(es) Created",
        success: "Congrats! You unlocked Badge {tier} for creating {required} classes. Keep it up! 😜",
        failure: "Unlock Badge {tier} by creating {required} classes.",
        group: BadgeGroupEnum.CLASS,
        badge: BadgeEnum.CLASSES_CREATED,
        tiers: [
            { coinReward: 100, required: 2, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 200, required: 3, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 300, required: 4, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 500, required: 5, xpReward: 100, __typename: "BadgeTier" },
            { coinReward: 750, required: 10, xpReward: 150, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Film Star",
        description: "{required} Video(s) Uploaded",
        success: "You unlocked Badge {tier} by uploading {required} videos. Keep it up! 🥳",
        failure: "Unlock Badge {tier} by uploading {required} videos.",
        group: BadgeGroupEnum.CREATOR,
        badge: BadgeEnum.VIDEO_UPLOAD,
        tiers: [
            { coinReward: 5, required: 2, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 30, required: 10, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 75, required: 25, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 100, required: 100, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 150, required: 200, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Quill Master",
        description: "{required} Note(s) Created",
        success: "Congrats! You unlocked Badge {tier} by creating {required} notes. Keep writing away! 🥳",
        failure: "Unlock Badge {tier} by creating {required} notes.",
        group: BadgeGroupEnum.CREATOR,
        badge: BadgeEnum.NOTE_CREATION,
        tiers: [
            { coinReward: 5, required: 3, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 50, required: 10, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 100, required: 50, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 150, required: 100, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 400, required: 250, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "The Matchmaker",
        description: "{required} Match Mode(s)",
        success: "You unlocked Badge {tier} by completing {required} rounds of match mode. Keep it up! 😜",
        failure: "Unlock Badge {tier} by completing {required} rounds of match mode.",
        group: BadgeGroupEnum.LEARNING,
        badge: BadgeEnum.MATCH_MODE,
        tiers: [
            { coinReward: 5, required: 5, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 25, required: 50, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 50, required: 100, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 100, required: 300, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 200, required: 600, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "Social Butterfly",
        description: "{required} Followers Reached",
        success: "Congrats, you’re famous! You unlocked Badge {tier} by reaching {required} follower(s)",
        failure: "Unlock Badge {tier} by reaching {required} followers. ",
        group: BadgeGroupEnum.SOCIAL,
        badge: BadgeEnum.FOLLOWERS_REACHED,
        tiers: [
            { coinReward: 25, required: 1, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 75, required: 10, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 125, required: 50, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 250, required: 150, xpReward: 125, __typename: "BadgeTier" },
            { coinReward: 500, required: 300, xpReward: 250, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "Magic Mirror",
        description: "{required} Chat with AI Use(s)",
        success: "You unlocked Badge {tier} by chatting with Kai {required} times. Keep chatting! 🥳",
        failure: "Unlock Badge {tier} by chatting with Kai {required} times.",
        group: BadgeGroupEnum.LEARNING,
        badge: BadgeEnum.CHAT_WITH_AI,
        tiers: [
            { coinReward: 5, required: 15, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 30, required: 50, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 75, required: 150, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 100, required: 300, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 150, required: 600, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "Decked Out",
        description: "{required} Flashcard Set(s) Created",
        success: "Congrats! You unlocked Badge {tier} by creating {required} sets. You’re killing it! 🥳",
        failure: "Unlock Badge {tier} by creating {required} flashcard sets. ",
        group: BadgeGroupEnum.CREATOR,
        badge: BadgeEnum.FLASHCARDSET_CREATION,
        tiers: [
            { coinReward: 5, required: 3, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 50, required: 10, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 100, required: 50, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 150, required: 100, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 400, required: 250, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
    {
        __typename: "BadgeMetadata",
        name: "Certified Stalker",
        description: "{required} Following reached",
        success:
            "You unlocked Badge {tier} by following {required} people. Keep going, maybe they’ll follow you back! 😜",
        failure: "Unlock Badge {tier} by following {required} people.",
        group: BadgeGroupEnum.SOCIAL,
        badge: BadgeEnum.FOLLOWING_REACHED,
        tiers: [
            { coinReward: 10, required: 10, xpReward: 15, __typename: "BadgeTier" },
            { coinReward: 50, required: 50, xpReward: 25, __typename: "BadgeTier" },
            { coinReward: 100, required: 200, xpReward: 50, __typename: "BadgeTier" },
            { coinReward: 175, required: 350, xpReward: 75, __typename: "BadgeTier" },
            { coinReward: 300, required: 600, xpReward: 100, __typename: "BadgeTier" },
        ],
    },
];
