"use client";

import { useSWRImmutable } from "@/hooks/swr/immutable";
import { callListBadgeProgress, callListBadgesMetadata } from "@/hooks/gamification/badges/graphqlUtils";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";

export const resolveBadgeProgressSWRKey = (userId: string) => [userId, "listBadgeProgress"] as const;

export const useBadgeProgress = (
    { userId: _userId }: { userId: string | null | undefined } = { userId: undefined }
) => {
    const { userId: currentUserId } = useCurrentUser();

    const userId = _userId || currentUserId;

    const { data: badgeProgresses, mutate } = useSWRImmutable(userId && resolveBadgeProgressSWRKey(userId), () =>
        callListBadgeProgress({ userId })
    );

    return { badgeProgresses, mutate };
};

export const useBadgesMetadata = () => {
    const { data: badgesMetadata, mutate } = useSWRImmutable("listBadgesMetadata", () => callListBadgesMetadata());
    return { badgesMetadata, mutate };
};
