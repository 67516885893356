import { unstable_serialize, useSWRConfig } from "swr";
import useSWRImmutableOrig from "swr/immutable";
import { useEffect } from "react";
import { withMiddleware } from "@/hooks/swr/utils";

export const populateCacheWithFallbackData = (useSWRNext) => {
    const useInitialData = (key, fetcher, config) => {
        const globalConfig = useSWRConfig();

        const result = useSWRNext(key, fetcher, config);
        const { mutate } = result;

        const { data: populatedKeys, mutate: mutatePopulatedKeys } = useSWRImmutableOrig("populatedKeys", () => ({}));

        useEffect(() => {
            const asyncEffect = async () => {
                const wasAlreadyPopulated = populatedKeys?.[key];
                if (wasAlreadyPopulated) return;

                const fallbackData = config.fallbackData || globalConfig.fallback[unstable_serialize(key)];
                if (!fallbackData) return;

                const currentCache = await mutate((current) => current, { revalidate: false });
                if (currentCache) return;

                mutate(fallbackData, { revalidate: false });
                mutatePopulatedKeys((current) => ({ ...current, [key]: true }), { revalidate: false });
            };

            asyncEffect();
        }, [mutate, config.fallbackData, key, globalConfig.fallback, populatedKeys, mutatePopulatedKeys]);

        return result;
    };

    return useInitialData;
};

/**
 * Context: swr hooks (either useSWR or useSWRImmutable) does not populate the cache with
 * `fallbackData` by default; instead, the cache is only populated when a fetch is made.
 * However, if using `useSWRImmutable` with provided `fallbackData`, a fetch will never be made,
 * hence, the cache will remain empty -- this hook solves that problem by making sure the cache
 * is populated when suitable, using the `populateCacheWithFallbackData` middleware.
 */
export const useSWRImmutable = withMiddleware(useSWRImmutableOrig, populateCacheWithFallbackData);
