"use client";

import { fetchPostJSON } from "@/fetchFunctions/fetchWrappers";
import { didReachAIUsage } from "@/hooks/ai/utils";
import { useSWRImmutable } from "@/hooks/swr/immutable";
import { fetchCurrentUserInfo } from "@/hooks/user/auth";
import { callVerifyStripeCheckout, getOrganizationalSettings, getUserPlan } from "@/hooks/user/graphqlUtils";
import { platform } from "@/platform";
import { AnyFunction } from "@/types/common";
import { COUNTRY_CODES } from "@/utils/countries";
import { wait } from "@/utils/genericUtils";
import {
    AccountType,
    Organization,
    OrganizationSettings,
    SignInType,
    StoreProductEnum,
    StripeConnection,
    SubscriptionType,
    UpgradeEvent,
    UserAIStats,
    UserDetails,
} from "@knowt/syncing/graphql/schema";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { KeyedMutator } from "swr";
import { StableSWRKeys } from "../swr/swr";
import { FEATURES_BY_SUBSCRIPTION_TYPE, featureTypeToStoreProductEnum } from "./subscriptionConstants";
import { canShareFiles, canShowAds, canSubscribe, canUseAI, isEnforcedDPA } from "./subscriptions";
import { LocalUser } from "./types";
import { isUnderAge } from "./utils";

type CurrentUser = {
    loginInProgress: boolean;
    loggedOut: boolean;
    user?: UserDetails;
    tz: string | undefined;
    organization?: Organization;
    organizationSettings: OrganizationSettings | null;
    sharingEnabled: boolean;
    international: boolean;
    isEnforcedDPA: boolean;
    isAIEnabled: boolean;
    areSubsAllowed: boolean;
    isUnder13: boolean;
    isAdmin: boolean;
    isDistrictAdmin: boolean;
    hasReachedAIUsage: (type: keyof UserAIStats) => boolean;
    serverSyncTime?: number;
    signInType?: SignInType;
    userId?: string;
    viewerId?: string;
    isTeacher: boolean;
    isStudent: boolean;
    userName: string;
    isSocialSignIn: boolean;
    activeSubscription?: StripeConnection;
    isSubscriptionActive: boolean;
    canShowAds: boolean;
    subscriptionType: SubscriptionType;
    hasFeatureAccess: (event: UpgradeEvent) => boolean;
    isSubscriptionCancelling?: boolean;
    mutate: KeyedMutator<LocalUser>;
    resubscribe: () => Promise<void>;
    unpause: () => Promise<void>;
    isLoading: boolean;
};

let isDataFetched = false;

export const useCurrentUser = ({
    fallbackData,
    from,
    waitForFallbackData = false,
}: {
    fallbackData?: LocalUser;
    from?: string;
    waitForFallbackData?: boolean;
} = {}): CurrentUser => {
    const isInitialRender = useRef(true);

    const { data, error, mutate, isLoading } = useSWRImmutable(
        StableSWRKeys.USER,
        () => {
            if (waitForFallbackData && isInitialRender.current) {
                isInitialRender.current = false;
                return undefined;
            }
            // biome-ignore lint: noConsole
            if (from) console.log(`useCurrentUser: ${from}`);
            isDataFetched = true;
            return fetchCurrentUserInfo();
        },
        { fallbackData: fallbackData ?? undefined }
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        //biome-ignore lint:
        setTimeout(() => (isInitialRender.current = false), 500);

        if (!isDataFetched && !waitForFallbackData && data === undefined) {
            isDataFetched = true;
            mutate();
        }
    }, []);

    const { data: viewerId } = useSWRImmutable(
        (data || error) && ["viewerId", data?.user?.ID],
        async () => data?.user?.ID ?? (await platform.anonymousViewerUid().then(({ get }) => get()))
    );

    const user = data?.user as UserDetails;
    const organization = data?.organization as Organization;

    const activeSubscription = useMemo(() => {
        // Active Subscription will be the first one in this list.
        return user?.subscriptions?.[0] ? ({ ...user?.subscriptions?.[0] } as StripeConnection) : undefined;
    }, [user]);

    const adminRole = organization?.admins?.find(admin => admin.userId === user?.ID);

    const resubscribe = useCallback(async () => {
        const mixpanel = await platform.analytics.mixpanel();

        mixpanel.track("Resubscribe Button - Clicked", {
            subscriptionId: activeSubscription?.subscriptionId,
            priceId: activeSubscription?.priceId,
        });

        await fetchPostJSON("/api/subscription/update", {
            subscriptionId: activeSubscription?.subscriptionId,
            priceId: activeSubscription?.priceId,
        });
        await wait(1000);
        await callVerifyStripeCheckout(user.customerId);
        mutate();
    }, [activeSubscription, user, mutate]);

    const unpause = useCallback(async () => {
        const mixpanel = await platform.analytics.mixpanel();

        mixpanel.track("Resubscribe Button - Clicked", {
            subscriptionId: activeSubscription?.subscriptionId,
            priceId: activeSubscription?.priceId,
        });

        await fetchPostJSON("/api/subscription/unpause", {
            subscriptionId: activeSubscription?.subscriptionId,
        });
        await wait(1000);
        await callVerifyStripeCheckout(user.customerId);
        mutate();
    }, [activeSubscription, user, mutate]);

    const hasReachedAIUsage = useCallback((type: keyof UserAIStats) => didReachAIUsage(user, type), [user]);

    const subscriptionType = getUserPlan(user);
    const organizationSettings = getOrganizationalSettings({ user, organization });
    const isEnforcedDPAVal = isEnforcedDPA({ organization, user });

    return {
        mutate,
        loginInProgress: !data && !error,
        user,
        tz: user?.timeZone ?? undefined,
        organization,
        organizationSettings,
        sharingEnabled: canShareFiles({ user, organization }),
        international: COUNTRY_CODES.includes(user?.schoolId),
        isEnforcedDPA: isEnforcedDPAVal,
        areSubsAllowed: canSubscribe({
            user,
            organization,
        }),
        isAIEnabled: canUseAI({ user, organization }),
        isUnder13: isUnderAge(user?.birthday, 13),
        isAdmin: !!adminRole,
        isDistrictAdmin: adminRole?.schoolId === "ALL",
        hasReachedAIUsage,
        serverSyncTime: data?.serverSyncTime,
        userId: user?.ID ?? undefined,
        viewerId: user?.ID || viewerId,
        userName: user?.Name ?? "",
        isTeacher: user?.accountType === AccountType.Teacher,
        isStudent: user?.accountType === AccountType.Student,
        loggedOut: !data || error?.status === 403,
        signInType: user?.signInType,
        isSocialSignIn: user?.signInType && user?.signInType !== SignInType.EMAIL,
        activeSubscription,
        subscriptionType: subscriptionType,
        hasFeatureAccess: (event: UpgradeEvent) =>
            hasFeatureAccess({ event, features: user?.inventory?.features ?? [], subscriptionType }),
        isSubscriptionActive: subscriptionType !== SubscriptionType.BASIC,
        canShowAds: canShowAds({
            user,
            organization,
        }),
        isSubscriptionCancelling: !!activeSubscription?.cancel_at_period_end,
        resubscribe,
        unpause,
        isLoading,
    };
};

const hasFeatureAccess = ({
    event,
    features,
    subscriptionType,
}: {
    event: UpgradeEvent;
    features: StoreProductEnum[];
    subscriptionType: SubscriptionType;
}) => {
    const accessibleFeatures = FEATURES_BY_SUBSCRIPTION_TYPE[subscriptionType];

    if (accessibleFeatures.includes(event)) {
        return true;
    }

    if (features.includes(featureTypeToStoreProductEnum(event))) {
        return true;
    }

    return false;
};

export const useIfUserSessionExpires = (cb: AnyFunction) => {
    const { user, userId, loginInProgress } = useCurrentUser();
    const cbRef = useRef(cb);
    const userRef = useRef<UserDetails | null>(null);

    useEffect(() => {
        cbRef.current = cb;
    });

    useEffect(() => {
        if (loginInProgress) return;
        if (user) {
            userRef.current = user;
        }
        if (userRef.current?.ID && !userId) {
            cbRef.current(userRef.current?.Email);
        }
    }, [userId, user, loginInProgress]);
};
