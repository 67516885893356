import { TIME, todayDate } from "../../utils/dateTimeUtils";
import { numberWithCommas } from "../../utils/stringUtils";
import {
    ActionEnum,
    BadgeEnum,
    BadgeProgress,
    DailyActivity,
    StoreProductEnum,
    StoreProductHistory,
    UserDetails,
} from "@knowt/syncing/graphql/schema";

export enum ExtraActionEnum {
    NONE = "NONE",
    STREAK = "STREAK",
    LEVELS = "LEVELS",
    COINS = "COINS",
}

export const STREAK_ACTION_COLORS = {
    [ExtraActionEnum.STREAK]: "#ff7c1d",
    [ExtraActionEnum.LEVELS]: "#976EEE",
    [ExtraActionEnum.COINS]: "#FE9923",
    [ActionEnum.STREAK_FREEZE]: "#0BC0FA",
    [ActionEnum.STREAK_REVIVAL]: "#FF6C77",
    [ActionEnum.LONG_PAUSE]: "#4A4A4A",
};

export const MAX_FEATURED_BADGES = 5;

export const KNOWT_GAMIFICATION_STORAGE = "https://s3.amazonaws.com/knowt-generic-storage/gamification";

export const ACTION_REWARD_MAP = {
    [ActionEnum.FINISH_LEARN_MODE_ROUND]: {
        xp: 10,
    },
    [ActionEnum.FULLY_MASTER_SET]: {
        xp: 100,
    },
    [ActionEnum.GIVE_RATING]: {
        xp: 5,
    },
    [ActionEnum.RECEIVE_HIGH_RATING]: {
        xp: 10,
    },
    [ActionEnum.BRAINSTORM_ASSISTANT]: {
        xp: 5,
    },
    [ActionEnum.FLASHCARD_FROM_NOTES]: {
        xp: 25,
    },
    [ActionEnum.TEST_FROM_NOTE]: {
        xp: 25,
    },
    [ActionEnum.NOTES_FROM_VIDEO]: {
        xp: 25,
    },
    [ActionEnum.FLASHCARD_FROM_VIDEO]: {
        xp: 25,
    },
    [ActionEnum.NOTES_FROM_PDF]: {
        xp: 25,
    },
    [ActionEnum.FLASHCARD_FROM_PDF]: {
        xp: 25,
    },
    [ActionEnum.CHAT_WITH_AI]: {
        xp: 5,
    },
    [ActionEnum.MAKE_NOTE_PUBLIC]: {
        xp: 10,
    },
    [ActionEnum.CREATE_NOTE]: {
        xp: 5,
    },
    [ActionEnum.CREATE_FLASHCARDSET]: {
        xp: 5,
    },
    [ActionEnum.REFER_USER]: {
        xp: 0,
    },
    [ActionEnum.REFERRED_BY_USER]: {
        xp: 10,
    },
    [ActionEnum.CREATE_CLASS]: {
        xp: 25,
    },
    [ActionEnum.JOIN_CLASS]: {
        xp: 10,
    },
    [ActionEnum.INVITE_STUDENT]: {
        xp: 10,
    },
    [ActionEnum.FILL_DPA_FORM]: {
        xp: 100,
    },
    [ActionEnum.ADDED_TAGS]: {
        xp: 5,
    },
};

export const ACTION_REWARD_MULTIPLIER_MAP = {
    [ActionEnum.FINISH_LEARN_MODE_ROUND]: {
        RIGHT_ANSWER: 2,
        WRONG_ANSWER: 0,
    },
    [ActionEnum.FULLY_MASTER_SET]: {
        MASTERED_FLASHCARD: 2,
    },
};

export const MAX_DAYS_TO_REVIVE_STREAK = 14;

export const DEFAULT_STORE_PRODUCT_HISTORY = (
    claimsUserId: string,
    storeProductId: StoreProductEnum
): Omit<StoreProductHistory, "__typename"> => ({
    userId: claimsUserId,
    storeProductId,
    current: 0,
    bought: 0,
    price: 0,
});

export const DEFAULT_BADGE_PROGRESS = (userId: string, badge: BadgeEnum): Omit<BadgeProgress, "__typename"> => ({
    userId,
    badge,
    current: 0,
    claimed: [],
    unclaimed: [],
    earnedAt: [],
});

export const DEFAULT_DAILY_ACTIVITY = (userId: string, activityDate: string): Omit<DailyActivity, "__typename"> => ({
    userId,
    activityDate,
    xp: 0,
    items: [],
    coinsEarned: 0,
    badgesEarned: 0,
    asyncActions: [],
    actionMap: "{}",
});

export const NON_STREAK_ACTIONS = [
    ActionEnum.MAKE_NOTE_PUBLIC,
    ActionEnum.RECEIVE_HIGH_RATING,
    ActionEnum.STREAK_MILESTONE,
    ActionEnum.TIER_1_QUEST,
    ActionEnum.TIER_2_QUEST,
    ActionEnum.TIER_3_QUEST,
    ActionEnum.TIER_4_QUEST,
    ActionEnum.TIER_5_QUEST,
    ActionEnum.SEND_ADMIN_EMAIL,
    ActionEnum.CANCEL_LONG_PAUSE,
    ActionEnum.CANCEL_STREAK,
    ActionEnum.REFERRED_BY_USER,
    ActionEnum.REFER_USER,
    ActionEnum.FOLLOWED_BY_USER,
    ActionEnum.VIEW_ITEM,
    ActionEnum.NO_ACTION,
    ActionEnum.EXPLAIN_WRONG_ANSWER,
    ActionEnum.FLASHCARD_FROM_VIDEO,
    ActionEnum.WATCH_AD,
    ActionEnum.LONG_PAUSE,
    ActionEnum.STREAK_REVIVAL,
    ActionEnum.ADDED_TAGS,
    ActionEnum.VIEW_PLANS,
];

export const levels = [
    50, // Level 1
    110, // Level 2
    180, // Level 3
    260, // Level 4
    350, // Level 5
    460, // Level 6
    590, // Level 7
    740, // Level 8
    920, // Level 9
    1_130, // Level 10
    1_360, // Level 11
    1_610, // Level 12
    1_880, // Level 13
    2_170, // Level 14
    2_480, // Level 15
    2_820, // Level 16
    3_190, // Level 17
    3_590, // Level 18
    4_020, // Level 19
    4_490, // Level 20
    5_000, // Level 21
    5_550, // Level 22
    6_150, // Level 23
    6_800, // Level 24
    7_500, // Level 25
    8_260, // Level 26
    9_080, // Level 27
    9_970, // Level 28
    10_940, // Level 29
    11_990, // Level 30
    13_060, // Level 31
    14_150, // Level 32
    15_260, // Level 33
    16_390, // Level 34
    17_540, // Level 35
    18_710, // Level 36
    19_900, // Level 37
    21_110, // Level 38
    22_340, // Level 39
    23_590, // Level 40
    24_860, // Level 41
    26_150, // Level 42
    27_460, // Level 43
    28_800, // Level 44
    30_170, // Level 45
    31_570, // Level 46
    33_000, // Level 47
    34_460, // Level 48
    35_950, // Level 49
    37_470, // Level 50
    39_020, // Level 51
    40_600, // Level 52
    42_210, // Level 53
    43_850, // Level 54
    45_520, // Level 55
    47_220, // Level 56
    48_950, // Level 57
    50_710, // Level 58
    52_500, // Level 59
    54_320, // Level 60
    56_180, // Level 61
    58_080, // Level 62
    60_020, // Level 63
    62_000, // Level 64
    64_020, // Level 65
    66_080, // Level 66
    68_180, // Level 67
    70_320, // Level 68
    72_500, // Level 69
    74_720, // Level 70
    76_980, // Level 71
    79_280, // Level 72
    81_620, // Level 73
    84_010, // Level 74
    86_450, // Level 75
    88_940, // Level 76
    91_480, // Level 77
    94_070, // Level 78
    96_710, // Level 79
    99_400, // Level 80
    102_140, // Level 81
    104_930, // Level 82
    107_770, // Level 83
    110_660, // Level 84
    113_610, // Level 85
    116_620, // Level 86
    119_690, // Level 87
    122_820, // Level 88
    126_010, // Level 89
    129_260, // Level 90
    132_570, // Level 91
    135_940, // Level 92
    139_370, // Level 93
    142_870, // Level 94
    146_440, // Level 95
    150_080, // Level 96
    153_790, // Level 97
    157_570, // Level 98
    161_420, // Level 99
    165_340, // Level 100
    169_340, // Level 101
    173_420, // Level 102
    177_580, // Level 103
    181_820, // Level 104
    186_140, // Level 105
    190_540, // Level 106
    195_020, // Level 107
    199_590, // Level 108
    204_250, // Level 109
    209_000, // Level 110
    213_840, // Level 111
    218_770, // Level 112
    223_790, // Level 113
    228_910, // Level 114
    234_130, // Level 115
    239_450, // Level 116
    244_870, // Level 117
    250_390, // Level 118
    256_020, // Level 119
    261_760, // Level 120
    267_610, // Level 121
    273_570, // Level 122
    279_640, // Level 123
    285_830, // Level 124
    292_140, // Level 125
    298_570, // Level 126
    305_120, // Level 127
    311_800, // Level 128
    318_610, // Level 129
    325_550, // Level 130
    332_620, // Level 131
    339_830, // Level 132
    347_180, // Level 133
    354_670, // Level 134
    362_300, // Level 135
    370_080, // Level 136
    378_010, // Level 137
    386_090, // Level 138
    394_330, // Level 139
    402_730, // Level 140
    411_290, // Level 141
    420_010, // Level 142
    428_900, // Level 143
    437_960, // Level 144
    447_190, // Level 145
    456_600, // Level 146
    466_190, // Level 147
    475_960, // Level 148
    485_920, // Level 149
    496_070, // Level 150
];

export const streakMilestones = {
    2: { coins: 10, xp: 25 },
    3: { coins: 15, xp: 50 },
    7: { coins: 25, xp: 100 },
    14: { coins: 50, xp: 250 },
    30: { coins: 100, xp: 370 },
    60: { coins: 150, xp: 600 },
    90: { coins: 200, xp: 820 },
    120: { coins: 250, xp: 1070 },
    150: { coins: 300, xp: 1150 },
    180: { coins: 350, xp: 1200 },
    210: { coins: 400, xp: 1300 },
    240: { coins: 500, xp: 1400 },
    270: { coins: 600, xp: 1500 },
    300: { coins: 700, xp: 1650 },
    330: { coins: 800, xp: 1800 },
    365: { coins: 1000, xp: 2000 },
};

const MAX_STREAK_REWARD = { xp: 2000, coins: 1000 };

export const getNextStreakMilestone = (streak: number) => {
    const milestones = Object.keys(streakMilestones).map(Number);
    const nextMilestone = milestones.find(milestone => milestone >= streak);
    if (nextMilestone) {
        return nextMilestone;
    }

    const numYears = Math.floor(streak / 365);
    const numMonthsInThisYear = (streak % 365) / 30;
    const milestoneDays = numMonthsInThisYear > 11 ? 365 : 30 * Math.ceil(numYears / 30);
    return 365 * numYears + milestoneDays;
};

export const getStreakMilestoneReward = (streakMilestone: number): { coins: number; xp: number } => {
    return streakMilestones[streakMilestone] || MAX_STREAK_REWARD;
};

export const levelMilestones = {
    0: 0,
    1: 10,
    6: 25,
    11: 50,
    26: 100,
    51: 150,
    101: 200,
};

const MAX_LEVEL_REWARD = 200;

export const getLevel = (xp: number) => {
    //starting at level 0
    let level = levels.findIndex(level => level > xp);
    if (level !== -1) {
        return level;
    }

    let currXp = Math.round((xp * 1 * 1.1) / 10) * 10;
    level = levels.length;

    while (currXp <= xp) {
        currXp += Math.round((currXp * 1 * 0.1) / 10) * 10;
        level++;
    }

    return level;
};

export const getLevelReward = (level: number) => {
    // find the greatest key that is less than or equal to the level
    const milestone = Object.keys(levelMilestones)
        .map(Number)
        .reverse()
        .find(milestone => milestone <= level);

    return levelMilestones[milestone] ?? MAX_LEVEL_REWARD;
};

export const getXPForLevel = (level: number) => {
    return numberWithCommas(String(levels[level]));
};

export const xpRequiredForLevel = (level: number) => {
    if (level <= levels.length) {
        return levels[level - 1] ?? 0;
    }

    let xp = levels[levels.length - 1];
    for (let i = levels.length; i < level; i++) {
        xp += Math.round((xp * 1 * 0.1) / 10) * 10;
    }

    return xp;
};

export const getDeltaXPForLevel = (level: number) => {
    if (level === 0) return levels[0];
    return xpRequiredForLevel(level + 1) - xpRequiredForLevel(level);
};

export const getAccumulatedXPForLevel = (level: number, xp: number) => {
    if (level === 0) return xp;
    return xp - xpRequiredForLevel(level);
};

export const ASYNC_ACTIONS = [
    ActionEnum.RECEIVE_HIGH_RATING,
    ActionEnum.NO_ACTION,
    ActionEnum.REFER_USER,
    ActionEnum.VIEW_ITEM,
    ActionEnum.INVITE_STUDENT,
    ActionEnum.FOLLOWED_BY_USER,
    ActionEnum.UPLOAD_VIDEO,
    ActionEnum.UPLOAD_PDF,
    ActionEnum.VIEW_PLANS,
];

export const MAX_XP_PER_DAY = 8_000;
export const MAX_COINS_PER_DAY = 3_000;

export const referralReward: Record<number, number> = {
    0: 100,
    6: 125,
    11: 150,
    26: 175,
    51: 200,
    75: 0,
};

const totalCollectedPerLevel = [0, 500, 1125, 3375, 7750, 12750];

export const getRewardTierStats = (referralCount: number) => {
    const referralLevels = Object.keys(referralReward).map(Number);
    const reward = getReferralReward(referralCount);

    // get next tier
    const currentTierIndex = referralLevels.findIndex(tier => referralReward[tier] === reward);
    const nextTierIndex = Math.min(currentTierIndex + 1, referralLevels.length - 1);

    const collectedReferrals = referralCount - referralLevels[currentTierIndex] + (currentTierIndex !== 0 ? 1 : 0);
    const remainingReferrals = referralLevels[nextTierIndex] - referralCount;

    const currentTierReward = referralReward[referralLevels[currentTierIndex]];
    const nextTierReward = referralReward[referralLevels[nextTierIndex]];

    const totalCoins = totalCollectedPerLevel[nextTierIndex];

    return {
        collectedReferrals,
        remainingReferrals,
        collectedCoins: totalCollectedPerLevel[currentTierIndex] + currentTierReward * collectedReferrals,
        totalCoins,
        currentTierReward,
        nextTierReward,
    };
};

export const getReferralReward = (referralCount: number) => {
    const milestone = Object.keys(referralReward)
        .map(Number)
        .reverse()
        .find(milestone => milestone <= referralCount);

    return referralReward[milestone] ?? 200;
};

export const AD_COIN_REWARD = 5;

export const BREAK_TIME_TO_WATCH_AD = 30 * TIME.MINUTE;

type ActionData = {
    icon: string;
    title: string;
    description: string;
    actions: string[];
};

export const getHowToEarnActions = (type: ExtraActionEnum, isTeacher: boolean): ActionData => {
    switch (type) {
        case ExtraActionEnum.STREAK:
            return {
                icon: `${KNOWT_GAMIFICATION_STORAGE}/streak-icon.svg`,
                title: "Actions that earn a streak",
                description: "Keep your streak going by completing any of these actions.",
                actions: isTeacher
                    ? [
                          "Create or open a class",
                          "Create note/flashcard set",
                          "Review a note",
                          "Play Matching game",
                          "Take Practice Test",
                          "Open a note",
                          "Open or upload a Video/PDF",
                          "Complete a Learn Mode round",
                          "Starting Flashcard Mode",
                          "Giving a rating on note/flashcard",
                      ]
                    : [
                          "Create note/flashcard set",
                          "Open a note",
                          "Play Matching game",
                          "Starting Flashcard Mode",
                          "Open or upload a Video/PDF",
                          "Complete a Learn Mode round",
                          "Take Practice Test",
                          "Giving a rating on note/flashcard",
                      ],
            };
        case ExtraActionEnum.COINS:
            return {
                icon: `${KNOWT_GAMIFICATION_STORAGE}/coin-icon.svg`,
                title: "Actions that earn coins",
                description: "Collect coins and redeem them for cool in-game items.",
                actions: ["Refer friends", "Reach a streak milestone", "Level up", "Refer teachers", "Complete badges"],
            };
        case ExtraActionEnum.LEVELS:
            return {
                icon: `${KNOWT_GAMIFICATION_STORAGE}/level-icon.svg`,
                title: "Actions that earn XP",
                description: "Earn XP and advance to the next level by completing these actions.",
                actions: isTeacher
                    ? [
                          "Create a class",
                          "Completing privacy onboarding",
                          "Receive a 4/5 star rating, or giving any rating",
                          "Make notes/flashcards public",
                          "Practice Test",
                          "Playing Matching Game",
                          "Making flashcards/tests from your notes using AI",
                          "Chat with AI",
                          "Invite students",
                          "Create note/flashcard set",
                          "Spaced Repetition rounds",
                          "Completing Learn mode",
                          "Use Brainstorm Assistant",
                          "Making flashcards/notes from videos using AI",
                          "Making flashcards/notes from PDFs using AI",
                          "Join a class",
                      ]
                    : [
                          "Create note/flashcard set",
                          "Make notes/flashcards public",
                          "Practice Test",
                          "Playing Matching Game",
                          "Making flashcards/tests from your notes using AI",
                          "Chat with AI",
                          "Join a class",
                          "Receive a 4/5 star rating, or giving any rating",
                          "Spaced Repetition rounds",
                          "Completing Learn mode",
                          "Use Brainstorm Assistant",
                          "Making flashcards/notes from videos using AI",
                          "Making flashcards/notes from PDFs using AI",
                      ],
            };
        default:
            throw new Error("Invalid action type");
    }
};

export const DEFAULT_GAMIFY_ATTRIBUTES = (user: UserDetails) => ({
    coins: 0,
    xp: 0,
    level: 0,
    streak: 0,
    inventory: {
        freezes: 3,
        pauses: 4,
        revivals: 0,
        features: [],
    },
    records: {
        xpEarned: `0:${todayDate(user.timeZone)}`,
        badgesEarned: `0:${todayDate(user.timeZone)}`,
        longestStreak: `0:${todayDate(user.timeZone)}`,
        coinsEarned: `0:${todayDate(user.timeZone)}`,
    },
    featuredBadges: [],
});

export const DEFAULT_MAX_ACTIONS_PER_DAY = 10_000;

export const MAX_ACTIONS_PER_DAY = {
    [ActionEnum.FINISH_LEARN_MODE_ROUND]: 600,
    [ActionEnum.FINISH_PRACTICE_TEST]: 600,
    [ActionEnum.FINISH_SPACED_REPITION_ROUND]: 600,
    [ActionEnum.FINISH_MATCHING_GAME]: 600,
    [ActionEnum.BRAINSTORM_ASSISTANT]: 600,
    [ActionEnum.CREATE_FLASHCARDSET]: 300,
    [ActionEnum.CREATE_NOTE]: 300,
    [ActionEnum.CHAT_WITH_AI]: 500,
};
