import { AICompletionType } from "@knowt/syncing/graphql/schema";

export const AI_LAMBDA_URL = "https://zzveivpchfbyzbewndpizfawhq0jzvid.lambda-url.us-east-1.on.aws/";

export enum ALL_AI_MODELS {
    GPT4O_MINI = "gpt-4o-mini",
    GPT4_TURBO = "gpt-4-turbo",
    O3_MINI = "o3-mini",
    CLAUDE_HAIKU = "us.anthropic.claude-3-5-haiku-20241022-v1:0",
    FLASH_MINI = "gemini-1.5-flash-8b",
    FLASH_2_0 = "gemini-2.0-flash",
}

export type AI_MODEL =
    | "gpt-4o-mini"
    | "gpt-4-turbo"
    | "o3-mini"
    | "us.anthropic.claude-3-5-haiku-20241022-v1:0"
    | "gemini-1.5-flash-8b"
    | "gemini-2.0-flash";

export enum GPT_MODELS {
    GPT4O_MINI = ALL_AI_MODELS.GPT4O_MINI,
    GPT4_TURBO = ALL_AI_MODELS.GPT4_TURBO,
    O3_MINI = ALL_AI_MODELS.O3_MINI,
}

export enum GEMINI_MODELS {
    FLASH_MINI = ALL_AI_MODELS.FLASH_MINI,
    FLASH_2_0 = ALL_AI_MODELS.FLASH_2_0,
}

export enum BEDROCK_MODELS {
    CLAUDE_HAIKU = ALL_AI_MODELS.CLAUDE_HAIKU,
}

export const GPT_MODEL = GPT_MODELS.GPT4O_MINI;
export const GEMINI_MODEL = GEMINI_MODELS.FLASH_2_0;
export const CLAUDE = BEDROCK_MODELS.CLAUDE_HAIKU;

export const CONTEXT_WINDOW_BY_MODEL: {
    [key in AI_MODEL]: number;
} = {
    [GPT_MODELS.GPT4O_MINI]: 128_000,
    [GPT_MODELS.GPT4_TURBO]: 128_000,
    [GPT_MODELS.O3_MINI]: 128_000,
    [GEMINI_MODELS.FLASH_MINI]: 128_000,
    [GEMINI_MODELS.FLASH_2_0]: 128_000,
    [BEDROCK_MODELS.CLAUDE_HAIKU]: 128_000,
};

export type CLAUDE_AI_PROMPT = {
    modelId: string;
    system: {
        text: string;
    }[];
    messages?: {
        role: "user" | "system" | "developer";
        content: {
            text: string;
            type: "text";
        }[];
    }[];
    additionalModelResponseFieldPaths?: string[];
    inferenceConfig: {
        maxTokens: number;
        temperature: number;
        topP: number;
    };
};

export const AI_PROMPT_PARAMS_MAP_RUNTIME: Record<string, any> = {
    [AICompletionType.NOTE_TEST]: {
        length: "number",
        difficulty: "string",
        note: "string",
        noteId: "string",
        language: "string",
    },
    [AICompletionType.NOTE_FLASHCARDS]: {
        language: "string",
        length: "number",
        note: "string",
        format: "string",
    },
    [AICompletionType.EXPLAIN_WHY_IM_WRONG]: {
        question: "string",
        rightAnswer: "string",
        selectedAnswer: "string",
    },
    [AICompletionType.LESSON_PLAN]: {
        grade: "string",
        topic: "string",
        additionalCriteria: "string",
        standards: "string",
        classroomContext: "string",
        time: "string",
    },
    [AICompletionType.RUBRIC_GENERATOR]: {
        grade: "string",
        pointScale: "string",
        objective: "string",
        description: "string",
        additionalInfo: "string",
    },
    [AICompletionType.WRITING_FEEDBACK]: {
        grade: "string",
        description: "string",
        rubric: "string",
        additionalInfo: "string",
        text: "string",
    },
    [AICompletionType.READING_TEXT]: {
        grade: "string",
        textLength: "string",
        textType: "string",
        topic: "string",
        additionalInfo: "string",
    },
    [AICompletionType.WORKSHEET_GENERATOR]: {
        grade: "string",
        topic: "string",
        additionalInfo: "string",
        standards: "string",
        lengthOfWorksheet: "time",
    },
    [AICompletionType.TEXT_LEVELER]: {
        grade: "string",
        text: "string",
        objective: "string",
        standards: "string",
        additionalInfo: "string",
        classroomContext: "string",
    },
    [AICompletionType.PROFESSIONAL_EMAIL]: {
        author: "string",
        recipient: "string",
        relationShipToRecipient: "string",
        purpose: "string",
        includedContent: "string",
        tone: "string",
        contentCustomization: "string",
    },
    [AICompletionType.TEXT_PROOFREADER]: {
        text: "string",
        objective: "string",
        additionalInfo: "string",
    },
    [AICompletionType.TEXT_PARAPHRASER]: {
        originalText: "string",
        rewriteInstructions: "string",
        toneOptions: "string",
        preserveKeyTerms: "boolean",
        maintainLength: "boolean",
        specialInstructions: "boolean",
    },
    [AICompletionType.MAKE_IT_RELEVANT]: {
        grade: "string",
        topic: "string",
        interests: "string",
    },
    [AICompletionType.CLASS_NEWSLETTER_GENERATOR]: {
        grade: "string",
        importantAnnouncements: "string",
        recentCelebrations: "string",
        additionalInfo: "string",
        standardsOrFiles: "string",
    },
    [AICompletionType.VOCABULARY_LIST_GENERATOR]: {
        grade: "string",
        topic: "string",
        numberOfWords: "number",
        includeExample: "boolean",
        includeDefinitions: "boolean",
        includePartOfSpeech: "boolean",
        includeSynonyms: "boolean",
        includeAntonyms: "boolean",
        includeEtymology: "boolean",
        includePronunciation: "boolean",
        specialInstructions: "string",
    },
    [AICompletionType.DISCUSSION_PROMPT_GENERATOR]: {
        grade: "string",
        topic: "string",
        questionsPerSection: "number",
    },
    [AICompletionType.WRITING_PROMPT_GENERATOR]: {
        topic: "string",
        gradeLevel: "string",
        essayStyle: "string",
        wordLimit: "number",
    },
    [AICompletionType.MATH_WORD_PROBLEM_GENERATOR]: {
        grade: "string",
        numQuestions: "string",
        mathTopic: "string",
        storyTopic: "string",
    },
    [AICompletionType.SCIENCE_LAB_GENERATOR]: {
        grade: "string",
        subject: "string",
        topic: "string",
        labType: "string",
        duration: "string",
        safetyLevel: "string",
        materials: "string",
        objectives: "string",
        specialInstructions: "string",
    },
    // [AICompletionType.MULTIPLE_CHOICE_QUIZ_GENERATOR]: {
    //     subject: "string",
    //     numQuestions: "number",
    //     grade: "string",
    //     standards: "string",
    // },
    [AICompletionType.DEBATE_SCENARIO_GENERATOR]: {
        grade: "string",
        subject: "string",
        topic: "string",
        format: "string",
        difficulty: "string",
        time: "string",
        participants: "number",
        specialInstructions: "string",
    },
    [AICompletionType.ICE_BREAKER_ACTIVITIES]: {
        grade: "string",
        settings: "string",
        time: "string",
        participants: "number",
        requireMaterials: "string",
    },
    [AICompletionType.CONTENT_SUMMARIZER]: {
        originalText: "string",
        lengthOfSummary: "string",
    },
    [AICompletionType.JEOPARDY_GAME_GENERATOR]: {
        gradeLevel: "string",
        topic: "string",
        categories: "string",
    },
    [AICompletionType.DECODABLE_TEXT_GENERATOR]: {
        pattern: "string",
    },
    [AICompletionType.STANDARDS_UNPACKER]: {
        grade: "string",
        subject: "string",
        standardType: "string",
        standardText: "string",
        keySkills: "boolean",
        prerequisites: "boolean",
        essentialVocabulary: "boolean",
        crossCurricularConnections: "boolean",
        assessmentIdeas: "boolean",
        resourceSuggestions: "boolean",
        differentiationStrategies: "boolean",
        commonMisconceptions: "boolean",
        specialInstructions: "string",
    },
    [AICompletionType.JOKE_GENERATOR]: {
        gradeLevel: "string",
        topic: "string",
        standards: "string",
    },
    [AICompletionType.TEXT_TRANSLATOR]: {
        originalText: "string",
        targetLanguage: "string",
    },
    [AICompletionType.STORY_WORD_PROBLEMS_GENERATOR]: {
        gradeLevel: "string",
        topicObjective: "string",
        storyTopic: "string",
        numberOfStories: "number",
    },
    [AICompletionType.BIP_GENERATOR]: {
        gradeLevel: "string",
        problemDescription: "string",
    },
    [AICompletionType.TIME_BASED_ACTIVITY_GENERATOR]: {
        gradeLevel: "string",
        duration: "string",
        topic: "string",
    },
    [AICompletionType.RECOMMENDATION_LETTER_GENERATOR]: {
        studentFullName: "string",
        studentPronouns: "string",
        institution: "string",
        relationshipToStudent: "string",
        keyInformation: "string",
    },
    [AICompletionType.PERFORMANCE_TASK_GENERATOR]: {
        gradeLevel: "string",
        subject: "string",
        customInstructions: "string",
        standards: "string",
    },
    [AICompletionType.PLC_AGENDA_GENERATOR]: {
        specificRequest: "string",
        objective: "string",
        additionalItems: "string",
    },
    [AICompletionType.SYLLABUS_GENERATOR]: {
        gradeLevel: "string",
        subject: "string",
        courseDescription: "string",
        courseObjectives: "string",
        gradingPolicy: "string",
        classroomPolicies: "string",
        courseOutline: "string",
        additionalInfo: "string",
        requiredMaterials: "string",
        standards: "string",
    },
    [AICompletionType.SONG_GENERATOR]: {
        topic: "string",
        details: "string",
        artistName: "string",
        songTitle: "string",
    },
    [AICompletionType.MATH_SPIRAL_REVIEW_GENERATOR]: {
        grade: "string",
        numProblems: "number",
        mathContent: "string",
        additionalCriteria: "string",
    },
    [AICompletionType.REPORT_CARD_COMMENTS]: {
        grade: "string",
        teacher: "string",
        studentName: "string",
        studentPronouns: "string",
        strengths: "string",
        areasForImprovement: "string",
        classroomContext: "string",
        tone: "string",
        subject: "string",
    },
    [AICompletionType.AP_HISTORY_MCQ_GENERIC]: {
        prompt: "string",
        difficulty: "string",
        note: "string",
        examName: "string",
        unit: "string",
    },
};

export enum NoteFormatEnum {
    BULLET_POINTS = "BULLET_POINTS",
    PARAGRAPHS = "PARAGRAPHS",
    PAGE_BY_PAGE = "PAGE_BY_PAGE",
}

export enum NoteLengthEnum {
    IN_DEPTH_NOTES = "IN_DEPTH_NOTES",
    CONCISE_SUMMARY = "CONCISE_SUMMARY",
}

export enum FlashcardFormatEnum {
    QUESTION_AND_ANSWER = "QUESTION_AND_ANSWER",
    FILL_IN_THE_BLANK = "FILL_IN_THE_BLANK",
    VOCABULARY = "VOCABULARY",
}

export enum NoteAutocompleteType {
    AUTOCOMPLETE = "AUTOCOMPLETE",
    REVISION = "REVISION",
}

export enum FlashcardAutocompleteType {
    AUTOCOMPLETE_TERM = "AUTOCOMPLETE_TERM",
    AUTOCOMPLETE_DEFINITION = "AUTOCOMPLETE_DEFINITION",
    AUTOCOMPLETE_TERM_FROM_DEFINITION = "AUTOCOMPLETE_TERM_FROM_DEFINITION",
    AUTOCOMPLETE_DEFINITION_FROM_TERM = "AUTOCOMPLETE_DEFINITION_FROM_TERM",
    REVISION_TERM = "REVISION_TERM",
    REVISION_DEFINITION = "REVISION_DEFINITION",
    REVISION_TERM_FROM_DEFINITION = "REVISION_TERM_FROM_DEFINITION",
    REVISION_DEFINITION_FROM_TERM = "REVISION_DEFINITION_FROM_TERM",
}

export enum FlashcardChatOptionLabels {
    /**web - basic suggestions */
    REGENERATE_FLASHCARDS = "Regenerate all the flashcards",
    CREATE_MORE_FLASHCARDS = "Create more flashcards",

    /**web - selection suggestions */
    MAKE_IT_MORE_DETAILED_FLASHCARDS = "Make it more detailed",
    SUMMARIZE_THE_DEFINITION = "Make it more concise",
    REGENERATE_SELECTED_FLASHCARD = "Regenerate selected flashcards",

    /**web - post answer suggestions */
    TRY_AGAIN_FLASHCARD_OUTPUT = "Try again",
}

export const FlashcardChatOptionPrompts = {
    /**web - basic suggestions */
    [FlashcardChatOptionLabels.REGENERATE_FLASHCARDS]:
        "Regenerate the current set of flashcards with more descriptive definitions and clarify terms whenever necessary",
    [FlashcardChatOptionLabels.CREATE_MORE_FLASHCARDS]:
        "Create more flashcards based on the current list. Keep the flashcards relevant to the current topic, and make sure they are concise and informative.",
    /**web - selection suggestions */
    [FlashcardChatOptionLabels.MAKE_IT_MORE_DETAILED_FLASHCARDS]:
        "Make the selected flashcard more detailed and informative.",
    [FlashcardChatOptionLabels.SUMMARIZE_THE_DEFINITION]:
        "Summarize the definition of the selected flashcard in a concise manner while retaining key information.",
    [FlashcardChatOptionLabels.REGENERATE_SELECTED_FLASHCARD]:
        "Regenerate the selected flashcard with more descriptive definition and clarify terms whenever necessary.",

    /**web - post answer suggestions */
    [FlashcardChatOptionLabels.TRY_AGAIN_FLASHCARD_OUTPUT]:
        "Reword the previous flashcards as per the new requirement of user.",
};

export enum NoteChatOptionLabels {
    /**web - basic suggestions */
    SUMMARIZE_MORE = "Make it more concise",
    MAKE_IT_MORE_DETAILED = "Make it more detailed",
    REWRITE_WITH_PARAGRAPH = "Rewrite with paragraphs",

    /**web - selection suggestions */
    MAKE_IT_MORE_DETAILED_NOTE = "Make it more detailed",
    SUMMARIZE_THIS_SELECTION_MORE_NOTE = "Summarize this more",
    CREATE_FLASHCARDS_FROM_SELECTION = "Create flashcard(s) from selection",
    REWORD_THIS_SELECTION_NOTE = "Reword this selection",

    /**web - post answer suggestions */
    TRY_AGAIN_NOTE_OUTPUT = "Try again",

    /**web - post answer suggestions, for flashcards */
    QNA_FLASHCARDS = "I want Q & A flashcards instead",
    CREATE_MORE_FLASHCARDS = "Create more flashcards from note",
}

export const NoteChatOptionPrompts = {
    /**web - basic suggestions */
    [NoteChatOptionLabels.SUMMARIZE_MORE]: "Summarize the note while still maintaining the key information",
    [NoteChatOptionLabels.MAKE_IT_MORE_DETAILED]: "Make the text more detailed and add more important information",
    [NoteChatOptionLabels.REWRITE_WITH_PARAGRAPH]:
        "Rewrite the note in a paragraph format, ensuring that all key information is retained. Return the note in Markdown format and include headings and subheadings when necessary",
    /**web - selection suggestions */
    [NoteChatOptionLabels.SUMMARIZE_THIS_SELECTION_MORE_NOTE]:
        "Summarize this selection and make it shorter while still maintaining the key information",
    [NoteChatOptionLabels.CREATE_FLASHCARDS_FROM_SELECTION]:
        "Create flashcards from the selected content, only create bare minimum flashcards with the most important information, stick to 1 flashcard in most cases unless absolutely necessary.",
    [NoteChatOptionLabels.REWORD_THIS_SELECTION_NOTE]:
        "Reword this selection to be more descriptive and clarify unclear portions",
    /**web - post answer suggestions */
    [NoteChatOptionLabels.TRY_AGAIN_NOTE_OUTPUT]: "Reword the previous output as per the new requirement of user.",

    /**web - post answer suggestions, for flashcards */
    [NoteChatOptionLabels.QNA_FLASHCARDS]:
        "Convert previously generated flashcards to flashcards in Q&A format. Term is the question and definition is the answer.",
    [NoteChatOptionLabels.CREATE_MORE_FLASHCARDS]:
        "Create more flashcards from the note. Ensure that the flashcards are concise and informative and relevant to the current topic of the note or selected section of the note",
};

export const CommonReadOnlyChatPrompts = {
    WHAT_ARE_THE_KEY_TERMS: "From the source of this document, list down the important points/terms to study.",
    BRIEF_SUMMARY:
        "Can you provide a brief summary of the document, if it's a flashcardSet, summarizer the main topic and briefly list each flashcard.",
};

export const FLASHCARD_FORMAT_LABEL_MAP = {
    [FlashcardFormatEnum.VOCABULARY]: {
        label: "Regular",
        value: FlashcardFormatEnum.VOCABULARY,
    },
    [FlashcardFormatEnum.FILL_IN_THE_BLANK]: {
        label: "Fill in the blank",
        value: FlashcardFormatEnum.FILL_IN_THE_BLANK,
    },
    [FlashcardFormatEnum.QUESTION_AND_ANSWER]: {
        label: "Question & Answer",
        value: FlashcardFormatEnum.QUESTION_AND_ANSWER,
    },
};

export const NOTE_LENGTH_LABEL_MAP = {
    [NoteLengthEnum.IN_DEPTH_NOTES]: {
        label: "In-depth Notes",
        value: NoteLengthEnum.IN_DEPTH_NOTES,
    },
    [NoteLengthEnum.CONCISE_SUMMARY]: {
        label: "Concise Summary",
        value: NoteLengthEnum.CONCISE_SUMMARY,
    },
};

export const NOTE_FORMAT_LABEL_MAP = {
    [NoteFormatEnum.BULLET_POINTS]: {
        label: "Outline Format",
        value: NoteFormatEnum.BULLET_POINTS,
    },
    [NoteFormatEnum.PARAGRAPHS]: {
        label: "Paragraph Format",
        value: NoteFormatEnum.PARAGRAPHS,
    },
    [NoteFormatEnum.PAGE_BY_PAGE]: {
        label: "By file page",
        value: NoteFormatEnum.PAGE_BY_PAGE,
    },
};
