import { badgeMetadata } from "@/badges";
import { claimBadgeReward } from "@/graphql/mutations";
import { listBadgeProgress } from "@/graphql/queries";
import { BadgeEnum, BadgeGroupEnum, BadgeProgress } from "@/graphql/schema";
import { client, listData, ServerClientWithCookies } from "@/utils/client/graphql";
import { streakMilestones } from "../constants";

export const frontendBadgeMetadata = badgeMetadata.map(badge => {
    if (badge.group === BadgeGroupEnum.MILESTONES) {
        const rewards = Object.values(streakMilestones);
        badge = {
            ...badge,
            tiers: badge.tiers.map((tier, i) => ({
                ...tier,
                xpReward: rewards[i].xp,
                coinReward: rewards[i].coins,
            })),
        };
    }

    return badge;
});

export const callListBadgeProgress = async ({
    userId,
    serverClient,
}: {
    userId?: string;
    serverClient?: ServerClientWithCookies;
} = {}) => {
    return (await listData({
        listQuery: listBadgeProgress,
        input: { userId },
        queryName: "listBadgeProgress",
        serverClient,
    })) as BadgeProgress[];
};

export const callListBadgesMetadata = () => frontendBadgeMetadata;

export const callClaimBadgeReward = async ({
    badge,
    tiers,
    serverClient,
}: {
    badge: BadgeEnum;
    tiers: Array<number>;
    serverClient?: ServerClientWithCookies;
}) => {
    return (
        await client.mutate({
            mutation: claimBadgeReward,
            variables: {
                input: {
                    badge,
                    tiers,
                },
            },
            serverClient,
        })
    ).data.claimBadgeReward;
};
