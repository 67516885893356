import { Fetcher, Key, Middleware, SWRConfiguration, SWRHook } from "swr";
import { isFunction } from "@/utils/genericUtils";

export const withMiddleware = (useSWR: SWRHook, middleware: Middleware): SWRHook => {
    return <Data = any, Error = any>(
        ...args: [Key] | [Key, Fetcher<Data> | null] | [Key, Fetcher<Data> | null, SWRConfiguration | undefined]
    ) => {
        const [key, fn, config] = normalizeKey(args);
        const uses = (config.use || []).concat(middleware);
        return useSWR<Data, Error>(key, fn, { ...config, use: uses });
    };
};

/**
 * returns [key, fetcher, config] with fetcher set to null if not provided
 * @param args
 */
const normalizeKey = <KeyType = Key, Data = any>(
    args: [KeyType] | [KeyType, Fetcher<Data> | null] | [KeyType, Fetcher<Data> | null, SWRConfiguration | undefined]
): [KeyType, Fetcher<Data> | null, Partial<SWRConfiguration<Data>>] => {
    return [args[0], isFunction(args[1]) ? args[1] : null, args[2] || {}];
};
