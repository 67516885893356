import { ActionEnum, StoreProductEnum, UserDetails } from "@/graphql/schema";
import { StreakStatus, progressingEvent } from "@/hooks/gamification/monitoring/progressing";
import { isFuture, isToday, isYesterday } from "@/hooks/gamification/monitoring/streakMonitoring/utils";
import { applyStreakAction, applyStreakActionV2 } from "../../utils";
import { today as _today, daysApart, todayDate } from "@/utils/dateTimeUtils";
import { platform } from "@/platform";
import { mutate } from "swr";
import { resolveDailyActivitySWRKey } from "../../activities/utils";
import { StableSWRKeys } from "@/hooks/swr/swr";
import { assertTruthy } from "@/utils/assertions";
import { MAX_DAYS_TO_REVIVE_STREAK } from "../../constants";

const LS_STREAK_ENCOURAGEMENT_POPUP_KEY = "streak-encouragement-popup";

const getIsTooLateToRevive = (user: UserDetails) => {
    assertTruthy(user, "User must be loaded before checking if it's too late to revive the streak");

    const date = todayDate(user.timeZone);
    const days = daysApart(date, user.lastStreakDate || date);

    return days > MAX_DAYS_TO_REVIVE_STREAK;
};

export const checkStreakStatus = async (user: UserDetails) => {
    const tz = user.timeZone ?? undefined;

    if (!user.lastStreakDate) {
        // no streak to monitor
        return;
    }

    const today = _today(tz).format("YYYY-MM-DD");

    const isTodayStreakComplete = isToday(user.lastStreakDate, tz);

    if (isTodayStreakComplete || isYesterday(user.lastStreakDate, tz)) {
        // We're good, nothing has to be done, the user has already
        // done a streak action today, or yesterday but still has the
        // chance to extend the streak today.

        const storage = await platform.storage();
        const lastShownDate = (await storage.get(LS_STREAK_ENCOURAGEMENT_POPUP_KEY)) ?? "";

        if (!isTodayStreakComplete && lastShownDate !== today && !user.longPauseEnd) {
            storage.set(LS_STREAK_ENCOURAGEMENT_POPUP_KEY, today);
            progressingEvent.notify({
                [StreakStatus.STREAK_ENCOURAGEMENT_POPUP]: {},
            });
        }

        return;
    }

    if (isToday(user.longPauseEnd, tz) || isFuture(user.longPauseEnd, tz)) {
        // a long pause is being applied, no need for freezes and revivals checks
        return;
    }

    try {
        const previousStreakFreezes = user.inventory?.freezes ?? 0;

        const response = await applyStreakActionV2({
            action: ActionEnum.STREAK_FREEZE,
        });

        if (response.gameError) {
            throw new Error(response.gameError);
        }

        const updatedUser = response.user;

        const remainingStreakFreezes = updatedUser.inventory?.freezes ?? 0;
        const usedStreakFreezes = previousStreakFreezes - remainingStreakFreezes;

        if (usedStreakFreezes > 0 && user.notifSettings?.gamifySettings?.streak !== false) {
            progressingEvent.notify({
                [StreakStatus.STREAK_FREEZES_WERE_USED]: { usedStreakFreezes, streak: updatedUser.streak },
            });
        }

        const mixpanel = await platform.analytics.mixpanel();

        await mixpanel.track("Streak - Frozen", {
            numberOffreezesUsed: usedStreakFreezes,
            storeProductId: StoreProductEnum.STREAK_FREEZE,
            xp: updatedUser?.xp,
            level: updatedUser?.level,
            streak: updatedUser?.streak,
            coins: updatedUser?.coins,
            gamificationRecords: updatedUser?.records,
            popupDisabled: updatedUser?.notifSettings?.gamifySettings?.streak === false,
        });

        return;
    } catch (error) {
        // refetch daily activity to update the weekly activities
        await mutate(resolveDailyActivitySWRKey(user.ID));
        await mutate(StableSWRKeys.USER);

        if (error.message.toLowerCase().includes("streak freezes")) {
            const mixpanel = await platform.analytics.mixpanel();
            await mixpanel.track("Streak - Ended", {
                lastStreakDate: user?.lastStreakDate,
                xp: user?.xp,
                level: user?.level,
                streak: user?.streak,
                coins: user?.coins,
                gamificationRecords: user?.records,
                popupDisabled: user?.notifSettings?.gamifySettings?.streak === false,
            });

            if (user.notifSettings?.gamifySettings?.streak === false) {
                // user has disabled streak popups, just cancel their streak for them without the popup
                return await applyStreakAction({
                    action: ActionEnum.CANCEL_STREAK,
                });
            }

            const isTooLateToRevive = getIsTooLateToRevive(user);

            const isSmallStreak = user.streak < 3;

            if (isTooLateToRevive || isSmallStreak) {
                // if its too late, no need to notify them, we can just cancel the streak for them
                return await applyStreakAction({
                    action: ActionEnum.CANCEL_STREAK,
                });
            }

            progressingEvent.notify({ [StreakStatus.STREAK_BROKEN]: {} });
        }
    }
};
