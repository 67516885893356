import { runESQueryFull } from "@knowt/syncing/fetchFunctions/elasticsearch";
import {
    manuallyVerifyUser,
    requestDeleteAccount,
    updateEmail,
    updateUserDetailsV2,
    updateUsernameV2,
    verifyStripeCheckout,
} from "@knowt/syncing/graphql/mutations";
import {
    getCurrentUserAndOrganization,
    getIntercomUserHash,
    getUserByRefV2,
    isUsernameAvailable,
} from "@knowt/syncing/graphql/queries";
import { ServerClientWithCookies, client } from "@knowt/syncing/utils/client/graphql";
import {
    Organization,
    OrganizationSettings,
    OrganizationSharing,
    PlatformEnum,
    SubscriptionType,
    UserDetailsInput,
} from "./../../graphql/schema";

import { platform } from "@knowt/syncing/platform";
import { GraphQLError } from "@knowt/syncing/utils/client/types";
import { now } from "@knowt/syncing/utils/dateTimeUtils";
import { fromEntries, retry } from "@knowt/syncing/utils/genericUtils";
import { UserDetails } from "@knowt/syncing/graphql/schema";
import { LocalAuthUser, LocalUnauthUser, LocalUser } from "./types";

export const callUpdateUserDetails = (updates: UserDetailsInput) => {
    return client
        .mutate({
            mutation: updateUserDetailsV2,
            variables: { input: updates },
        })
        .then(({ data }) => data.updateUserDetailsV2);
};

export const generateLocalUser = ({
    user,
    organization,
}: {
    user: UserDetails | null | undefined;
    organization: Organization | null | undefined;
}) => {
    if (!user) {
        return generateUnauthUser();
    }

    return generateAuthUser({ user, organization });
};

export const generateAuthUser = ({
    user,
    organization,
}: {
    user: UserDetails;
    organization: Organization | null | undefined;
}) => {
    return {
        user,
        organization,
        serverSyncTime: now(),
    } as LocalAuthUser;
};

export const generateUnauthUser = () => {
    return {
        user: null,
        organization: null,
        serverSyncTime: now(),
    } as LocalUnauthUser;
};

export const getAuthenticatedUser = async ({
    forceStripeVerify = false,
    serverClient,
}: {
    forceStripeVerify?: boolean;
    serverClient?: ServerClientWithCookies;
} = {}): Promise<LocalUser> => {
    try {
        const { user, organization } = await client
            .query({
                query: getCurrentUserAndOrganization,
                variables: { input: { forceStripeVerify } },
                serverClient,
            })
            .then(({ data }) => data.getCurrentUserAndOrganization);

        if (!user) {
            return generateUnauthUser();
        }

        return generateAuthUser({ user, organization });
    } catch (e) {
        if ((e as GraphQLError).errorType !== "Unauthorized") {
            // biome-ignore lint: noConsole
            console.log("ERROR FETCHING USER", e);
        }
        return generateUnauthUser();
    }
};

export const callGetUserByRefV2 = async ({ ref_v2 }: { ref_v2: string }) => {
    return await client
        .query({
            query: getUserByRefV2,
            variables: { input: { ref_v2 } },
        })
        .then(({ data }) => data.getUserByRefV2);
};

export const callUpdateUserEmail = ({ newEmail, password }) =>
    client
        .mutate({
            mutation: updateEmail,
            variables: { input: { newEmail: newEmail, password } },
        })
        .then(({ data }) => data.updateEmail);

export const callUpdateUsername = async (username: string) => {
    return await client
        .mutate({
            mutation: updateUsernameV2,
            variables: { input: { username } },
        })
        .then(({ data }) => data.updateUsernameV2);
};

export const callIsUsernameAvailable = async (username: string) => {
    return await client
        .query({
            query: isUsernameAvailable,
            variables: { input: { username: username.toLowerCase() } },
        })
        .then(({ data }) => data.isUsernameAvailable);
};

export const isSomeBasicInfoMissing = (user: UserDetails | null | undefined) => {
    if (!user) return false;

    const basicInfo = ["accountType", "username", "birthday"];
    return basicInfo.some(info => !user[info]);
};

export const getUserPlan = (user: Pick<UserDetails, "subscriptionType" | "orgPlanType"> | null | undefined) => {
    if (!user) return SubscriptionType.BASIC;

    return user.orgPlanType ?? user.subscriptionType ?? SubscriptionType.BASIC;
};

const DEFAULT_ORG_SETTINGS: OrganizationSettings = {
    __typename: "OrganizationSettings",
    sharing: OrganizationSharing.PUBLIC,
    subsAllowed: true,
    ai: true,
};

export const getOrganizationalSettings = ({
    user,
    organization,
}: {
    user: UserDetails | null | undefined;
    organization: Organization | null | undefined;
}): OrganizationSettings => {
    if (!user || !organization) {
        return DEFAULT_ORG_SETTINGS;
    }

    const schoolSettings = organization.schoolsSettings.find(school => school.schoolId === user.schoolId);
    const districtSettings = organization.settings;

    return schoolSettings || districtSettings || DEFAULT_ORG_SETTINGS;
};

export const USER_PUBLIC_PICKED_FIELDS: (keyof UserDetails)[] = [
    "accountType",
    "ID",
    "Name",
    "pictureUrl",
    "verified",
    "bio",
    "cover",
    "numFollowers",
    "numFollowing",
    "timeZone",
    "username",
    "lastLogIn",
    "rating",
    "ratingCount",
    "socials",
    "subscriptionType",
    "profileColor",
    "invites",
    "org",
    "orgPlanType",
    "xp",
    "level",
    "featuredBadges",
    "ref_v2",
    "elID",
    "streak",
    "lastStreakDate",
    "xpSyncDate",
    "coins",
    "gameBlock",
    "records",
    "inventory",
];

/**
 * Takes in a list of user IDs and returns an object of public data per userId
 */
export const fetchUsersInfo = async ({ userIds }: { userIds: string[] }) => {
    if (!userIds || !userIds.length) {
        return {};
    }

    userIds = userIds.filter(Boolean);

    const usersInfo = (
        await runESQueryFull({
            queryFields: ["ID"],
            queryPhrase: userIds,
            returnFields: USER_PUBLIC_PICKED_FIELDS,
            searchIndex: ["USER"],
            pagesize: 25,
        })
    ).items as UserDetails[];

    return fromEntries(usersInfo.map(user => [user.ID, user])) as Record<string, UserDetails>;
};

export const callVerifyStripeCheckout = async (customerId?: string | null, serverClient?: ServerClientWithCookies) => {
    if (!customerId) throw new Error("No customerId provided, checkout not verified!");

    return retry(async () =>
        client
            .mutate({
                mutation: verifyStripeCheckout,
                variables: {
                    input: {
                        customerId,
                    },
                },
                serverClient,
            })
            .then(({ data }) => data.verifyStripeCheckout)
            .catch(async error => {
                const { report } = await platform.analytics.logging();
                report(error, "verifyStripeCheckout", { customerId });
                throw error;
            })
    );
};

export const callRequestDeleteAccount = async ({
    userId,
    category,
    followUp,
    reason,
    deleteReq,
    user,
}: {
    userId?: string | null;
    category?: string | null;
    followUp?: string | null;
    reason?: string | null;
    deleteReq?: boolean;
    user?: UserDetails | null;
}) => {
    const input = {
        userId,
        category,
        followUp,
        reason,
        deleteReq,
    };

    return await client
        .mutate({
            mutation: requestDeleteAccount,
            variables: { input },
        })
        .then(async () => {
            const mixpanel = await platform.analytics.mixpanel();
            mixpanel.track("Delete Account - Requested", {
                category,
                followUp,
                reason,
                isRequestDelete: deleteReq,
                xp: user?.xp,
                level: user?.level,
                streak: user?.streak,
                coins: user?.coins,
                gamificationRecords: user?.records,
            });
            // TODO: (App Router): server side can't call signout() since its in a file that imports client stuff
        })
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "requestDeleteAccount", input);
            throw error;
        });
};

export const callManuallyVerifyUser = async (username: string) => {
    return await client
        .mutate({
            mutation: manuallyVerifyUser,
            variables: { input: { username } },
        })
        .then(({ data }) => data.manuallyVerifyUser)
        .catch(() => {
            // so that authSafe() can catch this error and try again with a different username
            const e = new Error("UserNotFoundException");
            e.name = "UserNotFoundException";
            throw e;
        });
};

export const callGetIntercomUserHash = async (platform: PlatformEnum) => {
    return await client
        .query({
            query: getIntercomUserHash,
            variables: { input: { platform } },
        })
        .then(({ data }) => data.getIntercomUserHash);
};

export const isOrgAdmin = ({
    user,
    organization,
}: {
    user: UserDetails | null | undefined;
    organization: Organization | null | undefined;
}) => {
    if (!user || !organization) return false;

    return organization.admins?.find(admin => admin.userId === user.ID)?.schoolId === "ALL";
};
