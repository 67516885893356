import { platform } from "@/platform";
import { TIME, today } from "@/utils/dateTimeUtils";
import { UserDetails } from "@knowt/syncing/graphql/schema";
import { fetchAuthSession } from "aws-amplify/auth";
import { watchAd as watchAdMutation } from "@/graphql/mutations";
import { callUpdateUserDetails, callUpdateUsername } from "./graphqlUtils";
import { LocalAuthUser } from "./types";
import { client } from "@/utils/client/graphql";
import { handleMaybeProgressing } from "../gamification/monitoring/progressing";
import { KNOWT_GAMIFICATION_STORAGE } from "../gamification/constants";
import dayjs from "dayjs";
import { safeLocalMutate, StableSWRKeys } from "../swr/swr";

export const amplifyAuthOverride = {
    API: {
        GraphQL: {
            headers: async () => {
                const idToken = (await fetchAuthSession()).tokens?.idToken?.toString();

                if (!idToken) return {};
                return {
                    Authorization: idToken,
                };
            },
        },
    },
};

export const updateUserUsername = async (_username: string) => {
    const username = _username.toLowerCase();
    const updatedUser = await callUpdateUsername(username);

    await safeLocalMutate(StableSWRKeys.USER, oldLocalUser => ({
        ...oldLocalUser,
        user: {
            ...updatedUser,
            signInType: updatedUser.signInType ?? oldLocalUser.user.signInType,
        },
    }));

    return updatedUser;
};

export const updateUserInfo = async (updateInfo: Partial<UserDetails>, optimistic = false) => {
    if (optimistic) {
        await safeLocalMutate(StableSWRKeys.USER, async (oldData: LocalAuthUser) => ({
            ...oldData,
            user: {
                ...oldData.user,
                ...updateInfo,
            },
        }));
    }

    const v2Response = await callUpdateUserDetails({ ...updateInfo }).catch(async error => {
        const { report } = await platform.analytics.logging();
        report(error, "updateUserDetails", { updateInfo });
        throw error;
    });

    await handleMaybeProgressing(v2Response);

    const user = v2Response.item;

    if (!optimistic) {
        await safeLocalMutate(StableSWRKeys.USER, async (oldData: LocalAuthUser) => ({
            ...oldData,
            user: {
                ...user,
                signInType: user.signInType ?? oldData.user.signInType,
            },
        }));
    }

    return user;
};

export const isUnderAge = (dateOfBirth: string | Date | null | undefined, age: number) => {
    if (!dateOfBirth) return false;

    const curr = new Date();
    const date = dateOfBirth instanceof Date ? dateOfBirth : new Date(dateOfBirth);
    const diff = curr.getTime() - date.getTime();
    return diff < age * TIME.YEAR;
};

export const watchAd = async () => {
    const { data } = await client.mutate({
        mutation: watchAdMutation,
        variables: {
            input: {},
        },
    });

    await handleMaybeProgressing(data.watchAd);

    return null;
};

export const getPersonalRecords = (user: UserDetails | null | undefined) => {
    if (!user) return [];

    const splitValueAndDate = (item?: string) => {
        if (!item) return { value: 0, date: today(undefined).format("MM/DD/YYYY") };

        const [value, date] = item.split(":");

        return { value: Number(value), date: dayjs(date).format("MM/DD/YYYY") };
    };

    return [
        {
            title: "Badges in a day",
            value: splitValueAndDate(user.records?.badgesEarned).value,
            date: splitValueAndDate(user.records?.badgesEarned).date,
            image: `${KNOWT_GAMIFICATION_STORAGE}/badge-icon.png`,
        },
        {
            title: "Coins in a day",
            value: splitValueAndDate(user.records?.coinsEarned).value,
            date: splitValueAndDate(user.records?.coinsEarned).date,
            image: `${KNOWT_GAMIFICATION_STORAGE}/coin-icon.svg`,
        },

        {
            title: "Longest Streak",
            value: splitValueAndDate(user.records?.longestStreak).value,
            date: splitValueAndDate(user.records?.longestStreak).date,
            image: `${KNOWT_GAMIFICATION_STORAGE}/streak-icon.svg`,
        },
        {
            title: "XP in a day",
            value: splitValueAndDate(user.records?.xpEarned).value,
            date: splitValueAndDate(user.records?.xpEarned).date,
            image: `${KNOWT_GAMIFICATION_STORAGE}/xp-icon.svg`,
        },
    ];
};
