"use client";

import { ApplyStreakActionInput, StoreProductEnum } from "@/graphql/schema";
import { platform } from "@/platform";
import { callApplyStreakAction, callApplyStreakActionV2 } from "./activities/graphqlUtils";
import { LocalAuthUser } from "../user/types";
import { resolveDailyActivitySWRKey } from "./activities/utils";
import { mutate } from "swr";
import { getAccumulatedXPForLevel, getDeltaXPForLevel, getLevel } from "./constants";
import { useUserXP } from "./activities/useDailyActivity";
import { callActivateStoreProduct } from "./store/graphqlUtils";
import { safeLocalMutate, StableSWRKeys } from "../swr/swr";

export const showXPToast = async (xp: number) => {
    const toast = await platform.toast();

    toast.xpToast(xp);
};

export const activateStoreProduct = async (storeProductId: StoreProductEnum) => {
    const updatedUser = await callActivateStoreProduct({ storeProductId });

    const mixpanel = await platform.analytics.mixpanel();

    await mixpanel.track("Store Item - Activated", {
        storeProductId,
        xp: updatedUser?.xp,
        level: updatedUser?.level,
        streak: updatedUser?.streak,
        coins: updatedUser?.coins,
        gamificationRecords: updatedUser?.records,
    });

    await safeLocalMutate(StableSWRKeys.USER, (oldLocalUser: LocalAuthUser) => ({
        ...oldLocalUser,
        user: {
            ...updatedUser,
            signInType: updatedUser.signInType ?? oldLocalUser.user.signInType,
        },
    }));

    await mutate("storeProductHistory");

    if (storeProductId === StoreProductEnum.LONG_PAUSE) {
        await mutate(resolveDailyActivitySWRKey(updatedUser.ID));
    }
};

export const applyStreakAction = async (input: ApplyStreakActionInput) => {
    const updatedUser = await callApplyStreakAction(input);

    await safeLocalMutate(StableSWRKeys.USER, (oldLocalUser: LocalAuthUser) => ({
        ...oldLocalUser,
        user: {
            ...updatedUser,
            signInType: updatedUser.signInType ?? oldLocalUser.user.signInType,
        },
    }));

    // refetch daily activity to update the streak status
    await mutate(resolveDailyActivitySWRKey(updatedUser.ID));

    return updatedUser;
};

export const applyStreakActionV2 = async (input: ApplyStreakActionInput) => {
    const response = await callApplyStreakActionV2(input);
    const updatedUser = response?.user;

    await safeLocalMutate(StableSWRKeys.USER, (oldLocalUser: LocalAuthUser) => ({
        ...oldLocalUser,
        user: {
            ...updatedUser,
            signInType: updatedUser.signInType ?? oldLocalUser.user.signInType,
        },
    }));

    // refetch daily activity to update the streak status
    await mutate(resolveDailyActivitySWRKey(updatedUser.ID));

    return response;
};

export const useUserXPStats = (additionalPotentialXP?: number) => {
    const userXP = useUserXP();

    return useXPStats(userXP, additionalPotentialXP);
};

export const useXPStats = (xp: number, additionalPotentialXP?: number) => {
    const level = getLevel(xp);
    const nextLevel = (level ?? 0) + 1;

    const deltaXPForLevel = getDeltaXPForLevel(level ?? 0);

    const xpAccumulatedInThisLevel = getAccumulatedXPForLevel(level ?? 0, xp);

    const progressToNextLevel = (xpAccumulatedInThisLevel / deltaXPForLevel) * 100;

    const progressWithEarnedXpToNextLevel = additionalPotentialXP
        ? ((xpAccumulatedInThisLevel + additionalPotentialXP) / deltaXPForLevel) * 100
        : undefined;

    const circlePercentage = 1 / 9;

    const circleFilledPercentage = Math.max(
        0,
        Math.round((progressToNextLevel - 100 * (1 - circlePercentage)) / circlePercentage)
    );

    return {
        nextLevel,
        deltaXPForLevel,
        xpAccumulatedInThisLevel,
        progressToNextLevel,
        progressWithEarnedXpToNextLevel,
        circleFilledPercentage,
    };
};
