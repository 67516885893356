import { ServerClientWithCookies, client } from "@knowt/syncing/utils/client/graphql";
import { SubmitAICompletionInput } from "@knowt/syncing/graphql/schema";

import { submitAICompletion } from "@knowt/syncing/graphql/mutations";

export const callSubmitAICompletion = async (
    input: SubmitAICompletionInput,
    serverClient: ServerClientWithCookies = undefined
) => {
    const aiCompletion = await client
        .mutate({
            mutation: submitAICompletion,
            variables: { input },
            serverClient,
        })
        .then(result => result.data.submitAICompletion);

    return aiCompletion;
};
