type Listener<T> = (payload: T) => void;

class Event<T> {
    private listeners: Map<string, Listener<T>> = new Map();

    register(listener: Listener<T>) {
        const id = Math.random().toString(36).substr(2, 9);
        this.listeners.set(id, listener);

        return () => {
            this.listeners.delete(id);
        };
    }

    notify(payload: T) {
        const clonedListeners = Array.from(this.listeners.values());
        clonedListeners.forEach(listener => listener(payload));
    }
}

export default Event;
