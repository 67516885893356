import { thisDay, today } from "@knowt/syncing/utils/dateTimeUtils";
import dayjs from "dayjs";

export const getTodayString = (tz: string) => today(tz).format("YYYY-MM-DD");

export const isToday = (dateString: string | dayjs.Dayjs | null | undefined, tz: string | undefined) => {
    if (!dateString) return false;

    if (dayjs.isDayjs(dateString)) {
        dateString = dateString.format("YYYY-MM-DD");
    }

    return dateString === getTodayString(tz);
};

export const isTomorrow = (dateString: string | null | undefined, tz: string | undefined) => {
    if (!dateString) return false;

    const tomorrow = today(tz).add(1, "day").format("YYYY-MM-DD");
    return dateString === tomorrow;
};

export const isYesterday = (dateString: string | null | undefined, tz: string | undefined) => {
    if (!dateString) return false;

    const yesterday = today(tz).subtract(1, "day").format("YYYY-MM-DD");
    return dateString === yesterday;
};

export const isFuture = (dateString: string | null | undefined, tz: string | undefined) => {
    if (!dateString) return false;

    return dateString > getTodayString(tz);
};

export const getDaysUntil = (dateString: string | null | undefined, tz: string | undefined) => {
    if (!dateString) return 0;

    const selectedDay = thisDay(dateString, tz);

    const hoursDiff = selectedDay.diff(today(tz), "hour");
    return Math.ceil(hoursDiff / 24);
};
