"use client";

import { mutate } from "swr";
import { LocalUser } from "../user/types";

export enum StableSWRKeys {
    USER = "getCurrentUser",
}

export type KeyTypes = {
    [StableSWRKeys.USER]: LocalUser;
};

export // wrapper around swr `mutate` that does not mutate the cache unless it exists..
// if it does not exist, it triggers a revalidation instead.
// TODO: revalidations only happen if there is a fetcher associated with this key!!!
//  so revisit this hook and reconsider if it's needed.
// TODO(types): this says it returns any[], but it can be a record type too.
const safeLocalMutate = async (key, updater) => {
    let shouldRevalidate = false;
    const boundedMutate = (...args) => mutate(key, ...args);

    const maybeUpdatedData = await boundedMutate(
        async oldData => {
            if (!oldData) {
                shouldRevalidate = true;
                return;
            }

            if (typeof updater !== "function") {
                return updater;
            }

            return await updater(oldData);
        },
        { revalidate: false }
    );

    if (!shouldRevalidate) {
        return maybeUpdatedData;
    }

    return await boundedMutate();
};

export const getSWRValue = async (key: StableSWRKeys) => {
    type KeyType = KeyTypes[typeof key];
    return (await mutate(key, (oldData: KeyType) => oldData, { revalidate: false })) as KeyType;
};
