const TIMEZONES = {
    "asia/dili": {
        offset: 9,
        hem: "s",
        label: "(GMT+09:00) Asia - Dili",
        value: "asia/dili",
    },
    "asia/jayapura": {
        offset: 9,
        hem: "s",
        label: "(GMT+09:00) Asia - Jayapura",
        value: "asia/jayapura",
    },
    "asia/chita": {
        offset: 9,
        hem: "n",
        label: "(GMT+09:00) Asia - Chita",
        value: "asia/chita",
    },
    "asia/khandyga": {
        offset: 9,
        hem: "n",
        label: "(GMT+09:00) Asia - Khandyga",
        value: "asia/khandyga",
    },
    "asia/pyongyang": {
        offset: 9,
        hem: "n",
        label: "(GMT+09:00) Asia - Pyongyang",
        value: "asia/pyongyang",
    },
    "asia/seoul": {
        offset: 9,
        hem: "n",
        label: "(GMT+09:00) Asia - Seoul",
        value: "asia/seoul",
    },
    "asia/tokyo": {
        offset: 9,
        hem: "n",
        label: "(GMT+09:00) Asia - Tokyo",
        value: "asia/tokyo",
    },
    "pacific/palau": {
        offset: 9,
        hem: "n",
        label: "(GMT+09:00) Pacific - Palau",
        value: "pacific/palau",
    },
    "australia/adelaide": {
        offset: 9.5,
        hem: "s",
        dst: "04/05:03->10/04:02",
        label: "(GMT+09:30) Australia - Adelaide",
        value: "australia/adelaide",
    },
    "australia/broken_hill": {
        offset: 9.5,
        hem: "s",
        dst: "04/05:03->10/04:02",
        label: "(GMT+09:30) Australia - Broken Hill",
        value: "australia/broken_hill",
    },
    "australia/south": {
        offset: 9.5,
        hem: "s",
        dst: "04/05:03->10/04:02",
        label: "(GMT+09:30) Australia - South",
        value: "australia/south",
    },
    "australia/yancowinna": {
        offset: 9.5,
        hem: "s",
        dst: "04/05:03->10/04:02",
        label: "(GMT+09:30) Australia - Yancowinna",
        value: "australia/yancowinna",
    },
    "australia/darwin": {
        offset: 9.5,
        hem: "s",
        label: "(GMT+09:30) Australia - Darwin",
        value: "australia/darwin",
    },
    "australia/north": {
        offset: 9.5,
        hem: "s",
        label: "(GMT+09:30) Australia - North",
        value: "australia/north",
    },
    "antarctica/casey": {
        offset: 8,
        hem: "s",
        label: "(GMT+08:00) Antarctica - Casey",
        value: "antarctica/casey",
    },
    "asia/kuala_lumpur": {
        offset: 8,
        hem: "s",
        label: "(GMT+08:00) Asia - Kuala Lumpur",
        value: "asia/kuala_lumpur",
    },
    "asia/makassar": {
        offset: 8,
        hem: "s",
        label: "(GMT+08:00) Asia - Makassar",
        value: "asia/makassar",
    },
    "asia/singapore": {
        offset: 8,
        hem: "s",
        label: "(GMT+08:00) Asia - Singapore",
        value: "asia/singapore",
    },
    "australia/perth": {
        offset: 8,
        hem: "s",
        label: "(GMT+08:00) Australia - Perth",
        value: "australia/perth",
    },
    "australia/west": {
        offset: 8,
        hem: "s",
        label: "(GMT+08:00) Australia - West",
        value: "australia/west",
    },
    "asia/ulan_bator": {
        offset: 8,
        hem: "n",
        dst: "03/25:03->09/29:23",
        label: "(GMT+08:00) Asia - Ulan Bator",
        value: "asia/ulan_bator",
    },
    "asia/brunei": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Brunei",
        value: "asia/brunei",
    },
    "asia/choibalsan": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Choibalsan",
        value: "asia/choibalsan",
    },
    "asia/chongqing": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Chongqing",
        value: "asia/chongqing",
    },
    "asia/chungking": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Chungking",
        value: "asia/chungking",
    },
    "asia/harbin": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Harbin",
        value: "asia/harbin",
    },
    "asia/hong_kong": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Hong Kong",
        value: "asia/hong_kong",
    },
    "asia/irkutsk": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Irkutsk",
        value: "asia/irkutsk",
    },
    "asia/kuching": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Kuching",
        value: "asia/kuching",
    },
    "asia/macao": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Macao",
        value: "asia/macao",
    },
    "asia/macau": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Macau",
        value: "asia/macau",
    },
    "asia/manila": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Manila",
        value: "asia/manila",
    },
    "asia/shanghai": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Shanghai",
        value: "asia/shanghai",
    },
    "asia/taipei": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Taipei",
        value: "asia/taipei",
    },
    "asia/ujung_pandang": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Ujung Pandang",
        value: "asia/ujung_pandang",
    },
    "asia/ulaanbaatar": {
        offset: 8,
        hem: "n",
        label: "(GMT+08:00) Asia - Ulaanbaatar",
        value: "asia/ulaanbaatar",
    },
    "australia/eucla": {
        offset: 8.75,
        hem: "s",
        label: "(GMT+08:45) Australia - Eucla",
        value: "australia/eucla",
    },
    "antarctica/davis": {
        offset: 7,
        hem: "s",
        label: "(GMT+07:00) Antarctica - Davis",
        value: "antarctica/davis",
    },
    "asia/jakarta": {
        offset: 7,
        hem: "s",
        label: "(GMT+07:00) Asia - Jakarta",
        value: "asia/jakarta",
    },
    "indian/christmas": {
        offset: 7,
        hem: "s",
        label: "(GMT+07:00) Indian - Christmas",
        value: "indian/christmas",
    },
    "asia/bangkok": {
        offset: 7,
        hem: "n",
        label: "(GMT+07:00) Asia - Bangkok",
        value: "asia/bangkok",
    },
    "asia/barnaul": {
        offset: 7,
        hem: "n",
        label: "(GMT+07:00) Asia - Barnaul",
        value: "asia/barnaul",
    },
    "asia/ho_chi_minh": {
        offset: 7,
        hem: "n",
        label: "(GMT+07:00) Asia - Ho Chi Minh",
        value: "asia/ho_chi_minh",
    },
    "asia/hovd": {
        offset: 7,
        hem: "n",
        label: "(GMT+07:00) Asia - Hovd",
        value: "asia/hovd",
    },
    "asia/krasnoyarsk": {
        offset: 7,
        hem: "n",
        label: "(GMT+07:00) Asia - Krasnoyarsk",
        value: "asia/krasnoyarsk",
    },
    "asia/novokuznetsk": {
        offset: 7,
        hem: "n",
        label: "(GMT+07:00) Asia - Novokuznetsk",
        value: "asia/novokuznetsk",
    },
    "asia/novosibirsk": {
        offset: 7,
        hem: "n",
        label: "(GMT+07:00) Asia - Novosibirsk",
        value: "asia/novosibirsk",
    },
    "asia/phnom_penh": {
        offset: 7,
        hem: "n",
        label: "(GMT+07:00) Asia - Phnom Penh",
        value: "asia/phnom_penh",
    },
    "asia/pontianak": {
        offset: 7,
        hem: "n",
        label: "(GMT+07:00) Asia - Pontianak",
        value: "asia/pontianak",
    },
    "asia/saigon": {
        offset: 7,
        hem: "n",
        label: "(GMT+07:00) Asia - Saigon",
        value: "asia/saigon",
    },
    "asia/tomsk": {
        offset: 7,
        hem: "n",
        label: "(GMT+07:00) Asia - Tomsk",
        value: "asia/tomsk",
    },
    "asia/vientiane": {
        offset: 7,
        hem: "n",
        label: "(GMT+07:00) Asia - Vientiane",
        value: "asia/vientiane",
    },
    "antarctica/vostok": {
        offset: 6,
        hem: "s",
        label: "(GMT+06:00) Antarctica - Vostok",
        value: "antarctica/vostok",
    },
    "asia/almaty": {
        offset: 6,
        hem: "n",
        label: "(GMT+06:00) Asia - Almaty",
        value: "asia/almaty",
    },
    "asia/bishkek": {
        offset: 6,
        hem: "n",
        label: "(GMT+06:00) Asia - Bishkek",
        value: "asia/bishkek",
    },
    "asia/dacca": {
        offset: 6,
        hem: "n",
        label: "(GMT+06:00) Asia - Dacca",
        value: "asia/dacca",
    },
    "asia/dhaka": {
        offset: 6,
        hem: "n",
        label: "(GMT+06:00) Asia - Dhaka",
        value: "asia/dhaka",
    },
    "asia/kashgar": {
        offset: 6,
        hem: "n",
        label: "(GMT+06:00) Asia - Kashgar",
        value: "asia/kashgar",
    },
    "asia/omsk": {
        offset: 6,
        hem: "n",
        label: "(GMT+06:00) Asia - Omsk",
        value: "asia/omsk",
    },
    "asia/qyzylorda": {
        offset: 6,
        hem: "n",
        label: "(GMT+06:00) Asia - Qyzylorda",
        value: "asia/qyzylorda",
    },
    "asia/thimbu": {
        offset: 6,
        hem: "n",
        label: "(GMT+06:00) Asia - Thimbu",
        value: "asia/thimbu",
    },
    "asia/thimphu": {
        offset: 6,
        hem: "n",
        label: "(GMT+06:00) Asia - Thimphu",
        value: "asia/thimphu",
    },
    "asia/urumqi": {
        offset: 6,
        hem: "n",
        label: "(GMT+06:00) Asia - Urumqi",
        value: "asia/urumqi",
    },
    "indian/chagos": {
        offset: 6,
        hem: "n",
        label: "(GMT+06:00) Indian - Chagos",
        value: "indian/chagos",
    },
    "asia/rangoon": {
        offset: 6.5,
        hem: "n",
        label: "(GMT+06:30) Asia - Rangoon",
        value: "asia/rangoon",
    },
    "asia/yangon": {
        offset: 6.5,
        hem: "n",
        label: "(GMT+06:30) Asia - Yangon",
        value: "asia/yangon",
    },
    "indian/cocos": {
        offset: 6.5,
        hem: "n",
        label: "(GMT+06:30) Indian - Cocos",
        value: "indian/cocos",
    },
    "antarctica/mawson": {
        offset: 5,
        hem: "s",
        label: "(GMT+05:00) Antarctica - Mawson",
        value: "antarctica/mawson",
    },
    "indian/kerguelen": {
        offset: 5,
        hem: "s",
        label: "(GMT+05:00) Indian - Kerguelen",
        value: "indian/kerguelen",
    },
    "asia/aqtau": {
        offset: 5,
        hem: "n",
        label: "(GMT+05:00) Asia - Aqtau",
        value: "asia/aqtau",
    },
    "asia/aqtobe": {
        offset: 5,
        hem: "n",
        label: "(GMT+05:00) Asia - Aqtobe",
        value: "asia/aqtobe",
    },
    "asia/ashgabat": {
        offset: 5,
        hem: "n",
        label: "(GMT+05:00) Asia - Ashgabat",
        value: "asia/ashgabat",
    },
    "asia/ashkhabad": {
        offset: 5,
        hem: "n",
        label: "(GMT+05:00) Asia - Ashkhabad",
        value: "asia/ashkhabad",
    },
    "asia/atyrau": {
        offset: 5,
        hem: "n",
        label: "(GMT+05:00) Asia - Atyrau",
        value: "asia/atyrau",
    },
    "asia/baku": {
        offset: 5,
        hem: "n",
        label: "(GMT+05:00) Asia - Baku",
        value: "asia/baku",
    },
    "asia/dushanbe": {
        offset: 5,
        hem: "n",
        label: "(GMT+05:00) Asia - Dushanbe",
        value: "asia/dushanbe",
    },
    "asia/karachi": {
        offset: 5,
        hem: "n",
        label: "(GMT+05:00) Asia - Karachi",
        value: "asia/karachi",
    },
    "asia/oral": {
        offset: 5,
        hem: "n",
        label: "(GMT+05:00) Asia - Oral",
        value: "asia/oral",
    },
    "asia/samarkand": {
        offset: 5,
        hem: "n",
        label: "(GMT+05:00) Asia - Samarkand",
        value: "asia/samarkand",
    },
    "asia/tashkent": {
        offset: 5,
        hem: "n",
        label: "(GMT+05:00) Asia - Tashkent",
        value: "asia/tashkent",
    },
    "asia/yekaterinburg": {
        offset: 5,
        hem: "n",
        label: "(GMT+05:00) Asia - Yekaterinburg",
        value: "asia/yekaterinburg",
    },
    "indian/maldives": {
        offset: 5,
        hem: "n",
        label: "(GMT+05:00) Indian - Maldives",
        value: "indian/maldives",
    },
    "asia/kathmandu": {
        offset: 5.75,
        hem: "n",
        label: "(GMT+05:45) Asia - Kathmandu",
        value: "asia/kathmandu",
    },
    "asia/katmandu": {
        offset: 5.75,
        hem: "n",
        label: "(GMT+05:45) Asia - Katmandu",
        value: "asia/katmandu",
    },
    "asia/calcutta": {
        offset: 5.5,
        hem: "n",
        label: "(GMT+05:30) Asia - Calcutta",
        value: "asia/calcutta",
    },
    "asia/colombo": {
        offset: 5.5,
        hem: "n",
        label: "(GMT+05:30) Asia - Colombo",
        value: "asia/colombo",
    },
    "asia/kolkata": {
        offset: 5.5,
        hem: "n",
        label: "(GMT+05:30) Asia - Kolkata",
        value: "asia/kolkata",
    },
    "indian/reunion": {
        offset: 4,
        hem: "s",
        label: "(GMT+04:00) Indian - Reunion",
        value: "indian/reunion",
    },
    "asia/dubai": {
        offset: 4,
        hem: "n",
        label: "(GMT+04:00) Asia - Dubai",
        value: "asia/dubai",
    },
    "asia/muscat": {
        offset: 4,
        hem: "n",
        label: "(GMT+04:00) Asia - Muscat",
        value: "asia/muscat",
    },
    "asia/tbilisi": {
        offset: 4,
        hem: "n",
        label: "(GMT+04:00) Asia - Tbilisi",
        value: "asia/tbilisi",
    },
    "asia/yerevan": {
        offset: 4,
        hem: "n",
        label: "(GMT+04:00) Asia - Yerevan",
        value: "asia/yerevan",
    },
    "europe/astrakhan": {
        offset: 4,
        hem: "n",
        label: "(GMT+04:00) Europe - Astrakhan",
        value: "europe/astrakhan",
    },
    "europe/samara": {
        offset: 4,
        hem: "n",
        label: "(GMT+04:00) Europe - Samara",
        value: "europe/samara",
    },
    "europe/saratov": {
        offset: 4,
        hem: "n",
        label: "(GMT+04:00) Europe - Saratov",
        value: "europe/saratov",
    },
    "europe/ulyanovsk": {
        offset: 4,
        hem: "n",
        label: "(GMT+04:00) Europe - Ulyanovsk",
        value: "europe/ulyanovsk",
    },
    "europe/volgograd": {
        offset: 4,
        hem: "n",
        label: "(GMT+04:00) Europe - Volgograd",
        value: "europe/volgograd",
    },
    "asia/volgograd": {
        offset: 4,
        hem: "n",
        label: "(GMT+04:00) Asia - Volgograd",
        value: "asia/volgograd",
    },
    "indian/mahe": {
        offset: 4,
        hem: "n",
        label: "(GMT+04:00) Indian - Mahe",
        value: "indian/mahe",
    },
    "indian/mauritius": {
        offset: 4,
        hem: "n",
        label: "(GMT+04:00) Indian - Mauritius",
        value: "indian/mauritius",
    },
    "asia/tehran": {
        offset: 4.5,
        hem: "n",
        dst: "03/21:00->09/20:24",
        label: "(GMT+04:30) Asia - Tehran",
        value: "asia/tehran",
    },
    "asia/kabul": {
        offset: 4.5,
        hem: "n",
        label: "(GMT+04:30) Asia - Kabul",
        value: "asia/kabul",
    },
    "antarctica/syowa": {
        offset: 3,
        hem: "s",
        label: "(GMT+03:00) Antarctica - Syowa",
        value: "antarctica/syowa",
    },
    "indian/antananarivo": {
        offset: 3,
        hem: "s",
        label: "(GMT+03:00) Indian - Antananarivo",
        value: "indian/antananarivo",
    },
    "asia/famagusta": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Asia - Famagusta",
        value: "asia/famagusta",
    },
    "asia/nicosia": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Asia - Nicosia",
        value: "asia/nicosia",
    },
    "europe/athens": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Europe - Athens",
        value: "europe/athens",
    },
    "europe/bucharest": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Europe - Bucharest",
        value: "europe/bucharest",
    },
    "europe/helsinki": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Europe - Helsinki",
        value: "europe/helsinki",
    },
    "europe/kiev": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Europe - Kiev",
        value: "europe/kiev",
    },
    "europe/mariehamn": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Europe - Mariehamn",
        value: "europe/mariehamn",
    },
    "europe/nicosia": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Europe - Nicosia",
        value: "europe/nicosia",
    },
    "europe/riga": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Europe - Riga",
        value: "europe/riga",
    },
    "europe/sofia": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Europe - Sofia",
        value: "europe/sofia",
    },
    "europe/tallinn": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Europe - Tallinn",
        value: "europe/tallinn",
    },
    "europe/uzhgorod": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Europe - Uzhgorod",
        value: "europe/uzhgorod",
    },
    "europe/vilnius": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Europe - Vilnius",
        value: "europe/vilnius",
    },
    "europe/zaporozhye": {
        offset: 3,
        hem: "n",
        dst: "03/29:03->10/25:04",
        label: "(GMT+03:00) Europe - Zaporozhye",
        value: "europe/zaporozhye",
    },
    "europe/chisinau": {
        offset: 3,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+03:00) Europe - Chisinau",
        value: "europe/chisinau",
    },
    "europe/tiraspol": {
        offset: 3,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+03:00) Europe - Tiraspol",
        value: "europe/tiraspol",
    },
    "asia/beirut": {
        offset: 3,
        hem: "n",
        dst: "03/29:00->10/24:24",
        label: "(GMT+03:00) Asia - Beirut",
        value: "asia/beirut",
    },
    "asia/jerusalem": {
        offset: 3,
        hem: "n",
        dst: "03/27:02->10/25:02",
        label: "(GMT+03:00) Asia - Jerusalem",
        value: "asia/jerusalem",
    },
    "asia/tel_aviv": {
        offset: 3,
        hem: "n",
        dst: "03/27:02->10/25:02",
        label: "(GMT+03:00) Asia - Tel Aviv",
        value: "asia/tel_aviv",
    },
    "asia/gaza": {
        offset: 3,
        hem: "n",
        dst: "03/27:00->10/31:01",
        label: "(GMT+03:00) Asia - Gaza",
        value: "asia/gaza",
    },
    "asia/hebron": {
        offset: 3,
        hem: "n",
        dst: "03/27:00->10/31:01",
        label: "(GMT+03:00) Asia - Hebron",
        value: "asia/hebron",
    },
    "asia/amman": {
        offset: 3,
        hem: "n",
        dst: "03/27:00->10/30:01",
        label: "(GMT+03:00) Asia - Amman",
        value: "asia/amman",
    },
    "asia/damascus": {
        offset: 3,
        hem: "n",
        dst: "03/27:00->10/29:24",
        label: "(GMT+03:00) Asia - Damascus",
        value: "asia/damascus",
    },
    "africa/addis_ababa": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Africa - Addis Ababa",
        value: "africa/addis_ababa",
    },
    "africa/asmara": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Africa - Asmara",
        value: "africa/asmara",
    },
    "africa/asmera": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Africa - Asmera",
        value: "africa/asmera",
    },
    "africa/dar_es_salaam": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Africa - Dar Es Salaam",
        value: "africa/dar_es_salaam",
    },
    "africa/djibouti": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Africa - Djibouti",
        value: "africa/djibouti",
    },
    "africa/juba": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Africa - Juba",
        value: "africa/juba",
    },
    "africa/kampala": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Africa - Kampala",
        value: "africa/kampala",
    },
    "africa/mogadishu": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Africa - Mogadishu",
        value: "africa/mogadishu",
    },
    "africa/nairobi": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Africa - Nairobi",
        value: "africa/nairobi",
    },
    "asia/aden": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Asia - Aden",
        value: "asia/aden",
    },
    "asia/baghdad": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Asia - Baghdad",
        value: "asia/baghdad",
    },
    "asia/bahrain": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Asia - Bahrain",
        value: "asia/bahrain",
    },
    "asia/istanbul": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Asia - Istanbul",
        value: "asia/istanbul",
    },
    "asia/kuwait": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Asia - Kuwait",
        value: "asia/kuwait",
    },
    "asia/qatar": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Asia - Qatar",
        value: "asia/qatar",
    },
    "asia/riyadh": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Asia - Riyadh",
        value: "asia/riyadh",
    },
    "europe/istanbul": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Europe - Istanbul",
        value: "europe/istanbul",
    },
    "europe/kirov": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Europe - Kirov",
        value: "europe/kirov",
    },
    "europe/minsk": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Europe - Minsk",
        value: "europe/minsk",
    },
    "europe/moscow": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Europe - Moscow",
        value: "europe/moscow",
    },
    "europe/simferopol": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Europe - Simferopol",
        value: "europe/simferopol",
    },
    "indian/comoro": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Indian - Comoro",
        value: "indian/comoro",
    },
    "indian/mayotte": {
        offset: 3,
        hem: "n",
        label: "(GMT+03:00) Indian - Mayotte",
        value: "indian/mayotte",
    },
    "antarctica/troll": {
        offset: 2,
        hem: "s",
        dst: "03/29:02->10/25:02",
        label: "(GMT+02:00) Antarctica - Troll",
        value: "antarctica/troll",
    },
    "africa/gaborone": {
        offset: 2,
        hem: "s",
        label: "(GMT+02:00) Africa - Gaborone",
        value: "africa/gaborone",
    },
    "africa/harare": {
        offset: 2,
        hem: "s",
        label: "(GMT+02:00) Africa - Harare",
        value: "africa/harare",
    },
    "africa/johannesburg": {
        offset: 2,
        hem: "s",
        label: "(GMT+02:00) Africa - Johannesburg",
        value: "africa/johannesburg",
    },
    "africa/lubumbashi": {
        offset: 2,
        hem: "s",
        label: "(GMT+02:00) Africa - Lubumbashi",
        value: "africa/lubumbashi",
    },
    "africa/lusaka": {
        offset: 2,
        hem: "s",
        label: "(GMT+02:00) Africa - Lusaka",
        value: "africa/lusaka",
    },
    "africa/maputo": {
        offset: 2,
        hem: "s",
        label: "(GMT+02:00) Africa - Maputo",
        value: "africa/maputo",
    },
    "africa/maseru": {
        offset: 2,
        hem: "s",
        label: "(GMT+02:00) Africa - Maseru",
        value: "africa/maseru",
    },
    "africa/mbabane": {
        offset: 2,
        hem: "s",
        label: "(GMT+02:00) Africa - Mbabane",
        value: "africa/mbabane",
    },
    "africa/ceuta": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Africa - Ceuta",
        value: "africa/ceuta",
    },
    "arctic/longyearbyen": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Arctic - Longyearbyen",
        value: "arctic/longyearbyen",
    },
    "atlantic/jan_mayen": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Atlantic - Jan Mayen",
        value: "atlantic/jan_mayen",
    },
    "europe/amsterdam": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Amsterdam",
        value: "europe/amsterdam",
    },
    "europe/andorra": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Andorra",
        value: "europe/andorra",
    },
    "europe/belgrade": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Belgrade",
        value: "europe/belgrade",
    },
    "europe/berlin": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Berlin",
        value: "europe/berlin",
    },
    "europe/bratislava": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Bratislava",
        value: "europe/bratislava",
    },
    "europe/brussels": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Brussels",
        value: "europe/brussels",
    },
    "europe/budapest": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Budapest",
        value: "europe/budapest",
    },
    "europe/busingen": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Busingen",
        value: "europe/busingen",
    },
    "europe/copenhagen": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Copenhagen",
        value: "europe/copenhagen",
    },
    "europe/gibraltar": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Gibraltar",
        value: "europe/gibraltar",
    },
    "europe/ljubljana": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Ljubljana",
        value: "europe/ljubljana",
    },
    "europe/luxembourg": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Luxembourg",
        value: "europe/luxembourg",
    },
    "europe/madrid": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Madrid",
        value: "europe/madrid",
    },
    "europe/malta": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Malta",
        value: "europe/malta",
    },
    "europe/monaco": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Monaco",
        value: "europe/monaco",
    },
    "europe/oslo": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Oslo",
        value: "europe/oslo",
    },
    "europe/paris": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Paris",
        value: "europe/paris",
    },
    "europe/podgorica": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Podgorica",
        value: "europe/podgorica",
    },
    "europe/prague": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Prague",
        value: "europe/prague",
    },
    "europe/rome": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Rome",
        value: "europe/rome",
    },
    "europe/san_marino": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - San Marino",
        value: "europe/san_marino",
    },
    "europe/sarajevo": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Sarajevo",
        value: "europe/sarajevo",
    },
    "europe/skopje": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Skopje",
        value: "europe/skopje",
    },
    "europe/stockholm": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Stockholm",
        value: "europe/stockholm",
    },
    "europe/tirane": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Tirane",
        value: "europe/tirane",
    },
    "europe/vaduz": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Vaduz",
        value: "europe/vaduz",
    },
    "europe/vatican": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Vatican",
        value: "europe/vatican",
    },
    "europe/vienna": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Vienna",
        value: "europe/vienna",
    },
    "europe/warsaw": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Warsaw",
        value: "europe/warsaw",
    },
    "europe/zagreb": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Zagreb",
        value: "europe/zagreb",
    },
    "europe/zurich": {
        offset: 2,
        hem: "n",
        dst: "03/29:02->10/25:03",
        label: "(GMT+02:00) Europe - Zurich",
        value: "europe/zurich",
    },
    "africa/blantyre": {
        offset: 2,
        hem: "n",
        label: "(GMT+02:00) Africa - Blantyre",
        value: "africa/blantyre",
    },
    "africa/bujumbura": {
        offset: 2,
        hem: "n",
        label: "(GMT+02:00) Africa - Bujumbura",
        value: "africa/bujumbura",
    },
    "africa/cairo": {
        offset: 2,
        hem: "n",
        label: "(GMT+02:00) Africa - Cairo",
        value: "africa/cairo",
    },
    "africa/khartoum": {
        offset: 2,
        hem: "n",
        label: "(GMT+02:00) Africa - Khartoum",
        value: "africa/khartoum",
    },
    "africa/kigali": {
        offset: 2,
        hem: "n",
        label: "(GMT+02:00) Africa - Kigali",
        value: "africa/kigali",
    },
    "africa/tripoli": {
        offset: 2,
        hem: "n",
        label: "(GMT+02:00) Africa - Tripoli",
        value: "africa/tripoli",
    },
    "europe/kaliningrad": {
        offset: 2,
        hem: "n",
        label: "(GMT+02:00) Europe - Kaliningrad",
        value: "europe/kaliningrad",
    },
    "africa/windhoek": {
        offset: 1,
        hem: "s",
        dst: "04/02:01->09/03:03",
        label: "(GMT+01:00) Africa - Windhoek",
        value: "africa/windhoek",
    },
    "africa/kinshasa": {
        offset: 1,
        hem: "s",
        label: "(GMT+01:00) Africa - Kinshasa",
        value: "africa/kinshasa",
    },
    "africa/luanda": {
        offset: 1,
        hem: "s",
        label: "(GMT+01:00) Africa - Luanda",
        value: "africa/luanda",
    },
    "africa/casablanca": {
        offset: 1,
        hem: "n",
        dst: "04/19:03->05/31:02",
        label: "(GMT+01:00) Africa - Casablanca",
        value: "africa/casablanca",
    },
    "africa/el_aaiun": {
        offset: 1,
        hem: "n",
        dst: "04/19:03->05/31:02",
        label: "(GMT+01:00) Africa - El Aaiun",
        value: "africa/el_aaiun",
    },
    "atlantic/canary": {
        offset: 1,
        hem: "n",
        dst: "03/29:01->10/25:02",
        label: "(GMT+01:00) Atlantic - Canary",
        value: "atlantic/canary",
    },
    "atlantic/faeroe": {
        offset: 1,
        hem: "n",
        dst: "03/29:01->10/25:02",
        label: "(GMT+01:00) Atlantic - Faeroe",
        value: "atlantic/faeroe",
    },
    "atlantic/faroe": {
        offset: 1,
        hem: "n",
        dst: "03/29:01->10/25:02",
        label: "(GMT+01:00) Atlantic - Faroe",
        value: "atlantic/faroe",
    },
    "atlantic/madeira": {
        offset: 1,
        hem: "n",
        dst: "03/29:01->10/25:02",
        label: "(GMT+01:00) Atlantic - Madeira",
        value: "atlantic/madeira",
    },
    "europe/belfast": {
        offset: 1,
        hem: "n",
        dst: "03/29:01->10/25:02",
        label: "(GMT+01:00) Europe - Belfast",
        value: "europe/belfast",
    },
    "europe/dublin": {
        offset: 1,
        hem: "n",
        dst: "03/29:01->10/25:02",
        label: "(GMT+01:00) Europe - Dublin",
        value: "europe/dublin",
    },
    "europe/guernsey": {
        offset: 1,
        hem: "n",
        dst: "03/29:01->10/25:02",
        label: "(GMT+01:00) Europe - Guernsey",
        value: "europe/guernsey",
    },
    "europe/isle_of_man": {
        offset: 1,
        hem: "n",
        dst: "03/29:01->10/25:02",
        label: "(GMT+01:00) Europe - Isle Of Man",
        value: "europe/isle_of_man",
    },
    "europe/jersey": {
        offset: 1,
        hem: "n",
        dst: "03/29:01->10/25:02",
        label: "(GMT+01:00) Europe - Jersey",
        value: "europe/jersey",
    },
    "europe/lisbon": {
        offset: 1,
        hem: "n",
        dst: "03/29:01->10/25:02",
        label: "(GMT+01:00) Europe - Lisbon",
        value: "europe/lisbon",
    },
    "europe/london": {
        offset: 1,
        hem: "n",
        dst: "03/29:01->10/25:02",
        label: "(GMT+01:00) Europe - London",
        value: "europe/london",
    },
    "africa/algiers": {
        offset: 1,
        hem: "n",
        label: "(GMT+01:00) Africa - Algiers",
        value: "africa/algiers",
    },
    "africa/bangui": {
        offset: 1,
        hem: "n",
        label: "(GMT+01:00) Africa - Bangui",
        value: "africa/bangui",
    },
    "africa/brazzaville": {
        offset: 1,
        hem: "n",
        label: "(GMT+01:00) Africa - Brazzaville",
        value: "africa/brazzaville",
    },
    "africa/douala": {
        offset: 1,
        hem: "n",
        label: "(GMT+01:00) Africa - Douala",
        value: "africa/douala",
    },
    "africa/lagos": {
        offset: 1,
        hem: "n",
        label: "(GMT+01:00) Africa - Lagos",
        value: "africa/lagos",
    },
    "africa/libreville": {
        offset: 1,
        hem: "n",
        label: "(GMT+01:00) Africa - Libreville",
        value: "africa/libreville",
    },
    "africa/malabo": {
        offset: 1,
        hem: "n",
        label: "(GMT+01:00) Africa - Malabo",
        value: "africa/malabo",
    },
    "africa/ndjamena": {
        offset: 1,
        hem: "n",
        label: "(GMT+01:00) Africa - Ndjamena",
        value: "africa/ndjamena",
    },
    "africa/niamey": {
        offset: 1,
        hem: "n",
        label: "(GMT+01:00) Africa - Niamey",
        value: "africa/niamey",
    },
    "africa/porto-novo": {
        offset: 1,
        hem: "n",
        label: "(GMT+01:00) Africa - Porto-novo",
        value: "africa/porto-novo",
    },
    "africa/tunis": {
        offset: 1,
        hem: "n",
        label: "(GMT+01:00) Africa - Tunis",
        value: "africa/tunis",
    },
    "pacific/kiritimati": {
        offset: 14,
        hem: "n",
        label: "(GMT+14:00) Pacific - Kiritimati",
        value: "pacific/kiritimati",
    },
    "pacific/apia": {
        offset: 13,
        hem: "s",
        dst: "04/05:04->09/27:03",
        label: "(GMT+13:00) Pacific - Apia",
        value: "pacific/apia",
    },
    "pacific/tongatapu": {
        offset: 13,
        hem: "s",
        dst: "01/15:02->11/05:03",
        label: "(GMT+13:00) Pacific - Tongatapu",
        value: "pacific/tongatapu",
    },
    "pacific/enderbury": {
        offset: 13,
        hem: "n",
        label: "(GMT+13:00) Pacific - Enderbury",
        value: "pacific/enderbury",
    },
    "pacific/fakaofo": {
        offset: 13,
        hem: "n",
        label: "(GMT+13:00) Pacific - Fakaofo",
        value: "pacific/fakaofo",
    },
    "antarctica/mcmurdo": {
        offset: 12,
        hem: "s",
        dst: "04/05:03->09/27:02",
        label: "(GMT+12:00) Antarctica - Mcmurdo",
        value: "antarctica/mcmurdo",
    },
    "antarctica/south_pole": {
        offset: 12,
        hem: "s",
        dst: "04/05:03->09/27:02",
        label: "(GMT+12:00) Antarctica - South Pole",
        value: "antarctica/south_pole",
    },
    "pacific/auckland": {
        offset: 12,
        hem: "s",
        dst: "04/05:03->09/27:02",
        label: "(GMT+12:00) Pacific - Auckland",
        value: "pacific/auckland",
    },
    "pacific/fiji": {
        offset: 12,
        hem: "s",
        dst: "01/12:03->11/08:02",
        label: "(GMT+12:00) Pacific - Fiji",
        value: "pacific/fiji",
    },
    "asia/anadyr": {
        offset: 12,
        hem: "n",
        label: "(GMT+12:00) Asia - Anadyr",
        value: "asia/anadyr",
    },
    "asia/kamchatka": {
        offset: 12,
        hem: "n",
        label: "(GMT+12:00) Asia - Kamchatka",
        value: "asia/kamchatka",
    },
    "asia/srednekolymsk": {
        offset: 12,
        hem: "n",
        label: "(GMT+12:00) Asia - Srednekolymsk",
        value: "asia/srednekolymsk",
    },
    "pacific/funafuti": {
        offset: 12,
        hem: "n",
        label: "(GMT+12:00) Pacific - Funafuti",
        value: "pacific/funafuti",
    },
    "pacific/kwajalein": {
        offset: 12,
        hem: "n",
        label: "(GMT+12:00) Pacific - Kwajalein",
        value: "pacific/kwajalein",
    },
    "pacific/majuro": {
        offset: 12,
        hem: "n",
        label: "(GMT+12:00) Pacific - Majuro",
        value: "pacific/majuro",
    },
    "pacific/nauru": {
        offset: 12,
        hem: "n",
        label: "(GMT+12:00) Pacific - Nauru",
        value: "pacific/nauru",
    },
    "pacific/tarawa": {
        offset: 12,
        hem: "n",
        label: "(GMT+12:00) Pacific - Tarawa",
        value: "pacific/tarawa",
    },
    "pacific/wake": {
        offset: 12,
        hem: "n",
        label: "(GMT+12:00) Pacific - Wake",
        value: "pacific/wake",
    },
    "pacific/wallis": {
        offset: 12,
        hem: "n",
        label: "(GMT+12:00) Pacific - Wallis",
        value: "pacific/wallis",
    },
    "pacific/chatham": {
        offset: 12.75,
        hem: "s",
        dst: "04/05:03->04/05:02",
        label: "(GMT+12:45) Pacific - Chatham",
        value: "pacific/chatham",
    },
    "antarctica/macquarie": {
        offset: 11,
        hem: "s",
        label: "(GMT+11:00) Antarctica - Macquarie",
        value: "antarctica/macquarie",
    },
    "pacific/bougainville": {
        offset: 11,
        hem: "s",
        label: "(GMT+11:00) Pacific - Bougainville",
        value: "pacific/bougainville",
    },
    "asia/magadan": {
        offset: 11,
        hem: "n",
        label: "(GMT+11:00) Asia - Magadan",
        value: "asia/magadan",
    },
    "asia/sakhalin": {
        offset: 11,
        hem: "n",
        label: "(GMT+11:00) Asia - Sakhalin",
        value: "asia/sakhalin",
    },
    "pacific/efate": {
        offset: 11,
        hem: "n",
        label: "(GMT+11:00) Pacific - Efate",
        value: "pacific/efate",
    },
    "pacific/guadalcanal": {
        offset: 11,
        hem: "n",
        label: "(GMT+11:00) Pacific - Guadalcanal",
        value: "pacific/guadalcanal",
    },
    "pacific/kosrae": {
        offset: 11,
        hem: "n",
        label: "(GMT+11:00) Pacific - Kosrae",
        value: "pacific/kosrae",
    },
    "pacific/noumea": {
        offset: 11,
        hem: "n",
        label: "(GMT+11:00) Pacific - Noumea",
        value: "pacific/noumea",
    },
    "pacific/pohnpei": {
        offset: 11,
        hem: "n",
        label: "(GMT+11:00) Pacific - Pohnpei",
        value: "pacific/pohnpei",
    },
    "pacific/ponape": {
        offset: 11,
        hem: "n",
        label: "(GMT+11:00) Pacific - Ponape",
        value: "pacific/ponape",
    },
    "pacific/norfolk": {
        offset: 11.5,
        hem: "n",
        dst: "04/05:03->10/04:02",
        label: "(GMT+11:30) Pacific - Norfolk",
        value: "pacific/norfolk",
    },
    "australia/act": {
        offset: 10,
        hem: "s",
        dst: "04/05:03->10/04:02",
        label: "(GMT+10:00) Australia - Act",
        value: "australia/act",
    },
    "australia/canberra": {
        offset: 10,
        hem: "s",
        dst: "04/05:03->10/04:02",
        label: "(GMT+10:00) Australia - Canberra",
        value: "australia/canberra",
    },
    "australia/currie": {
        offset: 10,
        hem: "s",
        dst: "04/05:03->10/04:02",
        label: "(GMT+10:00) Australia - Currie",
        value: "australia/currie",
    },
    "australia/hobart": {
        offset: 10,
        hem: "s",
        dst: "04/05:03->10/04:02",
        label: "(GMT+10:00) Australia - Hobart",
        value: "australia/hobart",
    },
    "australia/melbourne": {
        offset: 10,
        hem: "s",
        dst: "04/05:03->10/04:02",
        label: "(GMT+10:00) Australia - Melbourne",
        value: "australia/melbourne",
    },
    "australia/nsw": {
        offset: 10,
        hem: "s",
        dst: "04/05:03->10/04:02",
        label: "(GMT+10:00) Australia - Nsw",
        value: "australia/nsw",
    },
    "australia/sydney": {
        offset: 10,
        hem: "s",
        dst: "04/05:03->10/04:02",
        label: "(GMT+10:00) Australia - Sydney",
        value: "australia/sydney",
    },
    "australia/tasmania": {
        offset: 10,
        hem: "s",
        dst: "04/05:03->10/04:02",
        label: "(GMT+10:00) Australia - Tasmania",
        value: "australia/tasmania",
    },
    "australia/victoria": {
        offset: 10,
        hem: "s",
        dst: "04/05:03->10/04:02",
        label: "(GMT+10:00) Australia - Victoria",
        value: "australia/victoria",
    },
    "antarctica/dumontdurville": {
        offset: 10,
        hem: "s",
        label: "(GMT+10:00) Antarctica - Dumontdurville",
        value: "antarctica/dumontdurville",
    },
    "australia/brisbane": {
        offset: 10,
        hem: "s",
        label: "(GMT+10:00) Australia - Brisbane",
        value: "australia/brisbane",
    },
    "australia/lindeman": {
        offset: 10,
        hem: "s",
        label: "(GMT+10:00) Australia - Lindeman",
        value: "australia/lindeman",
    },
    "australia/queensland": {
        offset: 10,
        hem: "s",
        label: "(GMT+10:00) Australia - Queensland",
        value: "australia/queensland",
    },
    "asia/ust-nera": {
        offset: 10,
        hem: "n",
        label: "(GMT+10:00) Asia - Ust-nera",
        value: "asia/ust-nera",
    },
    "asia/vladivostok": {
        offset: 10,
        hem: "n",
        label: "(GMT+10:00) Asia - Vladivostok",
        value: "asia/vladivostok",
    },
    "asia/yakutsk": {
        offset: 10,
        hem: "n",
        label: "(GMT+10:00) Asia - Yakutsk",
        value: "asia/yakutsk",
    },
    "pacific/chuuk": {
        offset: 10,
        hem: "n",
        label: "(GMT+10:00) Pacific - Chuuk",
        value: "pacific/chuuk",
    },
    "pacific/guam": {
        offset: 10,
        hem: "n",
        label: "(GMT+10:00) Pacific - Guam",
        value: "pacific/guam",
    },
    "pacific/port_moresby": {
        offset: 10,
        hem: "n",
        label: "(GMT+10:00) Pacific - Port Moresby",
        value: "pacific/port_moresby",
    },
    "pacific/saipan": {
        offset: 10,
        hem: "n",
        label: "(GMT+10:00) Pacific - Saipan",
        value: "pacific/saipan",
    },
    "pacific/truk": {
        offset: 10,
        hem: "n",
        label: "(GMT+10:00) Pacific - Truk",
        value: "pacific/truk",
    },
    "pacific/yap": {
        offset: 10,
        hem: "n",
        label: "(GMT+10:00) Pacific - Yap",
        value: "pacific/yap",
    },
    "australia/lhi": {
        offset: 10.5,
        hem: "s",
        dst: "04/05:01->10/04:02",
        label: "(GMT+10:30) Australia - Lhi",
        value: "australia/lhi",
    },
    "australia/lord_howe": {
        offset: 10.5,
        hem: "s",
        dst: "04/05:01->10/04:02",
        label: "(GMT+10:30) Australia - Lord Howe",
        value: "australia/lord_howe",
    },
    "america/scoresbysund": {
        offset: 0,
        hem: "n",
        dst: "03/29:00->10/25:01",
        label: "(GMT+00:00) America - Scoresbysund",
        value: "america/scoresbysund",
    },
    "atlantic/azores": {
        offset: 0,
        hem: "n",
        dst: "03/29:00->10/25:01",
        label: "(GMT+00:00) Atlantic - Azores",
        value: "atlantic/azores",
    },
    "africa/abidjan": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Abidjan",
        value: "africa/abidjan",
    },
    "africa/accra": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Accra",
        value: "africa/accra",
    },
    "africa/bamako": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Bamako",
        value: "africa/bamako",
    },
    "africa/banjul": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Banjul",
        value: "africa/banjul",
    },
    "africa/bissau": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Bissau",
        value: "africa/bissau",
    },
    "africa/conakry": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Conakry",
        value: "africa/conakry",
    },
    "africa/dakar": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Dakar",
        value: "africa/dakar",
    },
    "africa/freetown": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Freetown",
        value: "africa/freetown",
    },
    "africa/lome": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Lome",
        value: "africa/lome",
    },
    "africa/monrovia": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Monrovia",
        value: "africa/monrovia",
    },
    "africa/nouakchott": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Nouakchott",
        value: "africa/nouakchott",
    },
    "africa/ouagadougou": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Ouagadougou",
        value: "africa/ouagadougou",
    },
    "africa/sao_tome": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Sao Tome",
        value: "africa/sao_tome",
    },
    "africa/timbuktu": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Africa - Timbuktu",
        value: "africa/timbuktu",
    },
    "america/danmarkshavn": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) America - Danmarkshavn",
        value: "america/danmarkshavn",
    },
    "atlantic/reykjavik": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Atlantic - Reykjavik",
        value: "atlantic/reykjavik",
    },
    "atlantic/st_helena": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Atlantic - St Helena",
        value: "atlantic/st_helena",
    },
    "etc/gmt": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Etc - Gmt",
        value: "etc/gmt",
    },
    "etc/gmt+0": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Etc - Gmt+0",
        value: "etc/gmt+0",
    },
    "etc/gmt-0": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Etc - Gmt-0",
        value: "etc/gmt-0",
    },
    "etc/gmt0": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Etc - Gmt0",
        value: "etc/gmt0",
    },
    "etc/greenwich": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Etc - Greenwich",
        value: "etc/greenwich",
    },
    "etc/utc": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Etc - Utc",
        value: "etc/utc",
    },
    "etc/universal": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Etc - Universal",
        value: "etc/universal",
    },
    "etc/zulu": {
        offset: 0,
        hem: "n",
        label: "(GMT+00:00) Etc - Zulu",
        value: "etc/zulu",
    },
    "america/adak": {
        offset: -9,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-09:00) America - Adak",
        value: "america/adak",
    },
    "america/atka": {
        offset: -9,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-09:00) America - Atka",
        value: "america/atka",
    },
    "pacific/gambier": {
        offset: -9,
        hem: "n",
        label: "(GMT-09:00) Pacific - Gambier",
        value: "pacific/gambier",
    },
    "pacific/marquesas": {
        offset: -9.5,
        hem: "n",
        label: "(GMT-09:30) Pacific - Marquesas",
        value: "pacific/marquesas",
    },
    "america/anchorage": {
        offset: -8,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-08:00) America - Anchorage",
        value: "america/anchorage",
    },
    "america/juneau": {
        offset: -8,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-08:00) America - Juneau",
        value: "america/juneau",
    },
    "america/metlakatla": {
        offset: -8,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-08:00) America - Metlakatla",
        value: "america/metlakatla",
    },
    "america/nome": {
        offset: -8,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-08:00) America - Nome",
        value: "america/nome",
    },
    "america/sitka": {
        offset: -8,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-08:00) America - Sitka",
        value: "america/sitka",
    },
    "america/yakutat": {
        offset: -8,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-08:00) America - Yakutat",
        value: "america/yakutat",
    },
    "pacific/pitcairn": {
        offset: -8,
        hem: "n",
        label: "(GMT-08:00) Pacific - Pitcairn",
        value: "pacific/pitcairn",
    },
    "america/dawson": {
        offset: -7,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-07:00) America - Dawson",
        value: "america/dawson",
    },
    "america/ensenada": {
        offset: -7,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-07:00) America - Ensenada",
        value: "america/ensenada",
    },
    "america/los_angeles": {
        offset: -7,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-07:00) America - Los Angeles",
        value: "america/los_angeles",
    },
    "america/santa_isabel": {
        offset: -7,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-07:00) America - Santa Isabel",
        value: "america/santa_isabel",
    },
    "america/tijuana": {
        offset: -7,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-07:00) America - Tijuana",
        value: "america/tijuana",
    },
    "america/vancouver": {
        offset: -7,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-07:00) America - Vancouver",
        value: "america/vancouver",
    },
    "america/whitehorse": {
        offset: -7,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-07:00) America - Whitehorse",
        value: "america/whitehorse",
    },
    "canada/pacific": {
        offset: -7,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-07:00) Canada - Pacific",
        value: "canada/pacific",
    },
    "canada/yukon": {
        offset: -7,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-07:00) Canada - Yukon",
        value: "canada/yukon",
    },
    "mexico/bajanorte": {
        offset: -7,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-07:00) Mexico - Bajanorte",
        value: "mexico/bajanorte",
    },
    "america/creston": {
        offset: -7,
        hem: "n",
        label: "(GMT-07:00) America - Creston",
        value: "america/creston",
    },
    "america/dawson_creek": {
        offset: -7,
        hem: "n",
        label: "(GMT-07:00) America - Dawson Creek",
        value: "america/dawson_creek",
    },
    "america/hermosillo": {
        offset: -7,
        hem: "n",
        label: "(GMT-07:00) America - Hermosillo",
        value: "america/hermosillo",
    },
    "america/phoenix": {
        offset: -7,
        hem: "n",
        label: "(GMT-07:00) America - Phoenix",
        value: "america/phoenix",
    },
    "chile/easterisland": {
        offset: -6,
        hem: "s",
        dst: "04/04:22->09/05:22",
        label: "(GMT-06:00) Chile - Easterisland",
        value: "chile/easterisland",
    },
    "pacific/easter": {
        offset: -6,
        hem: "s",
        dst: "04/04:22->09/05:22",
        label: "(GMT-06:00) Pacific - Easter",
        value: "pacific/easter",
    },
    "america/chihuahua": {
        offset: -6,
        hem: "n",
        dst: "04/05:02->10/25:02",
        label: "(GMT-06:00) America - Chihuahua",
        value: "america/chihuahua",
    },
    "america/mazatlan": {
        offset: -6,
        hem: "n",
        dst: "04/05:02->10/25:02",
        label: "(GMT-06:00) America - Mazatlan",
        value: "america/mazatlan",
    },
    "mexico/bajasur": {
        offset: -6,
        hem: "n",
        dst: "04/05:02->10/25:02",
        label: "(GMT-06:00) Mexico - Bajasur",
        value: "mexico/bajasur",
    },
    "america/boise": {
        offset: -6,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-06:00) America - Boise",
        value: "america/boise",
    },
    "america/cambridge_bay": {
        offset: -6,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-06:00) America - Cambridge Bay",
        value: "america/cambridge_bay",
    },
    "america/denver": {
        offset: -6,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-06:00) America - Denver",
        value: "america/denver",
    },
    "america/edmonton": {
        offset: -6,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-06:00) America - Edmonton",
        value: "america/edmonton",
    },
    "america/inuvik": {
        offset: -6,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-06:00) America - Inuvik",
        value: "america/inuvik",
    },
    "america/ojinaga": {
        offset: -6,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-06:00) America - Ojinaga",
        value: "america/ojinaga",
    },
    "america/shiprock": {
        offset: -6,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-06:00) America - Shiprock",
        value: "america/shiprock",
    },
    "america/yellowknife": {
        offset: -6,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-06:00) America - Yellowknife",
        value: "america/yellowknife",
    },
    "canada/mountain": {
        offset: -6,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-06:00) Canada - Mountain",
        value: "canada/mountain",
    },
    "america/belize": {
        offset: -6,
        hem: "n",
        label: "(GMT-06:00) America - Belize",
        value: "america/belize",
    },
    "america/costa_rica": {
        offset: -6,
        hem: "n",
        label: "(GMT-06:00) America - Costa Rica",
        value: "america/costa_rica",
    },
    "america/el_salvador": {
        offset: -6,
        hem: "n",
        label: "(GMT-06:00) America - El Salvador",
        value: "america/el_salvador",
    },
    "america/guatemala": {
        offset: -6,
        hem: "n",
        label: "(GMT-06:00) America - Guatemala",
        value: "america/guatemala",
    },
    "america/managua": {
        offset: -6,
        hem: "n",
        label: "(GMT-06:00) America - Managua",
        value: "america/managua",
    },
    "america/regina": {
        offset: -6,
        hem: "n",
        label: "(GMT-06:00) America - Regina",
        value: "america/regina",
    },
    "america/swift_current": {
        offset: -6,
        hem: "n",
        label: "(GMT-06:00) America - Swift Current",
        value: "america/swift_current",
    },
    "america/tegucigalpa": {
        offset: -6,
        hem: "n",
        label: "(GMT-06:00) America - Tegucigalpa",
        value: "america/tegucigalpa",
    },
    "canada/east-saskatchewan": {
        offset: -6,
        hem: "n",
        label: "(GMT-06:00) Canada - East-saskatchewan",
        value: "canada/east-saskatchewan",
    },
    "canada/saskatchewan": {
        offset: -6,
        hem: "n",
        label: "(GMT-06:00) Canada - Saskatchewan",
        value: "canada/saskatchewan",
    },
    "pacific/galapagos": {
        offset: -6,
        hem: "n",
        label: "(GMT-06:00) Pacific - Galapagos",
        value: "pacific/galapagos",
    },
    "america/lima": {
        offset: -5,
        hem: "s",
        label: "(GMT-05:00) America - Lima",
        value: "america/lima",
    },
    "america/rio_branco": {
        offset: -5,
        hem: "s",
        label: "(GMT-05:00) America - Rio Branco",
        value: "america/rio_branco",
    },
    "brazil/acre": {
        offset: -5,
        hem: "s",
        label: "(GMT-05:00) Brazil - Acre",
        value: "brazil/acre",
    },
    "america/bahia_banderas": {
        offset: -5,
        hem: "n",
        dst: "04/05:02->10/25:02",
        label: "(GMT-05:00) America - Bahia Banderas",
        value: "america/bahia_banderas",
    },
    "america/merida": {
        offset: -5,
        hem: "n",
        dst: "04/05:02->10/25:02",
        label: "(GMT-05:00) America - Merida",
        value: "america/merida",
    },
    "america/mexico_city": {
        offset: -5,
        hem: "n",
        dst: "04/05:02->10/25:02",
        label: "(GMT-05:00) America - Mexico City",
        value: "america/mexico_city",
    },
    "america/monterrey": {
        offset: -5,
        hem: "n",
        dst: "04/05:02->10/25:02",
        label: "(GMT-05:00) America - Monterrey",
        value: "america/monterrey",
    },
    "mexico/general": {
        offset: -5,
        hem: "n",
        dst: "04/05:02->10/25:02",
        label: "(GMT-05:00) Mexico - General",
        value: "mexico/general",
    },
    "america/north_dakota": {
        offset: -5,
        hem: "n",
        dst: "03/12:03->11/05:01",
        label: "(GMT-05:00) America - North Dakota",
        value: "america/north_dakota",
    },
    "america/chicago": {
        offset: -5,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-05:00) America - Chicago",
        value: "america/chicago",
    },
    "america/knox_in": {
        offset: -5,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-05:00) America - Knox In",
        value: "america/knox_in",
    },
    "america/matamoros": {
        offset: -5,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-05:00) America - Matamoros",
        value: "america/matamoros",
    },
    "america/menominee": {
        offset: -5,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-05:00) America - Menominee",
        value: "america/menominee",
    },
    "america/rainy_river": {
        offset: -5,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-05:00) America - Rainy River",
        value: "america/rainy_river",
    },
    "america/rankin_inlet": {
        offset: -5,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-05:00) America - Rankin Inlet",
        value: "america/rankin_inlet",
    },
    "america/resolute": {
        offset: -5,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-05:00) America - Resolute",
        value: "america/resolute",
    },
    "america/winnipeg": {
        offset: -5,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-05:00) America - Winnipeg",
        value: "america/winnipeg",
    },
    "canada/central": {
        offset: -5,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-05:00) Canada - Central",
        value: "canada/central",
    },
    "america/atikokan": {
        offset: -5,
        hem: "n",
        label: "(GMT-05:00) America - Atikokan",
        value: "america/atikokan",
    },
    "america/bogota": {
        offset: -5,
        hem: "n",
        label: "(GMT-05:00) America - Bogota",
        value: "america/bogota",
    },
    "america/cancun": {
        offset: -5,
        hem: "n",
        label: "(GMT-05:00) America - Cancun",
        value: "america/cancun",
    },
    "america/cayman": {
        offset: -5,
        hem: "n",
        label: "(GMT-05:00) America - Cayman",
        value: "america/cayman",
    },
    "america/coral_harbour": {
        offset: -5,
        hem: "n",
        label: "(GMT-05:00) America - Coral Harbour",
        value: "america/coral_harbour",
    },
    "america/eirunepe": {
        offset: -5,
        hem: "n",
        label: "(GMT-05:00) America - Eirunepe",
        value: "america/eirunepe",
    },
    "america/guayaquil": {
        offset: -5,
        hem: "n",
        label: "(GMT-05:00) America - Guayaquil",
        value: "america/guayaquil",
    },
    "america/jamaica": {
        offset: -5,
        hem: "n",
        label: "(GMT-05:00) America - Jamaica",
        value: "america/jamaica",
    },
    "america/panama": {
        offset: -5,
        hem: "n",
        label: "(GMT-05:00) America - Panama",
        value: "america/panama",
    },
    "america/porto_acre": {
        offset: -5,
        hem: "n",
        label: "(GMT-05:00) America - Porto Acre",
        value: "america/porto_acre",
    },
    "antarctica/palmer": {
        offset: -4,
        hem: "s",
        dst: "05/13:23->08/13:01",
        label: "(GMT-04:00) Antarctica - Palmer",
        value: "antarctica/palmer",
    },
    "america/santiago": {
        offset: -4,
        hem: "s",
        dst: "04/04:24->09/06:00",
        label: "(GMT-04:00) America - Santiago",
        value: "america/santiago",
    },
    "chile/continental": {
        offset: -4,
        hem: "s",
        dst: "04/04:24->09/06:00",
        label: "(GMT-04:00) Chile - Continental",
        value: "chile/continental",
    },
    "america/asuncion": {
        offset: -4,
        hem: "s",
        dst: "03/21:24->10/04:00",
        label: "(GMT-04:00) America - Asuncion",
        value: "america/asuncion",
    },
    "america/campo_grande": {
        offset: -4,
        hem: "s",
        dst: "02/16:24->11/03:00",
        label: "(GMT-04:00) America - Campo Grande",
        value: "america/campo_grande",
    },
    "america/cuiaba": {
        offset: -4,
        hem: "s",
        dst: "02/16:24->11/03:00",
        label: "(GMT-04:00) America - Cuiaba",
        value: "america/cuiaba",
    },
    "america/la_paz": {
        offset: -4,
        hem: "s",
        label: "(GMT-04:00) America - La Paz",
        value: "america/la_paz",
    },
    "america/manaus": {
        offset: -4,
        hem: "s",
        label: "(GMT-04:00) America - Manaus",
        value: "america/manaus",
    },
    "brazil/west": {
        offset: -4,
        hem: "s",
        label: "(GMT-04:00) Brazil - West",
        value: "brazil/west",
    },
    "america/indiana": {
        offset: -4,
        hem: "n",
        dst: "03/12:03->11/05:01",
        label: "(GMT-04:00) America - Indiana",
        value: "america/indiana",
    },
    "america/kentucky": {
        offset: -4,
        hem: "n",
        dst: "03/12:03->11/05:01",
        label: "(GMT-04:00) America - Kentucky",
        value: "america/kentucky",
    },
    "america/detroit": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - Detroit",
        value: "america/detroit",
    },
    "america/fort_wayne": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - Fort Wayne",
        value: "america/fort_wayne",
    },
    "america/grand_turk": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - Grand Turk",
        value: "america/grand_turk",
    },
    "america/indianapolis": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - Indianapolis",
        value: "america/indianapolis",
    },
    "america/iqaluit": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - Iqaluit",
        value: "america/iqaluit",
    },
    "america/louisville": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - Louisville",
        value: "america/louisville",
    },
    "america/montreal": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - Montreal",
        value: "america/montreal",
    },
    "america/nassau": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - Nassau",
        value: "america/nassau",
    },
    "america/new_york": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - New York",
        value: "america/new_york",
    },
    "america/nipigon": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - Nipigon",
        value: "america/nipigon",
    },
    "america/pangnirtung": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - Pangnirtung",
        value: "america/pangnirtung",
    },
    "america/port-au-prince": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - Port-au-prince",
        value: "america/port-au-prince",
    },
    "america/thunder_bay": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - Thunder Bay",
        value: "america/thunder_bay",
    },
    "america/toronto": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) America - Toronto",
        value: "america/toronto",
    },
    "canada/eastern": {
        offset: -4,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-04:00) Canada - Eastern",
        value: "canada/eastern",
    },
    "america/havana": {
        offset: -4,
        hem: "n",
        dst: "03/08:00->11/01:01",
        label: "(GMT-04:00) America - Havana",
        value: "america/havana",
    },
    "america/anguilla": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Anguilla",
        value: "america/anguilla",
    },
    "america/antigua": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Antigua",
        value: "america/antigua",
    },
    "america/aruba": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Aruba",
        value: "america/aruba",
    },
    "america/barbados": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Barbados",
        value: "america/barbados",
    },
    "america/blanc-sablon": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Blanc-sablon",
        value: "america/blanc-sablon",
    },
    "america/boa_vista": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Boa Vista",
        value: "america/boa_vista",
    },
    "america/caracas": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Caracas",
        value: "america/caracas",
    },
    "america/curacao": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Curacao",
        value: "america/curacao",
    },
    "america/dominica": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Dominica",
        value: "america/dominica",
    },
    "america/grenada": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Grenada",
        value: "america/grenada",
    },
    "america/guadeloupe": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Guadeloupe",
        value: "america/guadeloupe",
    },
    "america/guyana": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Guyana",
        value: "america/guyana",
    },
    "america/kralendijk": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Kralendijk",
        value: "america/kralendijk",
    },
    "america/lower_princes": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Lower Princes",
        value: "america/lower_princes",
    },
    "america/marigot": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Marigot",
        value: "america/marigot",
    },
    "america/martinique": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Martinique",
        value: "america/martinique",
    },
    "america/montserrat": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Montserrat",
        value: "america/montserrat",
    },
    "america/port_of_spain": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Port Of Spain",
        value: "america/port_of_spain",
    },
    "america/porto_velho": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Porto Velho",
        value: "america/porto_velho",
    },
    "america/puerto_rico": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Puerto Rico",
        value: "america/puerto_rico",
    },
    "america/santo_domingo": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Santo Domingo",
        value: "america/santo_domingo",
    },
    "america/st_barthelemy": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - St Barthelemy",
        value: "america/st_barthelemy",
    },
    "america/st_kitts": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - St Kitts",
        value: "america/st_kitts",
    },
    "america/st_lucia": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - St Lucia",
        value: "america/st_lucia",
    },
    "america/st_thomas": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - St Thomas",
        value: "america/st_thomas",
    },
    "america/st_vincent": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - St Vincent",
        value: "america/st_vincent",
    },
    "america/tortola": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Tortola",
        value: "america/tortola",
    },
    "america/virgin": {
        offset: -4,
        hem: "n",
        label: "(GMT-04:00) America - Virgin",
        value: "america/virgin",
    },
    "america/argentina": {
        offset: -3,
        hem: "s",
        label: "(GMT-03:00) America - Argentina",
        value: "america/argentina",
    },
    "america/buenos_aires": {
        offset: -3,
        hem: "s",
        label: "(GMT-03:00) America - Buenos Aires",
        value: "america/buenos_aires",
    },
    "america/cordoba": {
        offset: -3,
        hem: "s",
        label: "(GMT-03:00) America - Cordoba",
        value: "america/cordoba",
    },
    "america/fortaleza": {
        offset: -3,
        hem: "s",
        label: "(GMT-03:00) America - Fortaleza",
        value: "america/fortaleza",
    },
    "america/montevideo": {
        offset: -3,
        hem: "s",
        label: "(GMT-03:00) America - Montevideo",
        value: "america/montevideo",
    },
    "america/punta_arenas": {
        offset: -3,
        hem: "s",
        label: "(GMT-03:00) America - Punta Arenas",
        value: "america/punta_arenas",
    },
    "america/sao_paulo": {
        offset: -3,
        hem: "s",
        label: "(GMT-03:00) America - Sao Paulo",
        value: "america/sao_paulo",
    },
    "antarctica/rothera": {
        offset: -3,
        hem: "s",
        label: "(GMT-03:00) Antarctica - Rothera",
        value: "antarctica/rothera",
    },
    "atlantic/stanley": {
        offset: -3,
        hem: "s",
        label: "(GMT-03:00) Atlantic - Stanley",
        value: "atlantic/stanley",
    },
    "brazil/east": {
        offset: -3,
        hem: "s",
        label: "(GMT-03:00) Brazil - East",
        value: "brazil/east",
    },
    "america/glace_bay": {
        offset: -3,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-03:00) America - Glace Bay",
        value: "america/glace_bay",
    },
    "america/goose_bay": {
        offset: -3,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-03:00) America - Goose Bay",
        value: "america/goose_bay",
    },
    "america/halifax": {
        offset: -3,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-03:00) America - Halifax",
        value: "america/halifax",
    },
    "america/moncton": {
        offset: -3,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-03:00) America - Moncton",
        value: "america/moncton",
    },
    "america/thule": {
        offset: -3,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-03:00) America - Thule",
        value: "america/thule",
    },
    "atlantic/bermuda": {
        offset: -3,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-03:00) Atlantic - Bermuda",
        value: "atlantic/bermuda",
    },
    "canada/atlantic": {
        offset: -3,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-03:00) Canada - Atlantic",
        value: "canada/atlantic",
    },
    "america/araguaina": {
        offset: -3,
        hem: "n",
        label: "(GMT-03:00) America - Araguaina",
        value: "america/araguaina",
    },
    "america/bahia": {
        offset: -3,
        hem: "n",
        label: "(GMT-03:00) America - Bahia",
        value: "america/bahia",
    },
    "america/belem": {
        offset: -3,
        hem: "n",
        label: "(GMT-03:00) America - Belem",
        value: "america/belem",
    },
    "america/catamarca": {
        offset: -3,
        hem: "n",
        label: "(GMT-03:00) America - Catamarca",
        value: "america/catamarca",
    },
    "america/cayenne": {
        offset: -3,
        hem: "n",
        label: "(GMT-03:00) America - Cayenne",
        value: "america/cayenne",
    },
    "america/jujuy": {
        offset: -3,
        hem: "n",
        label: "(GMT-03:00) America - Jujuy",
        value: "america/jujuy",
    },
    "america/maceio": {
        offset: -3,
        hem: "n",
        label: "(GMT-03:00) America - Maceio",
        value: "america/maceio",
    },
    "america/mendoza": {
        offset: -3,
        hem: "n",
        label: "(GMT-03:00) America - Mendoza",
        value: "america/mendoza",
    },
    "america/paramaribo": {
        offset: -3,
        hem: "n",
        label: "(GMT-03:00) America - Paramaribo",
        value: "america/paramaribo",
    },
    "america/recife": {
        offset: -3,
        hem: "n",
        label: "(GMT-03:00) America - Recife",
        value: "america/recife",
    },
    "america/rosario": {
        offset: -3,
        hem: "n",
        label: "(GMT-03:00) America - Rosario",
        value: "america/rosario",
    },
    "america/santarem": {
        offset: -3,
        hem: "n",
        label: "(GMT-03:00) America - Santarem",
        value: "america/santarem",
    },
    "brazil/denoronha": {
        offset: -2,
        hem: "s",
        label: "(GMT-02:00) Brazil - Denoronha",
        value: "brazil/denoronha",
    },
    "america/godthab": {
        offset: -2,
        hem: "n",
        dst: "03/28:22->10/24:23",
        label: "(GMT-02:00) America - Godthab",
        value: "america/godthab",
    },
    "america/miquelon": {
        offset: -2,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-02:00) America - Miquelon",
        value: "america/miquelon",
    },
    "america/noronha": {
        offset: -2,
        hem: "n",
        label: "(GMT-02:00) America - Noronha",
        value: "america/noronha",
    },
    "atlantic/south_georgia": {
        offset: -2,
        hem: "n",
        label: "(GMT-02:00) Atlantic - South Georgia",
        value: "atlantic/south_georgia",
    },
    "america/st_johns": {
        offset: -2.5,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-02:30) America - St Johns",
        value: "america/st_johns",
    },
    "canada/newfoundland": {
        offset: -2.5,
        hem: "n",
        dst: "03/08:02->11/01:02",
        label: "(GMT-02:30) Canada - Newfoundland",
        value: "canada/newfoundland",
    },
    "atlantic/cape_verde": {
        offset: -1,
        hem: "n",
        label: "(GMT-01:00) Atlantic - Cape Verde",
        value: "atlantic/cape_verde",
    },
    "pacific/midway": {
        offset: -11,
        hem: "n",
        label: "(GMT-11:00) Pacific - Midway",
        value: "pacific/midway",
    },
    "pacific/niue": {
        offset: -11,
        hem: "n",
        label: "(GMT-11:00) Pacific - Niue",
        value: "pacific/niue",
    },
    "pacific/pago_pago": {
        offset: -11,
        hem: "n",
        label: "(GMT-11:00) Pacific - Pago Pago",
        value: "pacific/pago_pago",
    },
    "pacific/samoa": {
        offset: -11,
        hem: "n",
        label: "(GMT-11:00) Pacific - Samoa",
        value: "pacific/samoa",
    },
    "pacific/honolulu": {
        offset: -10,
        hem: "n",
        label: "(GMT-10:00) Pacific - Honolulu",
        value: "pacific/honolulu",
    },
    "pacific/johnston": {
        offset: -10,
        hem: "n",
        label: "(GMT-10:00) Pacific - Johnston",
        value: "pacific/johnston",
    },
    "pacific/rarotonga": {
        offset: -10,
        hem: "n",
        label: "(GMT-10:00) Pacific - Rarotonga",
        value: "pacific/rarotonga",
    },
    "etc/gmt-1.5": {
        offset: 1.5,
        hem: "n",
        label: "(GMT+01:30) Etc - Gmt-1.5",
        value: "etc/gmt-1.5",
    },
    "pacific/tahiti": {
        offset: -10,
        hem: "n",
        label: "(GMT-10:00) Pacific - Tahiti",
        value: "pacific/tahiti",
    },
};

export const ALL_VALID_TIMEZONES = Object.keys(TIMEZONES);

export default TIMEZONES;
