import { client, listData, ServerClientWithCookies } from "@/utils/client/graphql";
import { listDailyActivity } from "@/graphql/queries";
import {
    ApplyStreakActionInput,
    DailyActivity,
    ListDailyActivityInput,
    SimulateStreakActionsInput,
    SimulateStreakInput,
    TrackActivityInput,
} from "@/graphql/schema";
import {
    applyStreakAction,
    applyStreakActionV2,
    processAsyncActions,
    removeGamifyAttributes,
    simulateStreak,
    simulateStreakActions,
    trackActivity,
} from "@/graphql/mutations";
import { handleMaybeProgressing } from "@/hooks/gamification/monitoring/progressing";

export const callListDailyActivity = async ({
    serverClient,
    ...input
}: {
    serverClient?: ServerClientWithCookies;
} & ListDailyActivityInput) => {
    return (await listData({
        listQuery: listDailyActivity,
        input,
        queryName: "listDailyActivity",
        serverClient,
    })) as DailyActivity[];
};

export const callApplyStreakAction = async (input: ApplyStreakActionInput) => {
    return (
        await client.mutate({
            mutation: applyStreakAction,
            variables: { input },
        })
    ).data.applyStreakAction;
};

export const callApplyStreakActionV2 = async (input: ApplyStreakActionInput) => {
    return (
        await client.mutate({
            mutation: applyStreakActionV2,
            variables: { input },
        })
    ).data.applyStreakActionV2;
};

export const callTrackActivity = async (input: TrackActivityInput, serverClient?: ServerClientWithCookies) => {
    const v2Response = await client
        .mutate({
            mutation: trackActivity,
            variables: { input },
            serverClient,
        })
        .then(({ data }) => data.trackActivity);

    await handleMaybeProgressing(v2Response);
};

export const callProcessAsyncActions = async () => {
    return await client
        .mutate({
            mutation: processAsyncActions,
            variables: {},
        })
        .then(response => response.data.processAsyncActions);
};

// !! ONLY ON DEV
export const callSimulateStreak = async (input: SimulateStreakInput) => {
    return await client.mutate({
        mutation: simulateStreak,
        variables: { input },
    });
};

// !! ONLY ON DEV
export const callRemoveGamifyAttributes = async () => {
    return await client.mutate({
        mutation: removeGamifyAttributes,
        variables: { input: {} },
    });
};

// !! ONLY ON DEV
export const callSimulateStreakActions = async (action: SimulateStreakActionsInput) => {
    return await client.mutate({
        mutation: simulateStreakActions,
        variables: { input: action },
    });
};
