import { POST_HEADERS } from "@knowt/syncing/fetchFunctions/fetchWrappers";
import { platform } from "@knowt/syncing/platform";
import { now } from "@knowt/syncing/utils/dateTimeUtils";
import { safeParse } from "@knowt/syncing/utils/stringUtils";
import { AICompletionType, ActionEnum, ItemType, UserDetails } from "@knowt/syncing/graphql/schema";
import { fetchAuthSession } from "aws-amplify/auth";
import { useCallback, useEffect, useState } from "react";
import { callTrackActivity } from "../gamification/activities/graphqlUtils";
import { StableSWRKeys, safeLocalMutate } from "../swr/swr";
import { LocalAuthUser } from "../user/types";
import { AI_LAMBDA_URL } from "./constants";
import { AI_PROMPT_PARAMS_MAP } from "@knowt/syncing/hooks/ai/aiPrompts/types";
import { AI_PROMPT_OUTPUT_MAP, tryParsingAIOutput } from "./utils";

export const fieldNamesMap: Partial<Record<AICompletionType, keyof UserDetails["ai"]>> = {
    [AICompletionType.NOTE_TEST]: "nTests",
    [AICompletionType.NOTE_FLASHCARDS]: "nSets",
    [AICompletionType.NOTE_CHAT]: "chats",
    [AICompletionType.FLASHCARD_CHAT]: "chats",
    [AICompletionType.EXPLAIN_WHY_IM_WRONG]: "explain",
    [AICompletionType.EXPLAIN_EXAM_FRQ_ANSWER]: "frq",

    // TODO: add the rest
};

export const BADGE_ACTION_MAP: Partial<Record<AICompletionType, ActionEnum>> = {
    [AICompletionType.NOTE_TEST]: ActionEnum.TEST_FROM_NOTE,
    [AICompletionType.NOTE_FLASHCARDS]: ActionEnum.FLASHCARD_FROM_NOTES,
    [AICompletionType.NOTE_CHAT]: ActionEnum.CHAT_WITH_AI,
    [AICompletionType.FLASHCARD_CHAT]: ActionEnum.CHAT_WITH_AI,
    [AICompletionType.EXPLAIN_WHY_IM_WRONG]: ActionEnum.EXPLAIN_WRONG_ANSWER,
    [AICompletionType.EXPLAIN_EXAM_FRQ_ANSWER]: ActionEnum.EXPLAIN_WRONG_ANSWER,
};

export const useStreamingAI = <T extends keyof AI_PROMPT_OUTPUT_MAP>({
    itemId,
    itemType,
    type,
    header,
}: {
    itemId: string;
    itemType: ItemType;
    type: T;
    header?: string;
}) => {
    const [response, setResponse] = useState("");
    const [parsedResponse, setParsedResponse] = useState<AI_PROMPT_OUTPUT_MAP[T] | null>(null);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDone, setIsDone] = useState(true);

    const clearStream = useCallback(({ chunked = false }: { chunked?: boolean } = {}) => {
        if (!chunked) {
            setResponse("");
            setParsedResponse(null);
        }
        setIsLoading(false);
        setIsStreaming(false);
    }, []);

    /**
     * This function is used to call the AI. It will stream the response to the client
     * @param - input is the prompt
     * @param - timestamp is the timestamp of the prompt. Pass it in if you are chunking, so its tracked properly
     * @param - chunked is used to indicate that this is a chunked call. This is to make sure the response isnt cleared
     * @returns
     */
    type AIParams = Omit<AI_PROMPT_PARAMS_MAP[T], "transcripts">;
    const callAI = useCallback(
        async ({
            timestamp = now().toString(),
            chunked = false,
            aiParams,
        }: {
            timestamp?: string;
            chunked?: boolean;
            aiParams?: AIParams;
        }) => {
            let finalText = "";
            clearStream({
                chunked,
            });
            setIsDone(false);

            const sessionIdToken = await fetchAuthSession()
                .then(session => session.tokens.idToken.toString())
                .catch(() => null);

            const authToken: string | null = sessionIdToken;

            const customFetcher = await platform.fetch();
            /*
             *  If is running in react native, we need to use the react-native-fetch-api library
             *  because the fetch library that comes with react native doesnt support streaming
             */
            const fetcher = customFetcher ?? fetch;

            const response = await fetcher(AI_LAMBDA_URL, {
                method: "POST",
                reactNative: { textStreaming: true },
                body: JSON.stringify({
                    type,
                    itemId,
                    itemType,
                    timestamp,
                    cookie: authToken,
                    params: { ...aiParams, transcripts: [] },
                }),
                ...POST_HEADERS,
            });

            setIsStreaming(true);
            setIsLoading(false);

            if (!chunked) {
                await mutateUserAIUsage(type);
                // await trackAIBadgeActivity({ type: type, itemId, itemType });
            }

            if (!response.ok) {
                const mixpanel = await platform.analytics.mixpanel();
                mixpanel.track("AI Error", {
                    error: response.statusText,
                    type,
                    itemId,
                    itemType,
                    timestamp,
                    e: {
                        ok: response.ok,
                        status: response.status,
                        statusText: response.statusText,
                        url: response.url,
                        type: response.type,
                        redirected: response.redirected,
                        headers: response.headers,
                        body: response.body,
                        bodyUsed: response.bodyUsed,
                    },
                });
                // biome-ignore lint: noConsole
                console.error(response);
                throw new Error(response.statusText);
            }

            const data = response.body;
            if (!data) {
                return { response: "", parsedResponse: null };
            }
            const reader = data.getReader();
            const decoder = new TextDecoder();
            let done = false;

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                done = doneReading;
                const chunkValue = decoder.decode(value);
                finalText += chunkValue;
                setResponse(prevState => prevState + chunkValue);
            }

            if (done && !chunked) {
                setIsStreaming(false);
                setIsDone(true);
            }

            return { response: finalText, parsedResponse: tryParsingAIOutput(finalText, type) };
        },
        [header, clearStream, itemId, itemType, type]
    );

    useEffect(() => {
        const parsed = tryParsingAIOutput(response, type);
        if (parsed) setParsedResponse(parsed);
    }, [response]);

    /**
     * This function is used to call the AI in chunks. It will stream the response to the client
     * @param inputs - the inputs to chunk, array of strings
     * @param inputFormatter - a function that takes in a string and returns a string. Used to format the input for each call
     */
    const callChunkedAI = useCallback(
        async ({
            inputs,
            inputFormatter,
        }: {
            inputs: string[];
            inputFormatter: (input: string) => AI_PROMPT_PARAMS_MAP[T];
        }) => {
            // We use this to make sure that when we call submitAICompletion, we are updating the same entry
            clearStream();
            const timestamp = now().toString();
            await mutateUserAIUsage(type);
            // await trackAIBadgeActivity({ type: granularType || type, itemId, itemType });
            let finalResponse = "";
            let parsedFinalResponse: AI_PROMPT_OUTPUT_MAP[T] = null;

            for (const input of inputs) {
                const { response: chunkedResponse } = await callAI({
                    timestamp,
                    chunked: true,
                    aiParams: inputFormatter(input),
                });

                setResponse(prevState => prevState + "\n");
                finalResponse += chunkedResponse;
                parsedFinalResponse = tryParsingAIOutput(finalResponse, type);
            }
            setIsStreaming(false);
            setIsDone(true);
            return { response: finalResponse, parsedResponse: parsedFinalResponse };
        },
        [callAI, clearStream, itemId, itemType, type]
    );

    return {
        response,
        parsedResponse: (parsedResponse ?? safeParse(response)) as AI_PROMPT_OUTPUT_MAP[T],
        callAI,
        callChunkedAI,
        isStreaming,
        isDone,
        clearStream,
        isLoading,
    };
};

export const mutateUserAIUsage = async (aiUsageType: AICompletionType) => {
    const aiUsageField = (fieldNamesMap[aiUsageType] ?? "curPrompts") as Exclude<
        keyof UserDetails["ai"],
        "history" | "__typename"
    >;

    return await safeLocalMutate(StableSWRKeys.USER, (oldLocalUser: LocalAuthUser) => ({
        ...oldLocalUser,
        user: {
            ...oldLocalUser.user,
            ai: {
                ...oldLocalUser.user.ai,
                [aiUsageField]: (oldLocalUser.user.ai?.[aiUsageField] || 0) + 1,
            },
        },
    }));
};

export const trackAIBadgeActivity = async ({
    type,
    itemId,
    itemType,
}: {
    type: AICompletionType;
    itemId: string;
    itemType: ItemType;
}) => {
    const activityAction = BADGE_ACTION_MAP[type];

    if (!activityAction) return;

    await callTrackActivity({
        action: activityAction,
        itemId: itemId,
        type: itemType,
    });
};
