import { client, listData, ServerClientWithCookies } from "@/utils/client/graphql";
import { listStoreProductHistory, listStoreProductMetadata } from "@/graphql/queries";
import { activateStoreProduct, purchaseStoreProduct } from "@/graphql/mutations";
import { StoreProductEnum, StoreProductHistory, StoreProductMetadata } from "@/graphql/schema";

export const callListStoreProductMetadata = async () => {
    return (await listData({
        listQuery: listStoreProductMetadata,
        input: {},
        queryName: "listStoreProductMetadata",
    })) as StoreProductMetadata[];
};

export const callListStoreProductHistory = async ({
    serverClient,
}: { serverClient?: ServerClientWithCookies } = {}) => {
    return (await listData({
        listQuery: listStoreProductHistory,
        input: {},
        queryName: "listStoreProductHistory",
        serverClient,
    })) as StoreProductHistory[];
};

export const callPurchaseStoreProduct = async ({
    storeProductId,
    serverClient,
}: {
    storeProductId: StoreProductEnum;
    serverClient?: ServerClientWithCookies;
}) => {
    return (
        await client.mutate({
            mutation: purchaseStoreProduct,
            variables: {
                input: {
                    storeProductId,
                },
            },
            serverClient,
        })
    ).data.purchaseStoreProduct;
};

export const callActivateStoreProduct = async ({
    storeProductId,
    serverClient,
}: {
    storeProductId: StoreProductEnum;
    serverClient?: ServerClientWithCookies;
}) => {
    return (
        await client.mutate({
            mutation: activateStoreProduct,
            variables: {
                input: {
                    storeProductId,
                },
            },
            serverClient,
        })
    ).data.activateStoreProduct;
};
